// import packages
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

//import assets
import './projectPlan.scss'
import {AppImg, ArrowIcon, QRIcon} from "../../../assets/images";

// Import Components
import InfoModal from "../../../components/uiElements/infoModal/InfoModal";
import {PrimaryButton} from "../../../components/uiElements/buttons/Buttons";

// Import utils
import {generateImageMediaUrl} from "../../../utils/generateMediaUrl";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {androidUrl, iosUrl} from "../../../configs/appUrls";
import {getMobileOS} from "../../../utils/getMobileOS";
import {getBrowserType} from "../../../utils/getBrowserType";


function Plans(props) {
    const {plans, staticTexts} = props;
    const [modalVisible, setModalVisible] = useState(false);

    const toggleQRModal = () => {
        setModalVisible(!modalVisible);
    }

    function getAppUrl() {
        let url = androidUrl;
        const mobileOs = getMobileOS();
        if (mobileOs === 'unknown') {
            const browserType = getBrowserType();
            if (browserType === 'Safari') {
                url = iosUrl;
            }
        } else if (mobileOs === 'iOS') {
            url = iosUrl;
        }
        return url;
    }


    const t = staticTexts?.homepage_download_app_title || ""
    const start = t.slice(0, t.indexOf("["))
    const mid = t.slice(t.indexOf("[") + 1, t.lastIndexOf("]")) || ""
    const end = t.slice(t.lastIndexOf("]") + 1)
    const titleJSX = t.indexOf("[") !== t.lastIndexOf("]")
        ? <>{start}<span className="marked-word">{mid}</span>{end}</> : t

    return !!plans?.length && <div className='plans-wrapper'>
        <div className="plans-header">
            <div className="title">{staticTexts?.homepage_project_plans_title}</div>
            <Link to={'/project-plans'}>
                <div className="view-more">{staticTexts?.homepage_btn_see_all}</div>
            </Link>
        </div>
        {plans?.length && <div className="plans-content">
            {plans.slice(0, 3).map(plan => {
                return <Link to={{
                    pathname: `/project-plans`,
                }} className="plans-item" key={plan?.id}>
                    <div className="inner-side">
                        <img src={ generateImageMediaUrl(plan?.mediaMain?.path)} className="plan-icon" alt={""}/>
                        <div className="title">
                            {plan?.title}
                        </div>
                        <div className="plans-btn">{staticTexts?.btn_see_more} <ArrowIcon title={''}/></div>
                    </div>
                </Link>
            })}
        </div>}
        <div className='download-app-wrapper' style={{backgroundImage: `url(${AppImg})`}}>
            <img src={AppImg} style={{visibility: "hidden", width: "100%"}} alt=""/>
            <div className="app-info-wrapper">
                <div className="info-content">
                    <div className="app-title">
                        {titleJSX}
                    </div>
                    {/*<div className="app-description">{staticTexts?.homepage_download_app_description}</div>*/}
                    <div className="qr-wrapper">
                        <a className={'PrimaryButton'} target={'_blank'} href={getAppUrl()}>
                            {staticTexts?.homepage_download_app_btn}
                        </a>
                        <QRIcon alt=""onClick={toggleQRModal}/>
                    </div>
                </div>
            </div>
        </div>
        <div className="buttons-wrapper">
            <PrimaryButton className="bg-grey" link={"/shipping"} title={staticTexts?.homepage_info_first_btn}/>
            {/*<PrimaryButton title={staticTexts?.homepage_info_second_btn}/>*/}
            <PrimaryButton title={''} className="disabled"/>
        </div>
        <InfoModal
            visible={modalVisible}
            toggleModal={toggleQRModal}
            qrModal
        />
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
    ])
};
export default connect(mapStateToProps)(Plans);
