// Import packages
import React from "react";

// Import assets
import "./customHeader.scss";
import {AboutHeader} from "../../../assets/images";

import {generateImageMediaUrl} from "../../../utils/generateMediaUrl";


export function CustomHeader(props) {
    const {image, title, className, description} = props;
    return <div className={`custom-header-content ${className ? className : ""}`}>
        <div className="image-wrapper">
            <img src={image?.path ? generateImageMediaUrl(image?.path) : (image && !image?.path ? image : AboutHeader)} alt=""/>
        </div>
        <div className="custom-header-info">
            {title && <div className="custom-title">{title}</div>}
            {description && <div className="custom-description">{description}</div>}
        </div>
    </div>
}
