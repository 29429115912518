// import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";

//Import assets
import './blog-details.scss'
import {TwitterIcon, LinkedinIcon, CopyIcon, FacebookShareIcon} from "../../assets/images";
//Import components

//Import utils
import {GetSingleBlog} from "../../redux/actions";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton} from "react-share";
import MetaTags from "../../components/MetaTags";
import {history} from "../../configs/history";
import {LoadingOutlined} from "@ant-design/icons";
import { domain } from "../../redux/api";

class BlogDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blog: props.location?.state?.data || null,
            loading: false,
            copied: false,
        };
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        this.setState({
            loading: true
        })
        this.props.GetSingleBlog(this.props.match.params?.slug).then((res) => {
            const data = res && res.data;

            this.setState({
                blog: data,
                loading: false
            })
        }).catch((err) => {
            history.push("/404")
        })
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedLanguage} = this.props;

        if (selectedLanguage && prevProps.selectedLanguage && selectedLanguage.code !== prevProps.selectedLanguage.code) {
            this.props.GetSingleBlog(this.props.match.params?.slug).then((res) => {
                const data = res && res?.data;

                this.setState({
                    blog: data
                })
            }).catch(() => {
                history.push("/404")
            })
        }
    }

    render() {
        const {blog, copied, loading} = this.state;
        const {staticTexts} = this.props;
        return !loading ? <div className="blog-details-wrapper">
            <MetaTags title={blog?.title}
                      image={generateImageMediaUrl(blog?.mediaMain?.path)}/>
            <h1 className="details-title">{blog?.title}</h1>

            <div className="date-box">
                <div className="badge" style={{color: blog?.badge?.color}}>{blog?.badge?.title}</div>
                <div className="date">{moment(blog?.updatedAt).format("DD.MM.YYYY")}</div>
            </div>

            <div className="image-wrapper">
                <div className="image-proportion">
                    <div className="social-wrapper">
                        <CopyToClipboard text={`${domain}/blog/${blog?.slug}`}
                                         className={"shear-item"}
                                         onCopy={() => this.setState({copied: true})}
                                         onMouseLeave={() => this.setState({copied: false})}>
                            <button onClick={e => {
                                e.stopPropagation()
                            }}>
                                <CopyIcon title={''}/>
                                <div className="copy-text">{copied ? staticTexts?.blog_copy_link_text_copied : staticTexts?.blog_copy_link_text_copy}</div>
                            </button>
                        </CopyToClipboard>

                        <FacebookShareButton url={`${domain}/blog/${blog?.slug}`}
                                             quote={`${blog?.title}`}
                                             className="shear-item"
                        >
                            <FacebookShareIcon title={''}/>
                        </FacebookShareButton>
                        <TwitterShareButton
                            url={`${domain}/blog/${blog?.slug}`}
                            title={`${blog?.title}`}
                            className="shear-item">
                            <TwitterIcon title={''}/>
                        </TwitterShareButton>
                        <LinkedinShareButton
                            url={`${domain}/blog/${blog?.slug}`}
                            title={`${blog?.title}`}
                            className="shear-item">
                            <LinkedinIcon title={''}/>
                        </LinkedinShareButton>
                    </div>
                    <img src={generateImageMediaUrl(blog?.mediaMain?.path)}
                         alt={blog?.mediaMain?.altAttribute ? blog?.mediaMain?.altAttribute : ''}
                         title={blog?.mediaMain?.title ? blog?.mediaMain?.title : ''}/>
                </div>
            </div>

            <div className="sun-editor-editable details-description"
                 dangerouslySetInnerHTML={{__html: blog?.description}}/>
        </div> : <LoadingOutlined style={{fontSize: 22, color: "#666"}}/>
    }
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, ['selectedLanguage', "staticTexts"])
};

const mapDispatchToProps = {GetSingleBlog};

export default connect(mapStateToProps, mapDispatchToProps)(BlogDetails);
