// import packages
import React from "react";
import {connect} from "react-redux";

//import assets

// Import Components
import Markets from "../../containers/homepage/markets/Markets";

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import mapDispatchToProps from "react-redux/lib/connect/mapDispatchToProps";


function MarketsList(props) {
    const {markets, staticTexts} = props;

    return <Markets markets={markets} staticTexts={staticTexts}/>
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'markets',
        'staticTexts',
    ])
};

export default connect(mapStateToProps, mapDispatchToProps) (Markets)
