// Import packages
import React, {useEffect} from "react";
import {connect} from 'react-redux';

// Import styles
import './infoModal.scss';
import {ReactComponent as CloseIcon} from "../../../assets/images/icons/ic_close.svg";

// Import components
import {Modal} from 'antd';

//import utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {PrimaryButton} from "../buttons/Buttons";
import {enableScroll} from "../../../utils/toggleScrolling";
import {LogoWhite, QRIcon} from "../../../assets/images";
import {history} from "../../../configs/history";

function InfoModal(props) {
    const {
        goSignIn,
        productsIsEmpty,
        successModal,
        errorModal,
        visible,
        staticTexts,
        descriptionText,
        confirmModal,
        qrModal
    } = props;

    useEffect(() => {
        if (visible) {
            // disableScroll();
        } else {
            enableScroll()
        }
        return () => {
            enableScroll()
        }
    }, [visible])

    function goToProducts() {
        history.push("/products")
        props.toggleModal()
    }

    return <Modal
        centered
        title={<LogoWhite className={"modal-header-logo"}/>}
        visible={visible}
        okButtonProps={false}
        onCancel={props.toggleModal}
        closeIcon={<CloseIcon className={'modal-close-icon'} title={''}/>}
    >
        <div className={`info-modal-wrapper ${qrModal ? "modal-qr-wrapper" : ""}`}>
            {goSignIn && <>
                <div className="texts-wrapper">
                    <p className="modal-title">{staticTexts?.modal_warning_title}</p>
                    <p className="text-message">{staticTexts?.modal_create_account_text}</p>
                </div>
                <div className="buttons-wrapper">
                    <PrimaryButton className="cancel-btn without-hover bg-grey"
                                   title={staticTexts?.modal_close_modal_btn} cb={props.toggleModal}/>
                    <PrimaryButton title={staticTexts?.modal_btn_register} link={'/sign-in'}/>
                </div>
            </>}
            {productsIsEmpty && <>
                <div className="texts-wrapper">
                    <p className="modal-title">{staticTexts?.modal_error_title}</p>
                    <p className="text-message">{staticTexts?.modal_not_available_text}</p>
                </div>
                <div className="buttons-wrapper">
                    <PrimaryButton title={staticTexts?.modal_btn_products_list} cb={goToProducts}/>
                </div>
            </>}

            {successModal && <>
                <div className="texts-wrapper">
                    <p className="modal-title">{staticTexts?.modal_success_title}</p>
                    <p className="text-message">{staticTexts?.modal_order_success}</p>
                </div>
                <div className="buttons-wrapper">
                    <PrimaryButton title={staticTexts?.modal_see_order_btn} link={'/profile/orders'}/>
                </div>
            </>}
            {errorModal && <>
                <div className="texts-wrapper">
                    <p className="modal-title">{staticTexts?.modal_error_title}</p>
                    <p className="text-message">{staticTexts?.modal_order_error}</p>
                </div>
                <div className="buttons-wrapper">
                    <PrimaryButton title={staticTexts?.modal_btn_return_to_cart} link={'/cart'}/>
                </div>
            </>}

            {confirmModal && <>
                <div className="texts-wrapper">
                    <p className="modal-title">{staticTexts?.modal_warning_title}</p>
                    <p className="text-message">{descriptionText}</p>
                </div>
                <div className="buttons-wrapper">
                    <PrimaryButton className="cancel-btn without-hover bg-grey"
                                   title={staticTexts?.modal_close_modal_btn} cb={props.toggleModal}/>
                    <PrimaryButton title={staticTexts?.modal_cancel_modal_btn} cb={props.cancelOrderInModal}/>
                </div>
            </>}

            {qrModal && <div className="qr-wrapper">
                <QRIcon className={"qr-img"}/>
            </div>}
        </div>
    </Modal>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    "staticTexts",
]);

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InfoModal);
