// import packages
import React, {useEffect} from "react";
import {connect} from "react-redux";

//Import assets
import './shipping.scss'
import {
    ShippingImg
} from "../../assets/images";

//Import components
import {PrimaryButton} from "../../components/uiElements/buttons/Buttons";

//Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {SendEmail, GetInformation} from "../../redux/actions";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {CustomHeader} from "../../components/uiElements/customHeader/CustomHeader";
import {androidUrl, iosUrl} from "../../configs/appUrls";


function Shipping(props) {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        props.GetInformation()
    }, []);

    const {staticTexts} = props;

    return <div className="shipping-page-wrapper">
        <div className="shipping-info-section">
            <div className="image-wrapper">
                <img src={ShippingImg} alt=""/>
            </div>
            <div className="shipping-info-content">
                <div className="shipping-info-title">{staticTexts?.shipping_title}</div>
                <pre className="shipping-info-description">{staticTexts?.shipping_description}</pre>
                <PrimaryButton title={staticTexts?.about_contact_btn} link={{
                    pathname: '/about',
                    state: {
                        goToContactPart: true
                    }
                }}/>
            </div>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'requestLoading',
        'selectedLanguage',
        'information',
    ])
};

const mapDispatchToProps = {
    SendEmail,
    GetInformation
}

export default connect(mapStateToProps, mapDispatchToProps)(Shipping);
