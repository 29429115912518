// import packages
import React, { Component } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { history } from "../../configs/history";

//Import Images
import { Logo, HideEyeIcon, EyeIcon } from "../../assets/images";

//Import Components
import AuthWrapper from "../../components/auth/auth-wrapper/AuthWrapper";
import {  PrimaryButton } from "../../components/uiElements/buttons/Buttons";
import { InputGroup } from "../../components/uiElements/inputGroup/inputGroup";

import SocialLogins from "../../components/auth/loginOptionsCard/SocialLogins";

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import { validateAuth } from "../../utils/validateData";
import { NOTIFICATION_ROW_MODES } from "../../constants/constTypes";
import { LOGIN_ERROR_TYPES } from "../../constants/errorTypes";
import { LoginUser, toggleNotificationRow } from "../../redux/actions";



class SignIn extends Component {
    constructor () {
        super();
        this.state = {
            loginData: {
                username: '',
                password: '',
                grantType: "password"
            },
            errors: {
                username: false,
                password: false,
                isBlocked: false,
                isVerified: false
            },
            passwordVisible: false,
        };
        this.changePassVisibility = this.changePassVisibility.bind(this);
        this.getInputValues = this.getInputValues.bind(this);
        this.handleSignInErrors = this.handleSignInErrors.bind(this);
        this.loginUser = this.loginUser.bind(this);
    }

    componentDidMount () {
        const { location } = this.props;
        if (location?.state?.username) {
            this.setState({
                loginData: {
                    ...this.state.loginData,
                    username: location?.state?.username
                }
            });
            history.replace();
        }
    }

    getInputValues (e) {
        const { name, value } = e.target;
        this.setState({
            loginData: {
                ...this.state.loginData,
                [name]: value.trimStart(),
            }
        })
    }

    changePassVisibility () {
        this.setState({
            passwordVisible: !this.state.passwordVisible
        })
    }

    loginUser () {
        const { loginData } = this.state;
        const { location } = this.props;
        const validation = validateAuth(loginData);

        if (validation.result) {
            const redirectTo = location?.state?.redirectTo;
            this.props.LoginUser(loginData, redirectTo).catch((error) => {
                const status = error && error.response && error.response.data && error.response.data.slug;
                // console.log("error.response", error.response);
                if (status) {
                    this.handleSignInErrors(status);
                    if (status === LOGIN_ERROR_TYPES.UNVERIFIED_ACCOUNT) {
                        history.push({
                            pathname: '/user/confirm-email',
                            state: {
                                redirectedFromActivation: true,
                                username: loginData.username,
                            }
                        });
                    }
                } else {
                    this.setState({
                        errors: {
                            username: false,
                            password: false,
                            isBlocked: false,
                            isVerified: false,
                        }
                    });
                }
            })
        } else {
            this.setState({ errors: validation.errors });
        }
    }

    handleSignInErrors (status) {
        const { staticTexts } = this.props;
        let errorText = '';
        switch ( status ) {
            case LOGIN_ERROR_TYPES.INVALID_USERNAME:
                errorText = staticTexts?.login_error_invalid_username;
                break;
            case LOGIN_ERROR_TYPES.INVALID_PASSWORD:
                errorText = staticTexts?.login_error_invalid_password;
                break;
            case LOGIN_ERROR_TYPES.BLOCKED_ACCOUNT:
                errorText = staticTexts?.login_error_blocked_account;
                break;
            case LOGIN_ERROR_TYPES.UNVERIFIED_ACCOUNT:
                errorText = staticTexts?.login_error_unverified_account;
                break;
        }
        this.props.toggleNotificationRow({
            visible: true,
            text: errorText,
            disappear: true,
            mode: NOTIFICATION_ROW_MODES.ERROR
        });
        this.setState({
            errors: {
                username: status === LOGIN_ERROR_TYPES.INVALID_USERNAME,
                password: status === LOGIN_ERROR_TYPES.INVALID_PASSWORD,
                isBlocked: status === LOGIN_ERROR_TYPES.BLOCKED_ACCOUNT,
                isVerified: status === LOGIN_ERROR_TYPES.UNVERIFIED_ACCOUNT,
            }
        });

    }

    render () {
        const { requestLoading, staticTexts } = this.props;
        const { passwordVisible, loginData, errors } = this.state;
        return <AuthWrapper>
            <div className="form-content sign-in">
                <Link to={'/'} className='form-logo'>
                    <Logo/>
                </Link>

                <div className="inner-part">
                    <div className="form-title">{staticTexts?.login_form_title}</div>
                    <div className="form-sub-title">
                        {staticTexts?.login_header_text_signIn}
                    </div>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        name={'username'}
                        value={loginData.username}
                        error={errors.username}
                        placeholder={staticTexts?.login_placeholder_email}
                        maxLength={50}
                        onKeyDown={(e) => {
                            e.key === 'Enter' && this.loginUser()
                        }}
                        onChange={this.getInputValues}
                    />
                    <InputGroup
                        inputType={'input'}
                        type={`${passwordVisible ? "text" : "password"}`}
                        name={'password'}
                        placeholder={staticTexts?.login_placeholder_password}
                        value={loginData.password}
                        error={errors.password}
                        maxLength={50}
                        onKeyDown={(e) => {
                            e.key === 'Enter' && this.loginUser()
                        }}
                        onChange={this.getInputValues}
                    >
                    <span className={'visibility-icon'} onClick={this.changePassVisibility}>
                        {passwordVisible ? <HideEyeIcon title={''}/> : <EyeIcon title={''}/>}
                       </span>
                    </InputGroup>
                    <Link to={'/user/forgot-password'} className="forgot-password">
                        {staticTexts?.login_forgot_password_text}
                    </Link>
                    <PrimaryButton
                        title={staticTexts?.login_btn_title_login}
                        cb={this.loginUser}
                        className='sign-in'
                        loading={requestLoading}
                        loadingText={staticTexts?.btn_loading_text}/>
                    <SocialLogins/>
                </div>
                <Link to={'/sign-up'} className="under-form-text">
                    {staticTexts?.login_dont_have_account}
                    <span>{staticTexts?.login_create_an_account}</span>
                </Link>

            </div>
        </AuthWrapper>

    }
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    // SocialSignIn,
    LoginUser,
    toggleNotificationRow
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
