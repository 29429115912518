// import packages
import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

//import assets
import "./projectPlans.scss"

// Import Components

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import mapDispatchToProps from "react-redux/lib/connect/mapDispatchToProps";
import {ArrowIcon, LogoSM} from "../../assets/images";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";


function ProjectPlans(props) {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    })
    const {projects, staticTexts} = props;

    return !!projects?.length && <div className='plans-wrapper plans-details-wrapper'>
        <div className="plans-header">
            <div className="title">{staticTexts?.homepage_project_plans_title}</div>
        </div>
        {projects?.length && <div className="plans-content">
            {projects.map(plan => {
                return <div className="plans-item plans-details-item" key={plan?.id}>
                    <div className="inner-side">
                        <div className="left-side">
                            <div className="title">
                                {plan?.title}
                            </div>
                            <div className="description" dangerouslySetInnerHTML={{__html: plan?.description}}/>
                        </div>
                        <div className="right-side">
                            <img src={ generateImageMediaUrl(plan?.mediaMain?.path)} alt=""/>
                        </div>
                    </div>
                </div>
            })}
        </div>}
    </div>
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'projects',
        'staticTexts',
    ])
};

export default connect(mapStateToProps, mapDispatchToProps) (ProjectPlans)
