// import packages
import React, { Component } from "react";
import { connect } from 'react-redux';
import { history } from "../../configs/history";

//Import styles
import "./reset-password.scss"
import { EyeIcon, HideEyeIcon, Logo } from "../../assets/images";

//Import Components

// Import utils
import {
    toggleNotificationRow,
    ResetCurrentUserPassword,
    LoginAfterActivation
} from "../../redux/actions";
import {getPropsFromState} from "../../redux/mapStateToProps";
import { NOTIFICATION_ROW_MODES, USER_ERROR_TYPES } from "../../constants/constTypes";
import { InputGroup } from "../../components/uiElements/inputGroup/inputGroup";
import { PrimaryButton } from "../../components/uiElements/buttons/Buttons";
import AuthWrapper from "../../components/auth/auth-wrapper/AuthWrapper";
import { Link } from "react-router-dom";

class ResetPassword extends Component {
    constructor (props) {
        super(props);
        this.state = {
            password: '',
            repeatPassword: '',
            errors: {
                password: false,
                repeatPassword: false,
            },
            repeatPasswordVisible: false,
            passwordVisible: false,
            username: props?.location?.state?.data?.username,
            code: props?.location?.state?.data?.code,
        };
        this.getPasswordValues = this.getPasswordValues.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
    }

    componentDidMount () {

    }

    getPasswordValues = (e) => {
        const { name, value } = e.target;
        this.setState({
            ...this.state,
            [name]: value.trimStart(),
        })

        if (this.state.errors[name]) {
            this.setState({
                errors: {
                    ...this.state.errors,
                    [name]: false
                }
            })
        }

    };

    changePassVisibility (type) {
        this.setState({
            [type]: !this.state[type]
        })
    }

    validate () {
        const errors = {};
        const { password, repeatPassword } = this.state;
        let result = true;

        if (!password || password.length < 8) {
            errors.password = true;
            result = false;
        }
        if (repeatPassword !== password) {
            errors.repeatPassword = true;
            result = false;
        }

        this.setState({ errors });
        return result;
    }

    resetPassword () {
        const { username, password, code } = this.state;
        const { staticTexts } = this.props;
        const reqData = { username, code, password };

        if (this.validate()) {
            this.props.ResetCurrentUserPassword(reqData).then((res) => {
                this.props.LoginAfterActivation(res.data)
            }).catch((err) => {

                if (err && err.response && err.response.slug === USER_ERROR_TYPES.CODE_NOT_FOUND) {
                    //activation link date expired
                    history.push({
                        pathname: '/user/forgot-password',
                        state: {
                            username: reqData.username,
                        }
                    });
                    this.props.toggleNotificationRow({
                        visible: true,
                        text: this.props.staticTexts?.activation_error_link_date_expired,
                        disappear: 5000,
                        mode: NOTIFICATION_ROW_MODES.INFO
                    });

                } else if (err && err.response && err.response.slug === USER_ERROR_TYPES.CODE_INVALID) {
                    //account already activated
                    this.props.toggleNotificationRow({
                        visible: true,
                        text: this.props.staticTexts?.activation_error_account_invalid_code,
                        disappear: 5000,
                        mode: NOTIFICATION_ROW_MODES.INFO
                    });
                } else {
                    //unknown error
                    history.push('/user/forgot-password')
                }
            })
        }
    }

    render () {
        const {
            username,
            errors,
            password,
            repeatPassword,
            passwordVisible,
            repeatPasswordVisible
        } = this.state;
        const { staticTexts, requestLoading } = this.props;

        return <AuthWrapper>
            <div className="form-content confirm-password-wrapper">
                <Link to={'/'} className='form-logo'>
                    <Logo/>
                </Link>
                <div className="inner-part">
                    <div className="form-title">{staticTexts?.forgot_password_header_title}</div>
                    <div className="form-sub-title">
                        {staticTexts?.forgot_password_header_description}
                        <span className="email"> &nbsp; {username} &nbsp;</span>
                    </div>
                    <InputGroup
                        inputType={'input'}
                        type={'text'}
                        className="hidden-email-field"
                    />
                    <div className="passwords-wrapper">
                        <InputGroup
                            inputType={'input'}
                            type={`${passwordVisible ? "text" : "password"}`}
                            placeholder={'New password'}
                            autocomplete={""}
                            name={'password'}
                            value={password}
                            maxLength={50}
                            error={errors.password}
                            onChange={this.getPasswordValues}>
                            <span className={'visibility-icon'}
                                  onClick={this.changePassVisibility.bind(this, 'passwordVisible')}>
                                {passwordVisible ? <HideEyeIcon title={''}/> :
                                    <EyeIcon title={''}/>}

                            </span>
                        </InputGroup>
                        <InputGroup
                            inputType={'input'}
                            type={`${repeatPasswordVisible ? "text" : "password"}`}
                            placeholder={'Repeat new password'}
                            name={'repeatPassword'}
                            value={repeatPassword}
                            maxLength={50}
                            error={errors.repeatPassword}
                            onChange={this.getPasswordValues}>
                            <span className={'visibility-icon'}
                                  onClick={this.changePassVisibility.bind(this, 'repeatPasswordVisible')}>
                                {repeatPasswordVisible ? <HideEyeIcon title={''}/> :
                                    <EyeIcon title={''}/>}

                            </span>
                        </InputGroup>
                    </div>
                    <PrimaryButton title={'Reset'}
                                   className="send-btn"
                                   disabled={
                                       !repeatPassword ||
                                       !password}
                                   cb={this.resetPassword}
                                   loading={requestLoading}
                    />
                </div>
            </div>
        </AuthWrapper>
    }
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'requestLoading',
    ])
};

const mapDispatchToProps =
    {
        toggleNotificationRow,
        LoginAfterActivation,
        ResetCurrentUserPassword
    }
;

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
