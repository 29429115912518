export const PRODUCTS_CONSTS = {
    GET_PRODUCTS: "GET_PRODUCTS",
    CLEAR_PRODUCTS: "CLEAR_PRODUCTS",
    GET_SPECIAL_PRODUCTS: "GET_SPECIAL_PRODUCTS",
    CLEAR_PRODUCT_FILTERS: "CLEAR_PRODUCT_FILTERS",
    GET_SINGLE_PRODUCT: "GET_SINGLE_PRODUCT",
    CLEAR_SINGLE_PRODUCT: "CLEAR_SINGLE_PRODUCT",
    GET_IDENTICAL_PRODUCTS: "GET_IDENTICAL_PRODUCTS",
    TOGGLE_PRODUCT_LIKE: "TOGGLE_PRODUCT_LIKE",
    GET_FAVORITE_PRODUCTS: "GET_FAVORITE_PRODUCTS",
    ADD_PRODUCT_REVIEW: "ADD_PRODUCT_REVIEW",
    GET_PRODUCTS_REVIEWS: "GET_PRODUCTS_REVIEWS",
    CLEAR_PRODUCTS_REVIEWS: "CLEAR_PRODUCTS_REVIEWS",
    UPDATE_PRODUCT_REVIEW: "UPDATE_PRODUCT_REVIEW",
    DELETE_PRODUCTS_REVIEWS: "DELETE_PRODUCTS_REVIEWS",
    GET_PRODUCTS_REVIEW_REPLIES: "GET_PRODUCTS_REVIEW_REPLIES",
    CLEAR_PRODUCTS_REVIEW_REPLIES: "CLEAR_PRODUCTS_REVIEW_REPLIES",
    ADD_PRODUCTS_REVIEW_REPLIES: "ADD_PRODUCTS_REVIEW_REPLIES",
    UPDATE_PRODUCT_REVIEW_REPLIES: "UPDATE_PRODUCT_REVIEW_REPLIES",
    DELETE_PRODUCTS_REVIEW_REPLIES: "DELETE_PRODUCTS_REVIEW_REPLIES",
};
