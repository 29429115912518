// Import packages
import React, {useState, useEffect} from "react";
import {connect} from 'react-redux';
import debounce from 'lodash.debounce';

// Import styles
import './settings.scss';

// Import components
import {InputGroup} from "../../../components/uiElements/inputGroup/inputGroup";
import {PrimaryButton} from "../../../components/uiElements/buttons/Buttons";

//import utils
import {
    UpdateCurrentUser,
    GetCurrentUser,
    UpdateCurrentUserPassword,
    GetRegions,
    GetResidences,
    toggleNotificationRow,
    UploadUserProfilePicture
} from "../../../redux/actions";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {NOTIFICATION_ROW_MODES} from "../../../constants/constTypes";
import PasswordChangeBox from "../passwordChange/PasswordChangeBox";
import ImageUploader from "../../../components/uiElements/profileImageUploader/profileImageUploader";
import {generateFileMediaUrl, generateImageMediaUrl} from "../../../utils/generateMediaUrl";
import {resizeImage} from "../../../utils/resizeImage";
import {EmailIcon} from "react-share";
import {EditEmailIcon} from "../../../assets/images";
import {history} from "../../../configs/history";

function Settings(props) {
    const {loggedInUser, staticTexts, selectedLanguage, requestLoading} = props;
    const user = loggedInUser ?? {};
    const [userInfo, setUserInfo] = useState({
        firstName: user?.firstName || '',
        lastName: user?.lastName || '',
        region: user?.region?.id || undefined,
        residence: user?.region?.residence?.id || undefined,
        address: user?.address || '',
        phone: user?.phone || '',
        username: user?.username || '',
    });
    const [profilePictureFile, setProfilePictureFile] = useState(null);
    const [updatedDataMap, setUpdatedDataMap] = useState(new Map());
    const [errors, setErrors] = useState({});


    useEffect(() => {
        !props?.regions?.length && props.GetRegions();
        user?.region?.id &&
        props.GetResidences(user?.region?.id);
        props.getPageTitle(staticTexts?.settings_title)
    }, []);

    // useEffect(() => {
    //     if (userInfo.region) {
    //         props.GetResidences(userInfo.region)
    //     } else {
    //         setUserInfo({
    //             ...userInfo,
    //             residence: undefined
    //         })
    //     }
    //
    // }, [userInfo.region, selectedLanguage]);

    function setMap(entries) {
        setUpdatedDataMap(new Map(entries))
    }

    const getInputValues = (e) => {
        const {name, value} = e.target;
        if (name === 'phone' && !/^[0-9]*$/.test(value)) {
            return;
        }
        value !== user?.[name] ? updatedDataMap.set(name, value) :
            updatedDataMap.delete(name);
        setMap(updatedDataMap);
        setUserInfo({
            ...userInfo,
            [name]: value.trimStart(),
        })
    };

    function getSelectedRegion(name, value) {
        if (value !== user?.region?.id) {
            updatedDataMap.set(name, value);
            updatedDataMap.set('residence', null);
        } else {
            updatedDataMap.delete(name);
        }
        setMap(updatedDataMap);
        props.GetResidences(value);
        setUserInfo({
            ...userInfo,
            [name]: value,
            residence: undefined
        })
    }

    function getSelectedResidence(name, value) {
        value !== user?.residence?.id ? updatedDataMap.set(name, value) :
            updatedDataMap.delete(name);
        setMap(updatedDataMap);
        setUserInfo({
            ...userInfo,
            [name]: value
        })
        setErrors({
            ...errors,
            residence: false
        })
    }

    const getSelectedImageFile = (file) => {
        updatedDataMap.set('profilePicturePath', '');
        setMap(updatedDataMap);
        setProfilePictureFile(file);
    };

    const changeEmail = () => {
        history.push({
            pathname: '/user/edit-email',
            state: {
                changeEmail: true,
            }
        });
    }

    const updateUser = async () => {
        const requiredFields = ['firstName', 'lastName',];
        let reqData = {};
        let isValid = true;
        updatedDataMap.forEach((value, key) => {
            if (key === 'phone'  && value?.length < 8 && !!value) {
                setErrors({
                    phone: true
                });
                isValid = false;
            }
            reqData = {
                ...reqData,
                [key]: value
            }
        });
        if(reqData.region && !reqData?.residence) {
            setErrors({
                residence: true
            });
            isValid = false;
        }
        requiredFields.forEach(key => {
            if (!userInfo[key]) {
                isValid = false;
                setErrors({
                    ...errors,
                    [key]: true,
                })
            }
        });

        if (isValid) {
            if(reqData.residence && !reqData.region) {
                reqData.region = userInfo.region
            }
            if (profilePictureFile) {
                let formData = new FormData();
                formData.append('profilePicture', profilePictureFile);
                let newProfilePicture = await props.UploadUserProfilePicture(formData, 'profile-picture');
                if (newProfilePicture) {
                    reqData.profilePicturePath = newProfilePicture?.id;
                }
            }
            props.UpdateCurrentUser(reqData).then(() => {
                props.toggleNotificationRow({
                    visible: true,
                    text: staticTexts?.profile_update_success,
                    disappear: true,
                    mode: NOTIFICATION_ROW_MODES.SUCCESS
                });
                updatedDataMap.clear();
                setMap(updatedDataMap);
                setErrors({});
                // props.GetCurrentUser()
            }).catch(() => {
                props.toggleNotificationRow({
                    visible: true,
                    text: staticTexts?.notification_default_error,
                    disappear: true,
                    mode: NOTIFICATION_ROW_MODES.ERROR
                });
            });
        }
    };

    const regionOptions = props?.regions?.map(item => {
        return {
            id: item?.id,
            name: item?.title
        }
    }) || [];
    const residencesOptions = props?.residences?.map(item => {
        return {
            id: item?.id,
            name: item?.title
        }
    }) || [];

    return <section className='settings-wrapper'>
        <div className="uploader-wrapper">
            <ImageUploader noImageError={''}
                           staticTexts={staticTexts}
                           image={generateImageMediaUrl(loggedInUser?.profilePicturePath?.path, 'user')}
                           onChange={getSelectedImageFile}/>
        </div>
        <div className="fields-wrapper">
            <div className="left-wrapper">
                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    name={'firstName'}
                    placeholder={staticTexts?.settings_label_firstName}
                    value={userInfo.firstName}
                    error={errors.firstName}
                    maxLength={50}
                    onChange={getInputValues}
                />

                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    name={'lastName'}
                    value={userInfo.lastName}
                    placeholder={staticTexts?.settings_label_lastName}
                    error={errors.lastName}
                    maxLength={50}
                    onChange={getInputValues}
                />
                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    name={'phone'}
                    placeholder={staticTexts?.settings_label_phoneNumber}
                    value={userInfo.phone}
                    error={errors.phone}
                    maxLength={8}
                    onChange={getInputValues}
                    className={'phone phone-input'}
                ><span className="phone-code">+374</span></InputGroup>
                <PasswordChangeBox UpdateCurrentUserPassword={props.UpdateCurrentUserPassword}
                                   toggleNotificationRow={props.toggleNotificationRow}
                                   loggedInUser={loggedInUser}
                                   staticTexts={staticTexts}/>
            </div>
            <div className="right-wrapper">
                {!user?.isSocial && <div className="email-wrapper">
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        name={'email'}
                        placeholder={staticTexts?.settings_label_email}
                        value={userInfo.username}
                        maxLength={50}
                        disabled
                        onChange={getSelectedRegion}
                    />
                    <PrimaryButton cb={changeEmail} title={<EditEmailIcon/>}/>
                </div>}
                <InputGroup
                    inputType={'select'}
                    type={"text"}
                    name={'region'}
                    placeholder={staticTexts?.settings_label_country}
                    value={userInfo.region}
                    error={errors.region}
                    options={regionOptions}
                    onChange={getSelectedRegion}
                />
                <InputGroup
                    inputType={'select'}
                    type={"text"}
                    name={'residence'}
                    placeholder={staticTexts?.settings_label_city}
                    value={userInfo.residence}
                    error={errors.residence}
                    options={residencesOptions}
                    onChange={getSelectedResidence}
                />
                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    name={'address'}
                    placeholder={staticTexts?.settings_label_address}
                    value={userInfo.address}
                    error={errors.address}
                    maxLength={50}
                    onChange={getInputValues}
                />
                <PrimaryButton title={staticTexts?.settings_btn_remember_user_data}
                               className={"without-hover"}
                               disabled={!updatedDataMap.size || loggedInUser?.isBlocked}
                               loading={requestLoading}
                               cb={updateUser}/>
            </div>
        </div>
    </section>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'loggedInUser',
    'requestLoading',
    'selectedLanguage',
    'staticTexts',
    'regions',
    'residences',
]);

const mapDispatchToProps = {
    UpdateCurrentUser,
    GetCurrentUser,
    UpdateCurrentUserPassword,
    GetRegions,
    GetResidences,
    toggleNotificationRow,
    UploadUserProfilePicture
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
