//import Packages
import React, {useEffect, useRef, useState} from "react";
import debounce from 'lodash.debounce';
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";
import GoogleMapReact from 'google-map-react';
import {Modal} from "antd";

//import assets
import "./address-map-Modal.scss";
import {LocationIcon, LogoWhite, CloseIcon, CurrentLocationIcon} from "../../../assets/images";

//import Components
import {InputGroup} from "../inputGroup/inputGroup";
import {PrimaryButton} from "../buttons/Buttons";

//import utils
import {MAP_DEFAULT_CENTER, MAP_DEFAULT_ZOOM} from "../../../constants/constants";
import {GOOGLE_MAP_KEY} from "../../../configs/variables";
import {AddAddress} from "../../../redux/actions";
import {mapStateToProps} from "../../../redux/mapStateToProps";

function AddressMapModal(props) {
    const debouncedAutoCompleteAddress = debounce(autoCompleteAddress, 1000);
    const {isOpen, closeModal, staticTexts} = props;
    const refToServices = useRef({});
    const mapGoogle = useRef({});
    let location = useLocation();

    //STATE
    const [address, setAddress] = useState({
        mainAddress: props?.address?.mainAddress || "",
        lat: '',
        lng: '',
        secondaryAddress: props?.address?.secondaryAddress || "",
    });
    const [googleMap, setGoogleMap] = useState(null);
    const [dragging, setDragging] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [centerChangedByAddress, setCenterChangedByAddress] = useState(false);
    const [mapCenter, setCenter] = useState(MAP_DEFAULT_CENTER);
    const [errors, setErrors] = useState({});

    let mapHTML = document.getElementsByClassName('gm-style');
    let markerImg = document.getElementsByClassName('marker-fixed');
    if( markerImg[0]){
        dragging ?  markerImg[0].setAttribute('class', 'marker-fixed dragging')
            : markerImg[0].setAttribute('class', 'marker-fixed')
    }

    useEffect(() => {
       location.pathname !== '/pharmacies' && mapHTML[0] &&
        mapHTML[0].insertAdjacentHTML('afterend', '<div class="marker-fixed"><div id="marker-icon"/></div></div>');
        // (async () => {
        //     const permissionStatus = await navigator.permissions.revoke({
        //         name: 'geolocation'
        //     });
        //     console.log(permissionStatus);
        // })();
        // setAddress({
        //     mainAddress: props?.address?.mainAddress
        // })
    }, [mapHTML[0]], dragging);
    // console.log(mapHTML, 'mapHTML');
    //FUNCTIONS
    const validate = () => {
        const err = {};
        let result = true;

        if (!address?.mainAddress) {
            result = false;
            err.mainAddress = true;
        }
        // console.log("aaaa",err);
        setErrors(err);
        return result;
    };

    function handleApiLoaded(map, maps) {
        // console.log('init google service')
        setGoogleMap(map);
        const _geoCoder = maps && new maps.Geocoder();
        const _autocompleteService = maps && new maps.places.AutocompleteService();

        refToServices.current = {
            geoCoder: _geoCoder,
            autocomplete: _autocompleteService,
        }
        getAddressFromLatLng(mapCenter);
    }

    function onChange({center, zoom, bounds, marginBounds}) {
        // console.group('ONCHANGE')
        // console.log('mapCenter', mapCenter);
        // console.log('zoom', zoom);
        // console.log('centerChangedByAddress', centerChangedByAddress);
        // console.log('bounds', bounds);
        // console.log('marginBounds', marginBounds);
        // console.groupEnd();
        // console.log("mapi ONCHANGE");
        setCenter(center)
        if (!centerChangedByAddress) {
            getAddressFromLatLng(center);
        } else {
            setCenterChangedByAddress(false);
        }
    }

    function getAddressFromLatLng(location) {
        const lat_lng = {lat: location.lat, lng: location.lng};
        const geoCoder = refToServices?.current?.geoCoder;
        geoCoder && geoCoder.geocode({location: lat_lng}, (results, status) => {
            // console.log('status', status);
            if (status === "OK") {
                let _address = results[0];
                // console.log(_address)
                // if (_address && _address.startsWith('Unnamed Road')) {
                //     _address = _address.substring('Unnamed Road, '.length)
                // }
                if (_address) {
                    // console.log('_address', _address)
                    setAddress({
                        ...address,
                        mainAddress: _address?.formatted_address,
                        lat: _address?.geometry?.location?.lat(),
                        lng: _address?.geometry?.location?.lng(),
                    });
                }
            }
        });
    }

    function handleChangeAddress(e) {
        const value = e.target.value;
        // console.log("target",e);
        setAddress({
            ...address,
            mainAddress: value
        });
        if (value.length > 2) {
            debouncedAutoCompleteAddress();
        } else {
            setSuggestions([])
        }
    }

    function autoCompleteAddress() {
        const autocomplete = refToServices?.current?.autocomplete;
        autocomplete && autocomplete.getPlacePredictions({input: address?.mainAddress},
            (predictions, status) => {
                if (Array.isArray(predictions) && predictions?.length) {
                    setSuggestions(predictions.slice(0, 10))
                } else {
                    setSuggestions([]);
                }
            }
        );
    }

    function getLocationByPlaceId(placeId) {
        const geoCoder = refToServices?.current?.geoCoder;
        geoCoder && geoCoder.geocode({placeId: placeId}, (results, status) => {
            if (status === "OK") {
                if (results[0]) {
                    const geoLocation = results[0]?.geometry?.location;
                    if (geoLocation) {
                        setCenterChangedByAddress(true);
                        const location = {
                            lat: geoLocation?.lat(),
                            lng: geoLocation?.lng(),
                        }
                        googleMap.setCenter(location);
                    }
                }
            }
        });

    }

    function selectSuggestion(suggestion) {
        getLocationByPlaceId(suggestion?.place_id)
        setAddress({
            ...address,
            mainAddress: suggestion.description
        });
        setSuggestions([]);
    }

    function getDirection(e) {
        const {value} = e.target;
        setAddress({
            ...address,
            secondaryAddress: value
        })
    }

    function sendAddress() {
        if (validate()) {
            props.getAddress(address?.mainAddress || address?.secondaryAddress)
            props.addresFromHeader && props.AddAddress({
                mainAddress: address?.mainAddress,
                lat: mapCenter?.lat,
                lng: mapCenter?.lng,
                secondaryAddress: address?.secondaryAddress
            })
        }
    }

    function getCurrentLocation() {
        // navigator.geolocation.getCurrentPosition(function(position) {
        //     const location = {
        //         lat: position.coords.latitude,
        //         lng: position.coords.longitude,
        //     };
        //     googleMap.setCenter(location);
        // });

        navigator.geolocation.getCurrentPosition(success => {
            const location = {
                lat: success.coords.latitude,
                lng: success.coords.longitude,
            };
            googleMap.setCenter(location);
        }, failure => {
            if (failure.message.startsWith("Only secure origins are allowed")) {
                // console.log(failure.message);
                // Secure Origin issue.
            }
        });
    }

    let defaultAddress = props.address?.lat && props?.address?.lng ? {
        lat: props.address?.lat,
        lng: props.address?.lng
    } : MAP_DEFAULT_CENTER

    return <Modal
        centered
        title={<LogoWhite className={"modal-header-logo"}/>}
        visible={isOpen}
        okButtonProps={false}
        onCancel={closeModal}
        closeIcon={<CloseIcon className={'modal-close-icon'} title={''}/>}
        className={`address-map-modal-wrapper`}
       >
        <div className={`address-map-modal`}>
            <div className={'top-part'}>
                <header className={'modal-header'}>
                    <LocationIcon className={"marker"} title={""}/>
                    <span>{staticTexts?.map_modal_title}</span>
                </header>
                <div className={'main-address'}>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        name={'mainAddress'}
                        error={errors.mainAddress}
                        value={address?.mainAddress}
                        label={<span>{staticTexts?.map_modal_label_residence_street} <span
                            className={"required-field"}>*</span></span>}
                        maxLength={50}
                        onChange={handleChangeAddress}
                        id={'address-content'}>
                        <ul className={`suggestions-wrapper ${suggestions?.length ? 'active' : ''}`}>
                            {suggestions.map((item, index) => {
                                return <li key={index}
                                           className={'item'}
                                           onClick={() => selectSuggestion(item)}>
                                    {item?.description}
                                </li>
                            })}
                        </ul>

                    </InputGroup>

                </div>
                <div className={'secondary-address'}>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        name={'secondaryAddress'}
                        value={address?.secondaryAddress}
                        label={staticTexts?.map_modal_label_building
                        + ", " + staticTexts?.map_modal_label_building_entrance
                        + ", " + staticTexts?.map_modal_label_building_floor
                        + ", " + staticTexts?.map_modal_label_building_house
                        }
                        maxLength={50}
                        onChange={getDirection}
                    />
                </div>
            </div>
            <div className="map-wrapper">
                <div className={'map'} ref={mapGoogle}>
                    <div className="current-location" onClick={getCurrentLocation}><CurrentLocationIcon title={''}/></div>
                    <GoogleMapReact
                        bootstrapURLKeys={{
                            key: GOOGLE_MAP_KEY,
                            libraries: 'places'
                        }}
                        defaultCenter={defaultAddress}
                        //center={center}
                        distanceToMouse={() => {
                        }}
                        onDrag={() => {
                            setDragging(true);
                        }}
                        onDragEnd={() => {
                            setDragging(false);
                        }}
                        defaultZoom={MAP_DEFAULT_ZOOM}
                        onChange={onChange}
                        yesIWantToUseGoogleMapApiInternals={true}
                        onGoogleApiLoaded={({map, maps}) => handleApiLoaded(map, maps)}
                    >
                        {/*<Marker lat={center.lat} lng={center.lng}/>*/}
                    </GoogleMapReact>
                    {/*<div className={`marker-fixed ${dragging ? 'dragging' : ''}`}>*/}
                    {/*    <img src={require('../assets/images/icons/marker.svg')}/>*/}
                    {/*</div>*/}
                </div>
            </div>
            <div className={'modal-actions'}>
                <PrimaryButton title={staticTexts?.map_modal_btn_cancel} className={'bg-white without-hover'} cb={closeModal}/>
                <PrimaryButton title={staticTexts?.map_modal_btn_save} cb={sendAddress}/>
            </div>
        </div>
    </Modal>
}

const mapDispatchToProps = {
    AddAddress
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressMapModal)
