import {
    request,
    _urlStaticTexts,
    _urlSlider,
    _urlCategories,
    _urlBanners,
    _urlMarkets,
    _urlSections,
    _urlFilters,
    _urlPartners,
    _urlContacts,
    _urlLanguage, _urlCustomPages, _urlRegions, _urlInformation, _urlProjects
} from "../api";
import {GENERAL_CONSTS, PRODUCTS_CONSTS, UTIL_CONSTS} from "../constants";

export const getBrowserLanguage = () => {
    return navigator.language.split('-')[0] || null;
};

export const GetStaticTexts = () => {
    const requestData = {
        url: `${_urlStaticTexts}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_STATIC_TEXTS,
                    payload: data
                })
            })
    };
};

export const GetLanguages = () => {
    const requestData = {
        url: `${_urlLanguage}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: GENERAL_CONSTS.GET_LANGUAGES,
                    payload: data
                })
            })
    };
};

export const ChangeLanguage = (data) => {
    // console.log(data, 'CHANGE');
    return dispatch => {
        dispatch({
            type: GENERAL_CONSTS.CHANGE_LANGUAGE,
            payload: data
        })
    }
};

export const GetCurrency = (currency) => {
    const requestData = {
        url: `https://free.currconv.com/api/v7/convert?q=AMD_${currency}&compact=ultra&apiKey=64bedd66448a0d7fd953`,
        method: "GET",
    };

    return dispatch => {
        if (currency !== 'AMD') {
            return request(requestData)
                .then((res) => {
                    const data = res && res.data;
                    data && dispatch({
                        type: GENERAL_CONSTS.GET_CURRENCY,
                        payload: data
                    })
                })
        } else {
            dispatch({
                type: GENERAL_CONSTS.GET_CURRENCY,
                payload: {AMD_AMD: 1}
            })
        }

    };
};

export const GetSlider = () => {
    const requestData = {
        url: `${_urlSlider}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_SLIDER,
                    payload: data
                });
                dispatch({type: UTIL_CONSTS.END_LOADING});
            })
    };
};

export const GetCategories = () => {
    const requestData = {
        url: `${_urlCategories}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_CATEGORIES,
                    payload: data
                })
            })
    };
};

export const GetSingleCategory = (id) => {
    const requestData = {
        url: `${_urlCategories}/${id}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_SINGLE_CATEGORY,
                    payload: data
                })
            })
    };
};

export const GetFilters = (categoryId = null) => {
    const requestData = {
        url: `${_urlFilters}`,
        method: "GET",
        languageFlag: true
    };

    categoryId && (requestData.url += `?categoryIds=${categoryId}`);

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_FILTERS,
                    payload: {
                        data: data,
                        categoryId,
                    }
                })
            })

    };
};

export const GetSingleFilter = (id) => {
    const requestData = {
        url: `${_urlFilters}/${id}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_SINGLE_FILTER,
                    payload: data
                })
            })
    };
};

export const GetMarkets = () => {
    const requestData = {
        url: `${_urlMarkets}`,
        method: "GET",
        languageFlag: true,
        token: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_MARKETS,
                    payload: data
                })
            })
    };
};


export const GetMarketById = (id) => {
    const requestData = {
        url: `${_urlMarkets}/${id}`,
        method: "GET",
        languageFlag: true,
        token: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_MARKET_BY_ID,
                    payload: data
                })
                return res && res.data;
            })
    };
};

export const ClearMarketById = () => {
    return async dispatch => {
        await dispatch({
            type: GENERAL_CONSTS.CLEAR_MARKET_BY_ID
        })
    };
};
export const GetBanners = () => {
    const requestData = {
        url: `${_urlBanners}`,
        method: "GET",
        languageFlag: true,
        token: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_BANNERS,
                    payload: data
                })
            })
    };
};

export const GetProjects = () => {
    const requestData = {
        url: `${_urlProjects}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_PROJECTS,
                    payload: data
                })
            })
    };
};

export const GetSections = () => {
    const requestData = {
        url: `${_urlSections}`,
        method: "GET",
        languageFlag: true,
        token: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_SECTIONS,
                    payload: data
                })
            })
    };
};

export const GetSingleSection = ({
                                     offset = 0,
                                     limit = 20,
                                     reset = true,
                                     id,
                                 } = {}) => {
    const requestData = {
        url: `${_urlSections}/${id}/products?offset=${offset}&limit=${limit}`,
        method: "GET",
        languageFlag: true,
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_SINGLE_SECTION,
                    payload: {
                        data: data,
                        reset,
                        hasMore: data?.length === limit
                    }
                });
            })
    };
};


export const ClearSingleSection = () => {
    return async dispatch => {
        await dispatch({
            type: GENERAL_CONSTS.CLEAR_SINGLE_SECTION
        })
    };
};

export const GetRegions = () => {
    const requestData = {
        url: _urlRegions,
        languageFlag: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_REGIONS,
                    payload: data
                });
                return data;
            }).catch(() => {

            })
    };
};

export const GetResidences = (regionId) => {

    const requestData = {
        url: `${_urlRegions}/${regionId}/residences`,
        languageFlag: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async ({data}) => {
                await dispatch({
                    type: GENERAL_CONSTS.GET_RESIDENCES,
                    payload: data
                })
                return data
            }).catch(() => {

            })
    };
};

export const ClearResidences = () => {
    return dispatch => {
        dispatch({
            type: GENERAL_CONSTS.CLEAR_RESIDENCES
        })
    }
};

export const GetContacts = () => {
    const requestData = {
        url: `${_urlContacts}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_CONTACTS,
                    payload: data
                })
            })
    };
};

export const SendEmail = (data) => {
    const requestData = {
        url: `${_urlContacts}`,
        method: "POST",
        data
    };
    return dispatch => {
        dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData).finally(() => {
            dispatch({type: UTIL_CONSTS.END_LOADING})
        })
    }
};

export const GetInformation = () => {
    const requestData = {
        url: `${_urlInformation}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_INFORMATION,
                    payload: data
                })
            })
    };
};

export const GetCustomPages = (url) => {
    const requestData = {
        url: `${_urlCustomPages}/${url}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {

        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_CUSTOM_PAGES,
                    payload: data
                })
            })
    };
};


