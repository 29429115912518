//----------------------------------Official-server---------------------------------------------------
const OFFICIAL_URLS = {
    ADMIN_URL: "https://app.smartlife.am/admin/api/v1",
    AUTH_URL: "https://app.smartlife.am/auth/api/v1",
    PRODUCT_URL: "https://app.smartlife.am/shop/api/v1",
    USERS_URL: "https://app.smartlife.am/users/api/v1",
    MEDIA_URL: "https://app.smartlife.am/resources/api/v1",
    ORDER_URL: "https://app.smartlife.am/orders/api/v1",
    NOTIFICATION_URL: "https://app.smartlife.am/notifications/api/v1",
    HOST_MEDIA_URL_V1: "https://app.smartlife.am/resources",
    SOCKET_NOTIFICATION_URL: "https://app.smartlife.am",
    PAYMENT_URL: "https://app.smartlife.am/payment/api/v1",
    DOMAIN: "https://smartlife.am",
}

//----------------------------------4Steps-server---------------------------------------------------
const TESTING_URLS = {
    ADMIN_URL: "http://173.249.20.192:8702/api/v1",
    AUTH_URL: "http://173.249.20.192:8700/api/v1",
    PRODUCT_URL: "http://173.249.20.192:8704/api/v1",
    USERS_URL: "http://173.249.20.192:8701/api/v1",
    MEDIA_URL: "http://173.249.20.192:8703/api/v1",
    ORDER_URL: "http://173.249.20.192:8705/api/v1",
    NOTIFICATION_URL: "http://173.249.20.192:8706/api/v1",
    HOST_MEDIA_URL_V1: "http://173.249.20.192:8703",
    SOCKET_NOTIFICATION_URL: "http://173.249.20.192:8706",
    PAYMENT_URL: "http://173.249.20.192:8707/api/v1",
    DOMAIN: "http://smartlife.4steps.am",
}

const {
    ADMIN_URL, AUTH_URL, PRODUCT_URL, USERS_URL, MEDIA_URL, ORDER_URL, NOTIFICATION_URL, HOST_MEDIA_URL_V1,
    SOCKET_NOTIFICATION_URL, PAYMENT_URL, DOMAIN
} = process.env.REACT_APP_ENVIRONMENT === 'production'
    ? OFFICIAL_URLS
    : TESTING_URLS;

export const _hostMedia = HOST_MEDIA_URL_V1;
export const _urlOauth = AUTH_URL + "/jwt";
export const _urlUsers = USERS_URL + "/users";
export const _urlLanguage = PRODUCT_URL + "/languages";
export const _urlMedia = MEDIA_URL + "/media";
export const _urlStaticTexts = ADMIN_URL + "/static-texts";
export const _urlSlider = PRODUCT_URL + "/sliders";
export const _urlCategories = PRODUCT_URL + "/categories";
export const _urlFilters = PRODUCT_URL + "/filters";
export const _urlBanners = PRODUCT_URL + "/banners";
export const _urlMarkets = PRODUCT_URL + "/markets";
export const _urlSections = PRODUCT_URL + "/sections";
export const _urlPartners = PRODUCT_URL + "/partners";
export const _urlProjects = PRODUCT_URL + "/projects";
export const _urlProducts = PRODUCT_URL + "/products";
export const _urlCustomPages = PRODUCT_URL + "/custom-pages";
export const _urlRegions = USERS_URL + "/regions";
export const _urlContacts = USERS_URL + "/contacts";
export const _urlInformation = PRODUCT_URL + "/information";
export const _urlBlogs = PRODUCT_URL + "/blogs";
export const _urlBlogTypes = PRODUCT_URL + "/blog-types";
export const _urlOrders = ORDER_URL + "/orders";
export const _urlBonusCards = ORDER_URL + "/bonus-cards";
export const _urlOrderStatuses = ORDER_URL + "/order-statuses";
export const _urlPromoCodes = ORDER_URL + "/promo-codes";
export const _urlPayment = PAYMENT_URL + "/payment";
export const _urlRequest = PRODUCT_URL + "/request";
export const _urlSubscribe = USERS_URL + "/subscribe";
export const _urlNotification = NOTIFICATION_URL;

export const _urlSocketNotification = SOCKET_NOTIFICATION_URL;
export const domain = DOMAIN;
