// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

//Import assets
import './rate.scss'
import {FillStar, EmptyStar, StarHalfIcon} from "../../assets/images";
import defaultFileLogo from '../../assets/images/default_img.jpg';

//Import components
import {PrimaryButton} from "../../components/uiElements/buttons/Buttons";

//Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import Rating from "react-rating";
import {useParams} from "react-router-dom";
import order from "../../redux/reducers/order";
import {GetSingleOrder, RateOrderProduct} from "../../redux/actions";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {history} from "../../configs/history";
import {LoadingOutlined} from "@ant-design/icons";


function Rate(props) {
    const [reviewRate, setReviewRate] = useState({});
    const [loading, setLoading] = useState({});
    const params = useParams();
    const {orderId} = params
    const {staticTexts, singleOrder, requestLoading} = props;

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        setLoading(true)
        orderId && props.GetSingleOrder(orderId).then((res) => {
            setLoading(false)
            if (!res?.id || res?.rated) {
                history.push("/rated")
            }
        })
    }, []);

    const rateProduct = (value, id) => {
        setReviewRate({
            ...reviewRate,
            [id]: value
        })
    }

    function rateOrder() {
        Object.keys(reviewRate).forEach(productId => {
            props.RateOrderProduct(productId, singleOrder?.id, reviewRate[productId]).finally(() => {
                history.push(`/profile/orders/${singleOrder?.orderNumber}`)
            })
        })

    }

    return !loading ? <div className="rate-page-wrapper">
        <div className="rate-header">
            <div className="product-part">
                <span className={'product-part-desktop-text'}>{staticTexts?.rate_products_desktop_title}</span>
                <span className={'product-part-mobile-text'}>{staticTexts?.rate_products_mobile_title}</span>
            </div>
            <div className="rate-part">{staticTexts?.rate_right_title}</div>
        </div>
        {!!singleOrder?.products?.length && singleOrder?.products?.map(product => {
            return <div className="rate-item">
                <div className="product-part">
                    <img src={generateImageMediaUrl(product?.mediaMain?.path)} className="product-image" alt={""}/>
                    <div className="product-content">
                        <div className="product-title">{product?.title}
                        </div>
                        {product?.price && <div className="product-price">{product?.price} ֏</div>}
                    </div>
                </div>
                <div className="rate-part">
                    <div className="rated">
                        <span className="rate-description">{staticTexts?.rate_right_subtitle_rated}</span>
                        <div className="rete-point">3.0</div>
                        <FillStar className='star-icon'/>
                    </div>
                    <div className="rating">
                        <span className="rate-description">{staticTexts?.rate_right_subtitle_your_rate}</span>
                        <Rating
                            emptySymbol={<EmptyStar className='star-icon empty-star-icon' title=''/>}
                            fullSymbol={<FillStar className='star-icon fill-star-icon' title=''/>}
                            initialRating={reviewRate[product?.id]}
                            onChange={(value => rateProduct(value, product?.id))}
                        />
                    </div>
                </div>
            </div>
        })}
        <PrimaryButton title={staticTexts?.rate_btn_title} className={"without-hover"} cb={rateOrder} loading={requestLoading}
                       disabled={Object.keys(reviewRate).length !== singleOrder?.products?.length}/>
    </div> : <LoadingOutlined style={{
        fontSize: 22,
        color: "#666",
    }}
    />
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'requestLoading',
        'selectedLanguage',
        'information',
        'singleOrder',
        'orders',
    ])
};

const mapDispatchToProps = {
    GetSingleOrder,
    RateOrderProduct
}

export default connect(mapStateToProps, mapDispatchToProps)(Rate);
