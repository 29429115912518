// Import packages
import React, {useState, useEffect} from "react";
import {connect} from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';

// Import styles
import './favorites.scss';

// Import components
import ProductCard from "../../../components/uiElements/cards/productCard/ProductCard";

//import utils
import {GetFavoriteProducts} from "../../../redux/actions";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {FavoritesEmpty} from "../../../assets/images";
import {LoadingOutlined} from "@ant-design/icons";

function Favorites(props) {
    const {userFavoriteProducts, staticTexts, selectedLanguage} = props;
    const [isFetching, setFetching] = useState(false);
    const [loaded, setLoaded] = useState(false);

    // console.log("userFavoriteProducts",userFavoriteProducts)

    useEffect(() => {
        props.getPageTitle(staticTexts?.favorites_title)
    }, []);

    useEffect(() => {
        setLoaded(true)
        props.GetFavoriteProducts().then(() => {
            setLoaded(false)
        });
    }, [selectedLanguage]);

    const loadMoreItems = () => {
        if (!isFetching) {
            setFetching(true);
            props.GetFavoriteProducts({
                reset: false,
                offset: userFavoriteProducts?.list?.length
            }).finally(() => {
                setFetching(false);
            });
        }
    };
    return <section className='favorites-wrapper'>
        <InfiniteScroll
            hasMore={userFavoriteProducts?.hasMore}
            loadMore={loadMoreItems}
            className={'favorite-blocked-products'}
            pageStart={0}
            useWindow={true}
            initialLoad={false}>
            <>{!loaded ?
                (!!userFavoriteProducts?.list?.length ? userFavoriteProducts?.list?.map(item => {
                    return <ProductCard data={item} key={item.id}/>
                }) : <div className="favorite-empty-view">
                    <FavoritesEmpty/>
                    <p>{staticTexts?.favorites_empty_view}</p>
                </div>)
                : <LoadingOutlined style={{fontSize: 22, color: "#666", marginTop: "40px", marginLeft: "20px"}}/>}
            </>
        </InfiniteScroll>
    </section>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
    'selectedLanguage',
    'userFavoriteProducts',
    'requestLoading',
]);

const mapDispatchToProps = {
    GetFavoriteProducts
};

export default connect(mapStateToProps, mapDispatchToProps)(Favorites);
