// Import packages
import React, {useState, useEffect, Component} from "react";
import {connect} from 'react-redux';
import moment from "moment";
import InfiniteScroll from "react-infinite-scroller";

// Import styles
import './notifications.scss';
import {ArrowIcon, ClearIcon, NotificationEmpty, OrdersEmpty} from "../../../assets/images";

// Import components
import {Switch} from 'antd';

//import utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {GetNotifications, /*ToggleNotification*/} from "../../../redux/actions";
import {SetAllNotificationsAsOpened} from "../../../socket/emitters";
import {DeleteNotification} from "../../../socket/emitters";
import {history} from "../../../configs/history";
import {NOTIFICATION_TYPES} from "../../../constants/constTypes";
import {LoadingOutlined} from "@ant-design/icons";
import NotificationDetails from "./notificationDetails/NotificationDetails";

function Notifications(props) {
    const {notificationsList, notificationHasMore, loggedInUser, staticTexts, selectedLanguage} = props;
    const [isFetching, setFetching] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [detailsData, setDetailsData] = useState({});
    useEffect(() => {
        setLoaded(true)
        props.GetNotifications().then(() => {
            setLoaded(false)
        });
        SetAllNotificationsAsOpened();
    }, [selectedLanguage]);

    useEffect(() => {
        detailsData?.id ?
            props.getPageTitle(
                <div className="notification-details-header"><ArrowIcon onClick={() => toggleNotificationDetails({})}/>
                    {staticTexts?.notifications_details_title}
                </div>)
            : props.getPageTitle(staticTexts?.notifications_title)
    }, [detailsData.id]);


    function loadMoreItems() {
        if (!isFetching) {
            setFetching(true);
            notificationHasMore && props.GetNotifications({
                reset: false,
                offset: notificationsList?.length
            }).finally(() => {
                setFetching(false);
            });
        }
    }

    function deleteNotification(e, id) {
        e && e.preventDefault();
        e && e.stopPropagation();
        DeleteNotification(id)
    }

    function toggleNotificationDetails(data) {
        setDetailsData(data)
    }

    function openNotificationLink(num) {
        history.push(`/profile/orders/${num}`)
    }

    return <section className='notifications-wrapper'>
        {/*<div className="notifications-switch-wrapper">*/}
        {/*    {staticTexts?.notifications_turn_off_text}*/}
        {/*    <Switch checked={loggedInUser?.isNotificationsEnable}*/}
        {/*            onClick={() => {*/}
        {/*                // props.ToggleNotification({isNotificationsEnable: !loggedInUser?.isNotificationsEnable})*/}
        {/*            }}/>*/}
        {/*</div>*/}
        {!detailsData?.id ?
            <InfiniteScroll
                hasMore={notificationHasMore}
                loadMore={loadMoreItems}
                className={'notifications'}
                pageStart={0}
                useWindow={true}
                initialLoad={false}>
                <>{!loaded ?
                    (!!props.notificationsList?.length ? props.notificationsList?.map((item, index) => {
                        const status = item?.type === NOTIFICATION_TYPES?.CHANGED_ORDER_STATUS && props?.orderStatuses?.find(status => status?.id === item?.data?.statusId)
                        return <div>
                            <div
                                className={`notification-item-card ${item?.type === NOTIFICATION_TYPES?.CHANGED_ORDER_STATUS ? "link-notification" : ""}`}
                                key={item.id}
                                onClick={() => item?.type === NOTIFICATION_TYPES?.CHANGED_ORDER_STATUS ? openNotificationLink(item?.data?.orderNumber) : toggleNotificationDetails(item)}
                            >
                                <div className="left-part">
                                    <div className="notification-content">
                                        <div className="title">{item.title}</div>
                                        <div className="description-wrapper">
                                            <div className={`description`}>{item.description}</div>
                                            {item?.type === NOTIFICATION_TYPES?.CHANGED_ORDER_STATUS &&
                                            <div className="description">{staticTexts?.notifications_status}։ <span
                                                style={{color: status?.color}}>{status?.title}</span></div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="right-part">
                                    <div className="delete-content" onClick={(e) => deleteNotification(e, item.id)}>
                                        <ClearIcon/></div>
                                    <div
                                        className="notification-date">{moment(item.createdAt).format("DD.MM.YYYY")}</div>
                                </div>
                            </div>
                        </div>
                    }) : <div className="notifications-empty-view">
                        <NotificationEmpty/>
                        <p>{staticTexts?.notification_empty_view}</p>
                    </div>)
                    : <LoadingOutlined style={{fontSize: 22, color: "#666", marginTop: "40px", marginLeft: "20px"}}/>}
                </>
            </InfiniteScroll> : <NotificationDetails data={detailsData}/>}
    </section>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
    'selectedLanguage',
    'notificationsList',
    'notificationHasMore',
    'loggedInUser',
    'requestLoading',
    'orderStatuses',
]);

const mapDispatchToProps = {
    GetNotifications,
    /*ToggleNotification*/
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
