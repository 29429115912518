// Import packages
import React, {useState} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Rating from "react-rating";

// Import Styles
import './productCard.scss';
import {
    EmptyStar,
    FavoriteFillIcon, FavoriteIcon, FillStar, MinusIcon, PlusIcon,
} from "../../../../assets/images";

// Import components
import {PrimaryButton, ProductBadge} from "../../buttons/Buttons";
import RequestModal from "../../requestModal/RequestModal";
import InfoModal from "../../infoModal/InfoModal";

// Import utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {
    LikeProduct,
    UnLikeProduct,
    addProductToCart,
    changeProductQuantityInCart,
} from "../../../../redux/actions";
import {generateImageMediaUrl} from "../../../../utils/generateMediaUrl";
import {CURRENCIES_TYPES} from "../../../../constants/constTypes";
import {resizeImage} from "../../../../utils/resizeImage";


function ProductCard(props) {
    const {data, gridView = false, staticTexts, userCart, isLoggedIn, key} = props;
    const productInCart = userCart.find(item => item.product?.id === data.id);
    const [modalVisible, setModalVisible] = useState(false);
    const [isFetching, setFetching] = useState(false);
    const [goSignIn, setGoSignIn] = useState(false);
    const [productsIsEmpty, setProductsIsEmpty] = useState(false);
    const [requestModal, setRequestModal] = useState({
        visible: false,
        productId: ""
    });
    // console.log(data, 'datadatadata');
    function toggleFavorite(e) {
        e && e.preventDefault();
        e && e.stopPropagation();
        if (isLoggedIn) {
            if (!isFetching) {
                setFetching(true);
                const favoritePromise = data.isFavorite ? props.UnLikeProduct(data.id) : props.LikeProduct(data.id);
                favoritePromise.finally(() => {
                    setFetching(false);
                })
            }
        } else {
            setModalVisible(true);
            setGoSignIn(true);
            setProductsIsEmpty(false);
        }
    }

    const toggleModal = () => {
        setModalVisible(!modalVisible);
    };

    const toggleRequestModal = (id) => {
        setRequestModal({
            visible: !requestModal?.visible,
            productId: id
        });
    };

    function addToCart() {
        if (data?.isAvailable && !!data?.quantity) {
            props.addProductToCart(data)
        } else {
            if (isLoggedIn) {
                toggleRequestModal(data.id)
            } else {
                setModalVisible(true);
                setGoSignIn(true);
                setProductsIsEmpty(false);
            }

        }
    }

    function decrement() {
        props.changeProductQuantityInCart(data?.id, productInCart?.quantity - 1)
    }

    function increment() {
        if ( productInCart?.quantity < data?.quantity) {
            props.changeProductQuantityInCart(data?.id, productInCart?.quantity + 1)
        } else if (!data?.quantity) {
            props.changeProductQuantityInCart(data?.id, productInCart?.quantity + 1)
        }
        if (productInCart?.quantity === data?.quantity) {
            setProductsIsEmpty(true);
            setGoSignIn(false);
            setModalVisible(true);
        }
    }

    return <React.Fragment key={key}>{gridView ?
        <Link to={{
            pathname: `/product/${data?.productNumber}`,
            state: {
                product: data
            }
        }}
              className={`product-card product-grid-card`}>
            {!!data?.badges?.length && data?.badges.map(item => {
                return <ProductBadge title={item.title}
                                     id={item?.id}
                                     style={{backgroundColor: item?.color}}/>
            })}
            <div className="product-image-proportion">
                <img className="product-img" src={resizeImage(generateImageMediaUrl(data?.mediaMain?.path), 500, 500)}
                     alt=""/>
            </div>
            <div className="product-main-content">
                <div className="product-title">
                    {data?.title}
                </div>
            </div>
            <div className="stars-count">
                <Rating
                    emptySymbol={<EmptyStar title=''/>}
                    fullSymbol={<FillStar className={'fill-star-icon'} title=''/>}
                    initialRating={data?.averageRate}
                    readonly
                />
                <span className='product-rating'>{data?.averageRate?.toString().slice(0, 3) || 0}</span>
            </div>
            <div className="price-wrapper">
                <div className={`price ${data?.oldPrice ? 'new-price' : ''}`}>
                    {data?.price} {CURRENCIES_TYPES.find(item => item.id === "AMD")?.icon}
                </div>
                {data?.oldPrice && <div
                    className="old-price">{data?.oldPrice} {CURRENCIES_TYPES.find(item => item.id === "AMD")?.icon}</div>}
            </div>
            <div className="bottom-side">
                <div className={"basket-wrapper"}>
                    {productInCart && data?.isAvailable && !!data?.quantity ?
                        <div className="plus-minus-wrapper" onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}>
                            <button className='down' onClick={decrement}><MinusIcon/></button>
                            {productInCart?.quantity}
                            <button className='up' onClick={increment}><PlusIcon/></button>
                        </div> : <PrimaryButton className="basket-btn"
                            // disabled={!productData?.isAvailable}
                                                title={data?.isAvailable && !!data?.quantity ? staticTexts?.product_card_add_to_cart_text
                                                    : staticTexts?.product_card_to_order_text}
                                                cb={addToCart}/>
                    }
                </div>
                <PrimaryButton className="fav-btn bg-grey"
                               title={data?.isFavorite ? <FavoriteFillIcon/> : <FavoriteIcon/>}
                               cb={toggleFavorite}/>
            </div>
        </Link>
        : <Link to={{
            pathname: `/product/${data?.productNumber}`,
            state: {
                product: data
            }
        }}
                className={`product-card product-list-card`}>
            <div className="product-image-proportion">
                <div className="status-wrapper">
                    {!!data?.badges?.length && data?.badges.map(item => {
                        return <ProductBadge title={item.title}
                                             id={item?.id}
                                             style={{backgroundColor: item?.color}}/>
                    })}
                </div>
                <img className="product-img" src={resizeImage(generateImageMediaUrl(data?.mediaMain?.path), 500, 500)}
                     alt=""/>
            </div>
            <div className="right-side">
                <div className="product-title-wrapper">
                    <div className="product-title">{data?.title}</div>
                    <PrimaryButton className="fav-btn bg-grey"
                                   title={data?.isFavorite ? <FavoriteFillIcon/> : <FavoriteIcon/>}
                                   cb={toggleFavorite}/>
                </div>
                <div className="product-main-content">
                    <div className="stars-count">
                        <Rating
                            emptySymbol={<EmptyStar title=''/>}
                            fullSymbol={<FillStar className={'fill-star-icon'} title=''/>}
                            initialRating={data?.averageRate}
                            readonly
                        />
                        <span className='product-rating'>{data?.averageRate?.toString().slice(0, 3) || 0}</span>
                    </div>
                    <div className="bottom-side">
                        <div className="price-wrapper">
                            <div className={`price ${data?.oldPrice ? 'new-price' : ''}`}>{data?.price}
                                <span>{CURRENCIES_TYPES.find(item => item.id === "AMD")?.icon}</span></div>
                            {data?.oldPrice && <div
                                className="old-price">{data?.oldPrice}
                                <span>{CURRENCIES_TYPES.find(item => item.id === "AMD")?.icon}</span></div>}
                        </div>
                        <div className={"basket-wrapper"}>
                            {productInCart && data?.isAvailable ?
                                <div className="plus-minus-wrapper" onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}>
                                    <button className='down' onClick={decrement}><MinusIcon/></button>
                                    {productInCart?.quantity}
                                    <button className='up' onClick={increment}><PlusIcon/></button>
                                </div> : <PrimaryButton className="basket-btn"
                                    // disabled={!productData?.isAvailable}
                                                        title={data?.isAvailable ? staticTexts?.product_card_add_to_cart_text
                                                            : staticTexts?.product_card_to_order_text}
                                                        cb={addToCart}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Link>}
        <InfoModal
            visible={modalVisible}
            goSignIn={goSignIn}
            toggleModal={toggleModal}
            productsIsEmpty={productsIsEmpty}
        />
        <RequestModal visible={requestModal?.visible}
                      productId={requestModal?.productId}
                      toggleModal={toggleRequestModal}/>
    </React.Fragment>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'isLoggedIn',
    "postCardView",
    "staticTexts",
    "userCart"
]);

const mapDispatchToProps = {
    LikeProduct,
    UnLikeProduct,
    addProductToCart,
    changeProductQuantityInCart,
};


export default connect(mapStateToProps, mapDispatchToProps)(ProductCard)
