// Import packages
import React, {Component} from 'react';
import {connect} from "react-redux";
import Slider from "@ant-design/react-slick";
import ReactPlayer from 'react-player/youtube'
import {Tabs} from 'antd';

import ReactImageMagnify from 'react-image-magnify';
import Rating from "react-rating";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton} from "react-share";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {history} from "../../configs/history";

//Import Assets
import './productsDetails.scss'
import {
    CopyIcon,
    EmptyHeartDetails,
    EmptyStarDetails,
    FacebookShareIcon,
    FillHeartDetails,
    LinkedinIcon,
    LogoSM,
    MinusIcon,
    PlusIcon,
    ShareIcon,
    TwitterIcon,
    VideoIcon
} from "../../assets/images";

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {
    GetSingleProduct,
    LikeProduct,
    UnLikeProduct,
    ClearSingleProduct,
    GetProductReview,
    ClearProductReview,
    GetIdenticalProducts
} from "../../redux/actions";
import {addProductToCart, changeProductQuantityInCart} from "../../redux/actions";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {checkYoutubeUrl, getYoutubeId} from "../../utils/checkYouTubeUrl";


//import components
import ProductsSlider from "../../components/productsSlider/ProductsSlider";
import {PrimaryButton, ProductBadge, SliderButton} from "../../components/uiElements/buttons/Buttons";
import {CURRENCIES_TYPES} from "../../constants/constTypes";
import InfoModal from "../../components/uiElements/infoModal/InfoModal";
import MetaTags from "../../components/MetaTags";
// import {ProductLoading} from "../components/uiElements/ProductLoading";
import RequestModal from '../../components/uiElements/requestModal/RequestModal'
import {resizeImage} from "../../utils/resizeImage";
import {LoadingOutlined} from "@ant-design/icons";
import {domain} from "../../redux/api";

const {TabPane} = Tabs;


class ProductDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentColor: '#EEEFF1',
            currentAttribute: {},
            slideIndex: 0,
            isFetching: false,
            modalVisible: false,
            requestModalVisible: false,
            productId: '',
            goSignIn: false,
            productsIsEmpty: false,
            openShareDropdown: false,
            copied: false,
            disabledPrev: true,
            disabledNext: false,
            loading: false,
            productData: this.props?.location?.state?.product || null,
        };
        this.zoomImg = React.createRef();
        this.mainImg = React.createRef();

        this.toggleShareWindow = this.toggleShareWindow.bind(this);
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
        });
        const id = this.props?.match?.params?.id;
        this.setState({
            loading: true
        });
        this.props.GetSingleProduct(id).then(() => {
            this.props.GetIdenticalProducts(this.props.singleProduct?.id)
            let itemCount = this.getShowSlideCount();
            this.setState({
                productData: this.props.singleProduct,
                disabledNext: this.props.singleProduct?.medias?.length - 1 < itemCount,
                loading: false
            });
            this.state.productData.filters.forEach(item => {
                this.setState({
                    currentAttribute: {
                        ...this.state.currentAttribute,
                        [item.id]: item.values[0]?.id,
                    }
                });
            });
        }).catch(() => {
            history.push("/404")
        });
        // this.props.GetProductReview(id);

        window.addEventListener('click', this.toggleShareWindow);
        return () => {
            window.removeEventListener('click', this.toggleShareWindow)
        }
    }

    componentWillUnmount() {
        this.props.ClearSingleProduct();
        // this.props.ClearProductReview();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const id = this.props?.match?.params?.id;
        const prevId = prevProps?.match?.params?.id;
        if (id && prevId && id !== prevId) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            this.setState({
                ...this.state,
                productData: this.props?.location?.state?.product || null,
            });
            this.props.GetSingleProduct(id).then(() => {
                this.setState({
                    ...this.state,
                    productData: this.props.singleProduct,
                });
            });
            // this.props.GetProductReview(id);
        }

        if (this.props.selectedLanguage?.code !== prevProps.selectedLanguage?.code) {
            this.props.GetSingleProduct(id)
                .then(() => {
                    this.setState({
                        ...this.state,
                        productData: this.props.singleProduct,
                    });
                    this.state.productData.filters.forEach(item => {
                        this.setState({
                            currentAttribute: {
                                ...this.state.currentAttribute,
                                [item.id]: item.values[0]?.id,
                            }
                        });
                    });
                });
        }
    }

    toggleShareWindow() {
        this.setState({
            openShareDropdown: false
        });
    }

    toggleAttribute = (filterId, valueId) => {
        this.setState({
            currentAttribute: {
                ...this.state.currentAttribute,
                [filterId]: valueId
            }
        })
    };

    toggleFavorite = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.isLoggedIn) {
            if (!this.state.isFetching) {
                this.setState({
                    isFetching: true
                });
                const favoritePromise = this.props.singleProduct.isFavorite
                    ? this.props.UnLikeProduct(this.props.singleProduct.id)
                    : this.props.LikeProduct(this.props.singleProduct.id);
                favoritePromise.finally(() => {
                    this.setState({
                        productData: this.props.singleProduct,
                        isFetching: false
                    });
                })
            }
        } else {
            this.setState({
                modalVisible: true,
                goSignIn: true,
                productsIsEmpty: false,
            })
        }
    };

    addToCart = (e) => {
        e && e.preventDefault();
        e && e.stopPropagation();
        if (this.state.productData?.isAvailable && !!this.state.productData?.quantity) {
            this.props.addProductToCart(this.props.singleProduct)
        } else {
            this.toggleRequestModal(this.props.singleProduct.id)
        }
    };

    decrement = () => {
        const productInCart = this.props.userCart.find(item => item.product?.id === this.state.productData?.id);
        if (productInCart?.quantity > 1) {
            this.props.changeProductQuantityInCart(this.state.productData?.id, productInCart?.quantity - 1)
        } else {
            this.props.changeProductQuantityInCart(this.state.productData?.id, 0)
        }
    };

    increment = () => {
        const productInCart = this.props.userCart.find(item => item.product?.id === this.state.productData?.id);
        if (productInCart?.quantity < this.props?.singleProduct?.quantity) {
            this.props.changeProductQuantityInCart(this.state.productData?.id, productInCart?.quantity + 1)
        } else if (!this.props?.singleProduct?.quantity) {
            this.props.changeProductQuantityInCart(this.state.productData?.id, productInCart?.quantity + 1)
        }
        if (productInCart?.quantity === this.props?.singleProduct?.quantity) {
            this.setState({
                modalVisible: true,
                productsIsEmpty: true,
                goSignIn: false,
            })
        }
    };

    toggleModal = (isDescriptionModal) => {
        this.setState({
            modalVisible: !this.state.modalVisible,
        });
    };

    toggleRequestModal = (id) => {
        this.setState({
            requestModalVisible: !this.state.requestModalVisible,
            productId: id,
        });
    };
    toggleDescriptionModal = () => {
        this.setState({
            modalVisible: true,
            goSignIn: false,
            productsIsEmpty: false,
        });
    };

    goToCard = () => {
        if (this.props.isLoggedIn) {
            history.push('/profile/bonus-card')
        } else {
            this.setState({
                modalVisible: true,
                goSignIn: true,
                productsIsEmpty: false,
            });
        }
    };

    getShowSlideCount = () => {
        let width = window.innerWidth;
        if (width > 1160) return 5;
        else if (width > 1000) return 4;
        else if (width > 800) return 6;
        else if (width > 700) return 5;
        else if (width > 600) return 4;
        else if (width > 450) return 5;
        else return 4;
    };

    afterSlideChange = (next) => {
        let itemCount = this.getShowSlideCount();
        this.setState({
            disabledPrev: next === 0,
            disabledNext: next + itemCount === this.state.productData?.medias?.length,
        });
    };

    render() {
        const {
            disabledPrev, disabledNext,
            slideIndex, modalVisible, requestModalVisible, goSignIn, productsIsEmpty, productData, openShareDropdown,
            currentAttribute, copied, productId, loading
        } = this.state;
        const {sections, productReviews, userCart, requestLoading, staticTexts, identicalProducts} = this.props;
        const productInCart = userCart.find(item => item.product?.id === productData?.id);
        const settingsImages = {
            dots: false,
            arrows: false,
            vertical: true,
            ref: slider => (this.slider = slider),
            verticalSwiping: true,
            infinite: false,
            slidesToShow: 5,
            slidesToScroll: 1,
            beforeChange: (current, next) => this.afterSlideChange(next),
            responsive: [
                {
                    breakpoint: 1160,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 1000,
                    settings: {
                        slidesToShow: 6,
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 5,
                    }
                },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 5,
                        vertical: false,
                        verticalSwiping: false
                    }
                },
                {
                    breakpoint: 450,
                    settings: {
                        slidesToShow: 4,
                        vertical: false,
                        verticalSwiping: false
                    }
                },
            ]
        };

        return productData ? <div className={`product-details-wrapper`}>
            <MetaTags title={productData?.title}
                      image={generateImageMediaUrl(!!productData?.medias?.length && generateImageMediaUrl(productData?.medias[0]?.path))}/>
            {productData ? <div className="product-details-content">
                    <div className="product-slider-wrapper" style={{
                        // height: this.mainImg.current?.clientHeight
                    }}>
                        <div className="pagination-img-wrapper" style={{
                            // height: this.mainImg.current?.clientHeight
                        }}
                             onScroll={(e) => {
                                 e.stopPropagation()
                             }}>
                            {productData?.medias?.length > this.getShowSlideCount() &&
                                <SliderButton className={'vertical'}
                                              disabledPrev={disabledPrev}
                                              disabledNext={disabledNext}
                                              cbPrev={() => this.slider.slickPrev()}
                                              cbNext={() => this.slider.slickNext()}/>}
                            {!!productData?.medias?.length && <Slider {...settingsImages}>
                                {productData?.medias?.map((item, index) => {
                                    let imageUrl = generateImageMediaUrl(item?.path)
                                    let withVideo = false
                                    if (item.url && checkYoutubeUrl(item.url)) {
                                        const videoId = getYoutubeId(item.url)
                                        imageUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;
                                        withVideo = true
                                    }
                                    return <div key={item.id}
                                                onClick={() => this.setState({slideIndex: index})}
                                                className={`pagination-item ${(slideIndex === index) ? 'active' : ''}`}>
                                        <img src={imageUrl} className={'pagination-img'}
                                             alt={item?.altAttribute ? item?.altAttribute : ''}/>
                                        {withVideo && <div className={'video-overlay'}>
                                            <VideoIcon/>
                                        </div>}
                                    </div>
                                })}
                            </Slider>
                            }
                        </div>

                        <div className={`main-img-wrapper ${!productData?.medias?.length ? "product-default-img" : ""}`}>
                            <div className="image-proportion">
                                {!!productData?.badges?.length && productData?.badges.map(item => {
                                    return <ProductBadge title={item?.title}
                                                         id={item?.id}
                                                         style={{backgroundColor: item?.color}}/>
                                })}
                                {!loading ? <>
                                    <div className="zoom-content" ref={this.zoomImg}>
                                        {productData?.medias?.length ? <ReactImageMagnify {...{
                                                className: 'product-current-img',
                                                enlargedImageContainerClassName: 'product-zoom-img',
                                                smallImage: {
                                                    alt: productData?.medias?.[slideIndex]?.altAttribute ? productData?.medias?.[slideIndex]?.altAttribute : '',
                                                    isFluidWidth: false,
                                                    src: resizeImage(generateImageMediaUrl(productData?.medias?.[slideIndex]?.path)),
                                                    width: this.zoomImg?.current?.clientWidth,
                                                    height: this.zoomImg?.current?.clientWidth
                                                },
                                                largeImage: {
                                                    src: resizeImage(generateImageMediaUrl(productData?.medias?.[slideIndex]?.path), 1600, 1600),
                                                    width: 1400,
                                                    height: 1400
                                                },
                                            }} /> :
                                            <img src={generateImageMediaUrl(productData?.medias?.[slideIndex]?.path)}
                                                 alt={productData?.medias?.[slideIndex]?.altAttribute ? productData?.medias?.[slideIndex]?.altAttribute : ''}
                                                 ref={this.mainImg}
                                                 className="product-current-img"/>}
                                    </div>
                                    {productData?.medias?.[slideIndex]?.url && checkYoutubeUrl(productData?.medias?.[slideIndex].url) ?
                                        <div className={'video-wrapper'}>
                                            <ReactPlayer
                                                url={productData?.medias[slideIndex].url}
                                                width={'100%'}
                                                height={'100%'}
                                                controls={true}
                                                youtubeConfig={{
                                                    playerVars: {
                                                        showinfo: 0,
                                                    }
                                                }}
                                            />
                                        </div> :
                                        <img
                                            src={resizeImage(generateImageMediaUrl(productData?.medias?.[slideIndex]?.path), 500, 500)}
                                            alt={productData?.medias?.[slideIndex]?.altAttribute ? productData?.medias?.[slideIndex]?.altAttribute : ''}
                                            ref={this.mainImg}
                                            className="mobile-slider-img"/>
                                    }</> : <LoadingOutlined style={{
                                    fontSize: 22,
                                    color: "#666",
                                    position: "absolute",
                                    top: "50%",
                                    transform: "translateX(-50%), translateY(-50%)",
                                    left: "50%"
                                }}
                                />}
                            </div>
                        </div>
                    </div>

                    <div className="info-wrapper">
                        <div className="top-side">
                            <div className="code-wrapper">
                                {staticTexts?.product_details_code}:<span> {productData?.productNumber}</span>
                            </div>
                            <div className="actions">
                                <div className="stars-count">
                                    <EmptyStarDetails/>
                                    <span className='product-rating'>{productReviews?.count || 0}</span>
                                </div>
                                <div className="share" onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.setState({openShareDropdown: true})
                                }}>
                                    <ShareIcon/>
                                    {staticTexts?.product_details_share_product}
                                    {openShareDropdown && <div className="share-dropdown">
                                        <CopyToClipboard
                                            text={`${domain}/product/${productData.productNumber}`}
                                            className={"copy-wrapper"}
                                            onCopy={() => this.setState({copied: true})}
                                            onMouseLeave={() => this.setState({copied: false})}>
                                            <button onClick={e => {
                                                e.stopPropagation()
                                            }}>
                                                <CopyIcon
                                                    title={''}/> {staticTexts?.product_details_share_dropdown_copy}
                                                <div
                                                    className="copy-text">{copied ? staticTexts?.product_details_copy_link_copied : staticTexts?.product_details_copy_link_copy}</div>
                                            </button>
                                        </CopyToClipboard>
                                        <FacebookShareButton
                                            url={`${domain}/product/${productData.productNumber}`}
                                            quote={`${productData.title}`}
                                        >
                                            <FacebookShareIcon
                                                title={''}/>{"Facebook"}
                                        </FacebookShareButton>
                                        {/*<TwitterShareButton*/}
                                        {/*    url={`${domain}/product/${productData.productNumber}`}*/}
                                        {/*    title={`${productData.title}`}>*/}
                                        {/*    <TwitterIcon*/}
                                        {/*        title={''}/>{"Twitter"}*/}
                                        {/*</TwitterShareButton>*/}
                                        {/*<LinkedinShareButton*/}
                                        {/*    url={`${domain}/product/${productData.productNumber}`}*/}
                                        {/*    title={`${productData.title}`}>*/}
                                        {/*    <LinkedinIcon*/}
                                        {/*        title={''}/>{"Linkedin"}*/}
                                        {/*</LinkedinShareButton>*/}
                                    </div>}
                                </div>
                                <div className="liked" onClick={this.toggleFavorite}>
                                    {productData?.isFavorite ?
                                        <FillHeartDetails/> :
                                        <EmptyHeartDetails/>}
                                    {staticTexts?.product_details_like_product}
                                </div>
                            </div>
                        </div>
                        <div className="info-main-content">
                            <div className="product-available">
                                {productData?.isAvailable ?
                                    <span className={'available'}>{staticTexts?.product_details_is_available}</span>
                                    : <span
                                        className={'not-available'}>{staticTexts?.product_details_is_not_available}</span>}
                            </div>
                            <div className="product-title">{productData?.title}</div>
                            <div className="grey-wrapper">
                                <div className="price-wrapper">
                                    <div className="left-part">
                                        <div className="prices">
                                            <div
                                                className={`price ${productData?.oldPrice ? 'new-price' : ''}`}>{productData?.price} {CURRENCIES_TYPES.find(item => item.id === "AMD")?.icon}</div>
                                            {productData?.oldPrice && <div
                                                className="old-price">{productData?.oldPrice} {CURRENCIES_TYPES.find(item => item.id === "AMD")?.icon}</div>}
                                        </div>
                                        <div className="delivery-info">
                                            <LogoSM/><span>{staticTexts?.product_details_delivery_info}</span>
                                        </div>
                                    </div>
                                    <div className={"basket-wrapper"}>
                                        {productInCart && productData?.isAvailable ?
                                            <div className="plus-minus-wrapper" onClick={e => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}>
                                                <button className='down' onClick={this.decrement}><MinusIcon/></button>
                                                {productInCart?.quantity}
                                                <button className='up' onClick={this.increment}><PlusIcon/></button>
                                            </div> : <PrimaryButton className="basket-btn"
                                                // disabled={!productData?.isAvailable}
                                                                    title={productData?.isAvailable && !!productData?.quantity
                                                                        ? staticTexts?.product_card_add_to_cart_text
                                                                        : staticTexts?.product_card_to_order_text}
                                                                    cb={this.addToCart}/>
                                        }
                                    </div>
                                </div>
                                <div className="order-bottom-info">
                                    <div
                                        className="order-bottom-info-title">{staticTexts?.product_details_order_bottom_info_title}</div>
                                    <div className="order-bottom-info-texts">
                                        <div className="order-bottom-info-text-item">
                                            <span>{staticTexts?.product_details_bottom_left_text_orange_part} </span>{staticTexts?.product_details_bottom_left_text_last_part}
                                        </div>
                                        <div className="order-bottom-info-text-item">
                                            <span>{staticTexts?.product_details_bottom_right_text_orange_part} </span>{staticTexts?.product_details_bottom_right_text_last_part}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                // requestLoading && <ProductLoading/>
                ""
            }

            <Tabs>
                {productData?.description !== "<p><br></p>" &&
                    <TabPane
                        tab={staticTexts?.product_details_tabs_description}
                        key="1">
                        <div className={'sun-editor-editable description-text'}
                             dangerouslySetInnerHTML={{__html: productData?.description}}/>
                    </TabPane>}
                {productData?.details !== "<p><br></p>" &&
                    <TabPane tab={staticTexts?.product_details_tabs_details} key="2">
                        <div className={'sun-editor-editable description-text'}
                             dangerouslySetInnerHTML={{__html: productData?.details}}/>
                    </TabPane>}
                {!!productData?.attributes?.length &&
                    <TabPane tab={staticTexts?.product_details_tabs_character} key="3">
                        <div className="attributes">
                            {productData?.attributes?.map(attribute => {
                                return <div className="attribute-wrapper" key={attribute?.id}>
                                    <div className="desc">{attribute?.title}</div>
                                    <div className="attribute-content">
                                        {
                                            !!attribute?.values.length && attribute?.values?.map((item, index) => {
                                                // console.log(currentAttribute[filter.id], 'ITEM');
                                                return <div
                                                    className={`attribute-item ${item.id === currentAttribute[attribute.id] ? 'current-attribute' : ''}`}
                                                    key={item.id}
                                                    onClick={() => this.toggleAttribute(attribute.id, item.id)}
                                                ><span>{item.title}</span>
                                                    {index + 1 < attribute?.values?.length && <span>,&nbsp;</span>}</div>
                                            })
                                        }
                                    </div>
                                </div>
                            })}
                        </div>
                    </TabPane>}
            </Tabs>
            <ProductsSlider products={identicalProducts}
                            key={sections?.[0]?.id}
                            id={sections?.[0]?.id}
                            title={staticTexts?.product_details_similar_products}
                            link={'/section-products'}/>
            <InfoModal
                visible={modalVisible}
                goSignIn={goSignIn}
                productsIsEmpty={productsIsEmpty}
                descriptionTitle={productData?.title}
                descriptionText={productData?.description}
                toggleModal={this.toggleModal}
            />
            <RequestModal visible={requestModalVisible}
                          productId={productId}
                          toggleModal={this.toggleRequestModal}/>
        </div> : <LoadingOutlined style={{
            fontSize: 22,
            color: "#666",
        }}
        />
    }
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'singleProduct',
        'sections',
        'isLoggedIn',
        'productReviews',
        'userCart',
        'requestLoading',
        'selectedLanguage',
        'currentCurrency',
        'identicalProducts',
    ])
};

const mapDispatchToProps = {
    GetSingleProduct,
    ClearSingleProduct,
    LikeProduct,
    UnLikeProduct,
    addProductToCart,
    changeProductQuantityInCart,
    GetProductReview,
    ClearProductReview,
    GetIdenticalProducts
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
