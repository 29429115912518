import {combineReducers} from "redux";

// import reducers
import auth from "./auth";
import general from './general';
import cart from './cart';
import utils from "./utils";
import product from "./product";
import order from "./order";
import bonusCard from "./bonusCards";
import notifications from "./notifications";
import blog from "./blog";

export default combineReducers({
    auth,
    general,
    cart,
    utils,
    product,
    order,
    bonusCard,
    notifications,
    blog,
});
