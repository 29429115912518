// import packages
import React, {Component} from "react";
import {connect} from 'react-redux';
import {history} from "../../configs/history";

//Import assets
import "./verifyEmail.scss"
import {Logo, VerifyMail} from "../../assets/images";

//Import Components
import AuthWrapper from "../../components/auth/auth-wrapper/AuthWrapper";
import {InputGroup} from "../../components/uiElements/inputGroup/inputGroup";
import {PrimaryButton} from "../../components/uiElements/buttons/Buttons";

// Import utils
import {
    toggleNotificationRow,
    ResendActivation,
    ActivateUser,
    LoginAfterActivation,
    CheckForgotPasswordCode, CheckChangeEmailCode, UpdateCurrentUser, ChangeUserEmail, ForgotUserPassword
} from "../../redux/actions";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {NOTIFICATION_ROW_MODES, USER_ERROR_TYPES} from "../../constants/constTypes";
import {Link} from "react-router-dom";

const NOTIFICATION_RESEND_TIMER_SECOND = 59;

class VerifyEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            canReSendEmail: false,
            username: props?.location?.state?.username,
            redirectedFromActivation: !!props?.location?.state?.redirectedFromActivation,
            redirectedFromSignUp: !!props?.location?.state?.redirectedFromSignUp,
            redirectedFromForgotPassword: !!props?.location?.state?.redirectedFromForgotPassword,
            redirectedFromChangeEmail: !!props?.location?.state?.redirectedFromChangeEmail,
            timer: NOTIFICATION_RESEND_TIMER_SECOND,
            codes: {},
        };
        this.activeInput = React.createRef();
        this.changeTimer = this.changeTimer.bind(this);
        this.getInputValues = this.getInputValues.bind(this);
        this.SendVerificationCode = this.SendVerificationCode.bind(this);
        this.reSendEmail = this.reSendEmail.bind(this);
        this.activateUser = this.activateUser.bind(this);
        this.checkCode = this.checkCode.bind(this);
    }

    componentDidMount() {
        const {
            redirectedFromActivation,
            redirectedFromSignUp,
            redirectedFromForgotPassword,
            redirectedFromChangeEmail
        } = this.state;
        if (redirectedFromSignUp || redirectedFromForgotPassword || redirectedFromChangeEmail) {
            this.timer = setInterval(this.changeTimer, 1000);
            // history.replace();
        } else if (redirectedFromActivation || redirectedFromForgotPassword || redirectedFromChangeEmail) {
            // history.replace();
        } else {
            // history.push('/')
            return;
        }

        const codes = document.querySelectorAll('.code');

        codes[0]?.focus();

        codes.forEach((code, idx) => {
            // console.log( 'e.key', idx);
            code.addEventListener('keydown', (e) => {

                this.setState({
                    ...this.state,
                    codes: {
                        ...this.state.codes,
                        [`code_${idx}`]: "",
                    }
                })
                if (e.key >= 0 && e.key <= 9) {
                    setTimeout(() => codes[idx + 1]?.focus(), 10);
                } else if (e.key === 'Backspace') {
                    setTimeout(() => codes[idx - 1]?.focus(), 10);
                }
            });
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {code} = this.props.location;
        if (prevProps.location.code !== this.props.location.code) {
            if (code) {
                for (let i = 0; i < code.length; i++) {
                    this.setState({
                        ...this.state,
                        codes: {
                            ...this.state.codes,
                            [`code_${i}`]: code.slice(i, i + 1),
                        }
                    })
                }
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    changeTimer() {
        const {timer} = this.state;
        if (timer === 0) {
            clearInterval(this.timer);
            this.setState({
                canReSendEmail: true,
                timer: NOTIFICATION_RESEND_TIMER_SECOND,
            })
        } else {
            this.setState({
                timer: timer - 1
            })
        }
    }

    getInputValues = ({name, value}) => {
        if (value.length > 1) {
            let codes = {};
            Array.from(Array(6)).forEach((item, index) => {
                codes[`code_${index}`] = value[index]
            });
            this.setState({
                codes: codes
            }, () => this.activeInput?.current?.focus())
        }
        else {
            this.setState({
                codes: {
                    ...this.state.codes,
                    [name]: value.trimStart(),
                }
            })
        }
    };

    SendVerificationCode() {
        const {username, codes, redirectedFromForgotPassword, redirectedFromChangeEmail} = this.state;
        const reqData = {}
        const numsArr = []
        const codeKeys = Object.keys(codes)
        if (codeKeys.length !== 6) {
            return
        }
        codeKeys.forEach((key) => {
            const [_, index] = key.split('_')
            numsArr[Number(index)] = codes[key]
        });
        username && (reqData.username = username);
        reqData.code = numsArr.join("");
        if (redirectedFromForgotPassword || redirectedFromChangeEmail) {
            this.checkCode(reqData)
        } else {
            this.activateUser(reqData)
        }
    }

    checkCode(reqData) {
        const {redirectedFromForgotPassword, redirectedFromChangeEmail} = this.state;
        const {staticTexts} = this.props;
        if (redirectedFromForgotPassword) {
            this.props.CheckForgotPasswordCode(reqData).then(() => {
                history.push({
                    pathname: '/user/reset-password',
                    state: {
                        redirectedFromForgotPassword: true,
                        data: reqData
                    }
                });
            }).catch((err) => {
                this.catchCheckCode(err, reqData)
            })
        }
        if (redirectedFromChangeEmail) {
            this.props.CheckChangeEmailCode(reqData).then(() => {
                history.push("/profile/settings")
                this.props.toggleNotificationRow({
                    visible: true,
                    text: this.props.staticTexts?.profile_update_success,
                    disappear: true,
                    mode: NOTIFICATION_ROW_MODES.SUCCESS
                });
            }).catch(() => {
                this.props.toggleNotificationRow({
                    visible: true,
                    text: this.props.staticTexts?.notification_default_error,
                    disappear: true,
                    mode: NOTIFICATION_ROW_MODES.ERROR
                });
            }).catch((err) => {
                this.catchCheckCode(err, reqData)
            })
        }
    }

    catchCheckCode(err, reqData) {
        if (err && err.response && err.response.data.slug === USER_ERROR_TYPES.CODE_NOT_FOUND) {
            //activation link date expired
            history.push({
                pathname: '/user/forgot-password',
                state: {
                    username: reqData.username,
                }
            });
            this.props.toggleNotificationRow({
                visible: true,
                text: this.props.staticTexts?.activation_error_link_date_expired,
                disappear: 5000,
                mode: NOTIFICATION_ROW_MODES.INFO
            });

        } else if (err && err.response && err.response?.data.slug === USER_ERROR_TYPES.CODE_INVALID) {
            //account already activated
            this.props.toggleNotificationRow({
                visible: true,
                text: this.props.staticTexts?.activation_error_account_invalid_code,
                disappear: 5000,
                mode: NOTIFICATION_ROW_MODES.INFO
            });
        } else {
            //unknown error
            history.push('/user/forgot-password')
        }
    }

    activateUser(reqData) {
        this.props.ActivateUser(reqData).then((res) => {
            this.props.LoginAfterActivation(res.data)
        }).catch((err) => {

            if (err.response?.data.slug === USER_ERROR_TYPES.CODE_INVALID) {
                //activation link date expired
                history.push({
                    pathname: '/user/confirm-email',
                    state: {
                        redirectedFromActivation: false,
                        username: reqData.username,
                    }
                });
                this.props.toggleNotificationRow({
                    visible: true,
                    text: this.props.staticTexts?.activation_error_account_invalid_code,
                    disappear: 5000,
                    mode: NOTIFICATION_ROW_MODES.INFO
                });

            }
            if (err?.response?.data?.slug === USER_ERROR_TYPES.CODE_NOT_FOUND) {
                //activation link date expired
                history.push({
                    pathname: '/user/confirm-email',
                    state: {
                        redirectedFromActivation: false,
                        username: reqData.username,
                    }
                });
                this.props.toggleNotificationRow({
                    visible: true,
                    text: this.props.staticTexts?.activation_error_link_date_expired,
                    disappear: 5000,
                    mode: NOTIFICATION_ROW_MODES.INFO
                });

            } else if (err && err.response && err.response?.data.slug === USER_ERROR_TYPES?.USER_ALREADY_ACTIVATED) {
                //account already activated
                history.push({
                    pathname: '/sign-in',
                    state: {
                        username: reqData.username,
                    }
                });
                this.props.toggleNotificationRow({
                    visible: true,
                    text: this.props.staticTexts?.activation_error_account_already_activated,
                    disappear: 5000,
                    mode: NOTIFICATION_ROW_MODES.INFO
                });
            }
            // else {
            //     //unknown error
            //     // history.push('/sign-up')
            // }
        })
    }

    reSendEmail() {
        const {staticTexts} = this.props;
        const {
            username,
            redirectedFromActivation,
            redirectedFromChangeEmail,
            redirectedFromForgotPassword
        } = this.state;
        const Promise = redirectedFromActivation ? ResendActivation(username) :
            (redirectedFromChangeEmail ? this.props.ChangeUserEmail(username) :
                (redirectedFromForgotPassword ? this.props.ForgotUserPassword(username) : ""))
        Promise && Promise?.then(() => {
            this.timer = setInterval(this.changeTimer, 1000);
            let successMessage = staticTexts?.resend_email_success;
            successMessage = successMessage?.replace('%d', username);
            this.props.toggleNotificationRow({
                visible: true,
                text: successMessage,
                disappear: 5000,
                mode: NOTIFICATION_ROW_MODES.SUCCESS
            });

            const emptyCodes = {}
            const codes = document.querySelectorAll('.code');

            codes[0]?.focus();

            codes.forEach((code, idx) => {
                emptyCodes[`code_${idx}`] = ""
            });

            this.setState({
                canReSendEmail: false,
                codes: emptyCodes
            });
            if (redirectedFromActivation) {
                this.setState({
                    redirectedFromActivation: false,
                    redirectedFromSignUp: true
                });
            }
        }).catch((err) => {
            if (err && err.response && err.response.status === 409) {
                //account already activated
                history.push({
                    pathname: '/sign-in',
                    state: {
                        username: username,
                    }
                });
                this.props.toggleNotificationRow({
                    visible: true,
                    text: staticTexts?.resend_email_error_account_already_activated,
                    disappear: 5000,
                    mode: NOTIFICATION_ROW_MODES.INFO
                });
            } else {
                this.props.toggleNotificationRow({
                    visible: true,
                    text: staticTexts?.notification_default_error,
                    disappear: 5000,
                    mode: NOTIFICATION_ROW_MODES.ERROR
                });
            }

        });
    }

    render() {
        const {
            canReSendEmail, timer, username, redirectedFromActivation, redirectedFromSignUp,
            redirectedFromForgotPassword, redirectedFromChangeEmail, codes
        } = this.state;
        const {staticTexts, requestLoading} = this.props;
        let isActiveIndex = -1;
        Array.from(Array(6)).forEach((item, index) => {
            if (!codes[`code_${index}`] && codes[`code_${index}`] !== '0' && !~isActiveIndex) {
                isActiveIndex = index;
                // console.log(index, 'index');
                // console.log(!~isActiveIndex, '!~isActiveIndex', isActiveIndex);
            }
        });
        if (isActiveIndex === -1) {
            isActiveIndex = 5;
        }
        return <AuthWrapper>
            <div className="form-content verify-email-wrapper">
                <Link to={'/'} className='form-logo'>
                    <Logo/>
                </Link>
                {
                    redirectedFromActivation &&
                    <div className="inner-part">
                        <div className="form-title">{staticTexts?.activation_link_date_expired}</div>
                        <div className="text">{staticTexts?.activation_link_resend_email_text}
                        </div>
                        <div className="email">{username}</div>
                        <div className="text">
                            <span className={`resend-btn active`} onClick={() => {
                                this.reSendEmail();
                                this.setState({
                                    redirectedFromActivation: true
                                })
                            }}>
                                {staticTexts?.activation_link_resend_email_link}
                            </span>
                        </div>
                    </div>}
                {(redirectedFromSignUp || redirectedFromForgotPassword || redirectedFromChangeEmail) && <>
                    <div className="inner-part">
                        <div className="form-title">{staticTexts?.verify_email_title}</div>
                        <div className="form-sub-title">
                            {staticTexts?.verify_email_description}
                            <span className="email"> &nbsp; {username} &nbsp;</span>
                        </div>

                        <div className="code-container">
                            <div className={`timer-wrapper ${!canReSendEmail ? 'show' : 'hide'}`}>
                                <div className="timer-text">Enter code from SMS</div>
                                <div className="timer-seconds">{timer > 9 ? '00:' : '00:0'}{timer}</div>
                            </div>
                            <div className="code-items">
                                {Array.from(Array(6)).map((item, index) => {
                                    return <InputGroup
                                        inputType={'input'}
                                        type={'number'}
                                        name={`code_${index}`}
                                        value={codes[`code_${index}`]}
                                        minValue={'0'}
                                        maxValue={'9'}
                                        onChange={this.getInputValues}
                                        className={'code'}
                                        required={true}
                                        forwardRef={isActiveIndex === index ? this.activeInput : null}
                                        onKeyDown={(e) => {
                                            Object.keys(codes)?.length === 6 && e.key === 'Enter' && this.SendVerificationCode()
                                        }}
                                    />
                                })}
                            </div>
                            <PrimaryButton
                                title={staticTexts?.verify_email_send_code_btn}
                                className="send-btn without-hover"
                                disabled={!codes[`code_5`] ||
                                !codes[`code_4`] ||
                                !codes[`code_3`] ||
                                !codes[`code_2`] ||
                                !codes[`code_1`] ||
                                !codes[`code_0`]}
                                cb={this.SendVerificationCode}
                                loading={requestLoading}
                            />
                        </div>
                    </div>
                    {<div className="under-form-text">
                        {staticTexts?.verify_email_description_not_seen}
                        <span className={`resend-btn ${canReSendEmail ? 'active' : ''}`}
                              onClick={() => {
                                  canReSendEmail && this.reSendEmail()
                              }}>&nbsp;{staticTexts?.verify_email_resend_email_text}</span>
                    </div>}
                </>
                }
            </div>
        </AuthWrapper>
    }
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    toggleNotificationRow,
    ActivateUser,
    LoginAfterActivation,
    ResendActivation,
    CheckForgotPasswordCode,
    CheckChangeEmailCode,
    UpdateCurrentUser,
    ChangeUserEmail,
    ForgotUserPassword
};
export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
