// import packages
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import debounce from 'lodash.debounce';
import { Checkbox, Radio } from "antd";

//import styles
import "./cartOrder.scss";

//Import Components;
import GoogleMapReact from 'google-map-react';

// Import utils
import { getPropsFromState } from "../../redux/mapStateToProps";
import {
    changeProductQuantityInCart,
    clearUserCart,
    removeProductFromCart,
    AddOrder,
    toggleNotificationRow,
    // GetResidences,
    // ClearResidences,
    GetBonusCards
} from "../../redux/actions";
import { PrimaryButton } from "../../components/uiElements/buttons/Buttons";
import { InputGroup } from "../../components/uiElements/inputGroup/inputGroup";
import isEmail from "validator/es/lib/isEmail";
import { PAYMENTS_TYPES } from "../../constants/constTypes";
import { history } from "../../configs/history";
import InfoModal from "../../components/uiElements/infoModal/InfoModal";
import { GOOGLE_MAP_KEY } from "../../configs/variables";
import { MAP_DEFAULT_CENTER, MAP_DEFAULT_ZOOM } from "../../constants/constants";
import { CurrentLocationIcon } from "../../assets/images";


function CartOrder (props) {
    const debouncedAutoCompleteAddress = debounce(autoCompleteAddress, 1000);
    const { loggedInUser, userCart, currentCurrency } = props;
    const { cart } = props.location?.state || {};
    const [order, setOrder] = useState({
        name: `${loggedInUser?.firstName ?? ''} ${loggedInUser?.lastName ?? ''}` || '',
        email: loggedInUser?.username || '',
        phoneNumber: loggedInUser?.phone || '',
        address: '',
        notes: '',
        promoCode: cart?.promoCode || '',
        checkCodeMessage: cart?.checkCodeMessage || '',
        bonusCardError: cart?.bonusCardError || false,
        promoCodeError: cart?.promoCodeError || false,
        discountRate: cart?.discountRate || "",
    });
    const [paymentType, setPaymentType] = useState(PAYMENTS_TYPES.CASH);
    const [errors, setErrors] = useState({});
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [agreement, setAgreement] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [address, setAddress] = useState({
        mainAddress: props?.address?.mainAddress || '',
        lat: '',
        lng: '',
        secondaryAddress: props?.address?.secondaryAddress || ''
    });
    const [dragging, setDragging] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [centerChangedByAddress, setCenterChangedByAddress] = useState(false);
    const [googleMap, setGoogleMap] = useState(null);
    const [mapCenter, setCenter] = useState(MAP_DEFAULT_CENTER);

    const refToServices = useRef({});
    let mapHTML = document.getElementsByClassName('gm-style');
    let markerImg = document.getElementsByClassName('marker-fixed');
    if (markerImg[0]) {
        dragging ? markerImg[0].setAttribute('class', 'marker-fixed dragging')
            : markerImg[0].setAttribute('class', 'marker-fixed')
    }
    useEffect(() => {
        // props.GetResidences("5ef386e57a9e4b23b16dd200");
        if (!props.isLoggedIn) {
            history.push("/cart")
        }
    }, []);

    useEffect(() => {
        mapHTML[0]?.insertAdjacentHTML('afterend', '<div class="marker-fixed"><div id="marker-icon"/></div></div>');
        // (async () => {
        //     const permissionStatus = await navigator.permissions.revoke({
        //         name: 'geolocation'
        //     });
        //     console.log(permissionStatus);
        // })();
        // setAddress({
        //     ...address,
        //     mainAddress: props?.address?.mainAddress
        // })
    }, [mapHTML[0]], dragging);

    useEffect(() => {
        setOrder({
            ...order,
            address: address ? address : props?.address,
        });

        return function cleanup () {
            // props.ClearResidences()
        };
    }, [props?.address, currentCurrency, address]);

    const getInputValues = (e) => {
        const { name, value } = e.target;

        if (name === 'phoneNumber' && !/^[0-9]*$/.test(value)) {
            return;
        }

        setOrder({
            ...order,
            [name]: value
        })
    };

    const validate = () => {
        const err = {};
        let result = true;
        const phoneRegExp = /^[+\d]\d*$/;
        const promoCodeRegExp = /^([A-Z0-9]){12}$/;
        const { name, email, phoneNumber, address, promoCode, checkCode } = order;

        if (!name?.trim()) {
            result = false;
            err.name = true;
        }
        if (!address?.mainAddress) {
            result = false;
            err.mainAddress = true;
        }
        if (!email || !isEmail(email)) {
            result = false;
            err.email = true;
        }
        if (!agreement) {
            result = false;
            err.agreement = true;
        }
        if (!phoneNumber || !phoneRegExp.test(phoneNumber)) {
            result = false;
            err.phoneNumber = true;
        }
        // if (!promoCode || !checkCode || !promoCodeRegExp.test(promoCode)) {
        //     result = false;
        //     err.promoCode = true;
        // }

        setErrors(err);
        return result;
    };

    const sendOrder = () => {
        if (validate()) {
            const { name, email, phoneNumber, address, notes } = order;
            const reqData = { name, email, address };
            const { sum } = props.location?.state;
            reqData.phoneNumber = '+374' + phoneNumber;
            reqData.paymentMethod = paymentType;
            order.promoCode && (reqData.promoCode = order.promoCode);
            reqData.products = userCart?.map(cartItem => ({
                product: cartItem?.product?.id,
                quantity: cartItem?.quantity
            }));
            notes && (reqData.notes = notes);
            reqData.sum = sum;
            reqData.address = address?.mainAddress + ", " + address?.secondaryAddress;

            // reqData.region = '5ef386e57a9e4b23b16dd200';
            // reqData.residence = '5ef386e57a9e4b23b16dd200';

            setDisabledBtn(true);
            props.AddOrder(reqData).then((res) => {
                if (paymentType === PAYMENTS_TYPES.CARD) {
                    const paymentWindow = window.open(res.data.formUrl, '_self',)
                } else {
                    setModalVisible(true);
                    setSuccessModal(true);
                    setErrorModal(false);
                    props.clearUserCart();
                }
                // history.push('/profile/orders')
            }).catch((err) => {
                setModalVisible(true);
                setErrorModal(true);
                setSuccessModal(false);
                if (err && err.response && err.response.status === 403) {
                    setOrder({
                        ...order,
                        promoCode: '',
                        bonusCardError: true
                    })
                }
            })
        }
    };

    const toggleModal = () => {
        setModalVisible(!modalVisible);
        history.push( '/cart');
    };

    function autoCompleteAddress () {
        const autocomplete = refToServices?.current?.autocomplete;
        autocomplete && autocomplete.getPlacePredictions({ input: address?.mainAddress },
            (predictions, status) => {
                if (Array.isArray(predictions) && predictions?.length) {
                    setSuggestions(predictions.slice(0, 10))
                } else {
                    setSuggestions([]);
                }
            }
        );
    }

    function onChange ({ center, zoom, bounds, marginBounds }) {
        // console.group('ONCHANGE')
        // console.log('center', center);
        // console.log('mapCenter', mapCenter);
        // console.log('zoom', zoom);
        // console.log('centerChangedByAddress', centerChangedByAddress);
        // console.log('bounds', bounds);
        // console.log('marginBounds', marginBounds);
        // console.groupEnd();
        setCenter(center)
        // console.log(center, 'center');
        if (!centerChangedByAddress) {
            getAddressFromLatLng(center);
        } else {
            setCenterChangedByAddress(false);
        }
    }

    function getAddressFromLatLng (location) {
        const lat_lng = { lat: location.lat, lng: location.lng };
        const geoCoder = refToServices?.current?.geoCoder;
        geoCoder && geoCoder.geocode({ location: lat_lng }, (results, status) => {
            console.log('status', status);
            if (status === "OK") {
                let _address = results?.find(add=>add.types[0]!=='plus_code') || results[0];
                console.log(results)
                // if (_address && _address.startsWith('Unnamed Road')) {
                //     _address = _address.substring('Unnamed Road, '.length)
                // }
                setAddress({
                    ...address,
                    mainAddress: _address?.formatted_address,
                    lat: _address?.geometry?.location?.lat(),
                    lng: _address?.geometry?.location?.lng(),
                });
            }
        });
    }

    function selectSuggestion (suggestion) {
        getLocationByPlaceId(suggestion?.place_id)
        setAddress({
            ...address,
            mainAddress: suggestion.description
        });
        setSuggestions([]);
    }

    function getDirection (e) {
        const { value } = e.target;
        setAddress({
            ...address,
            secondaryAddress: value
        })
    }

    function handleApiLoaded (map, maps) {
        // console.log('init google service')
        setGoogleMap(map);
        const _geoCoder = maps && new maps.Geocoder();
        const _autocompleteService = maps && new maps.places.AutocompleteService();

        refToServices.current = {
            geoCoder: _geoCoder,
            autocomplete: _autocompleteService,
        }
        getAddressFromLatLng(mapCenter);
    }

    function handleChangeAddress (e) {
        const value = e.target.value;
        // console.log("target",e);
        setAddress({
            ...address,
            mainAddress: value
        });
        if (value.length > 2) {
            debouncedAutoCompleteAddress();
        } else {
            setSuggestions([])
        }
    }

    function getLocationByPlaceId (placeId) {
        const geoCoder = refToServices?.current?.geoCoder;
        geoCoder && geoCoder.geocode({ placeId: placeId }, (results, status) => {
            if (status === "OK") {
                if (results[0]) {
                    const geoLocation = results[0]?.geometry?.location;
                    if (geoLocation) {
                        setCenterChangedByAddress(true);
                        const location = {
                            lat: geoLocation?.lat(),
                            lng: geoLocation?.lng(),
                        }
                        googleMap.setCenter(location);
                    }
                }
            }
        });

    }

    function getCurrentLocation () {
        // navigator.geolocation.getCurrentPosition(function(position) {
        //     const location = {
        //         lat: position.coords.latitude,
        //         lng: position.coords.longitude,
        //     };
        //     googleMap.setCenter(location);
        // });

        navigator.geolocation.getCurrentPosition(success => {
            const location = {
                lat: success.coords.latitude,
                lng: success.coords.longitude,
            };
            googleMap.setCenter(location);
        }, failure => {
            if (failure.message.startsWith("Only secure origins are allowed")) {
                // console.log(failure.message);
                // Secure Origin issue.
            }
        });
    }

    const { staticTexts, requestLoading } = props;
    let defaultAddress = props.address?.lat && props?.address?.lng ? {
        lat: props.address?.lat,
        lng: props.address?.lng
    } : MAP_DEFAULT_CENTER

    return <div className={`cart-order-wrapper `}>
        <div className="content-title">{staticTexts?.cart_order_details_title}</div>
        <div className="page-content">
            <div className="left-part">
                <div className="content-subtitle">{staticTexts?.cart_order_address_title}</div>
                <div className="form-wrapper">
                    <div className={'main-address'}>
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            name={'mainAddress'}
                            error={errors.mainAddress}
                            value={address?.mainAddress}
                            label={staticTexts?.map_modal_label_residence_street}
                            maxLength={50}
                            onChange={handleChangeAddress}
                            id={'address-content'}>
                            <ul className={`suggestions-wrapper ${suggestions?.length ? 'active' : ''}`}>
                                {suggestions.map((item, index) => {
                                    return <li key={index}
                                               className={'item'}
                                               onClick={() => selectSuggestion(item)}>
                                        {item?.description}
                                    </li>
                                })}
                            </ul>
                        </InputGroup>
                    </div>
                    <div className={'secondary-address'}>
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            name={'secondaryAddress'}
                            value={address?.secondaryAddress}
                            label={staticTexts?.cart_label_delivery_address}
                            maxLength={50}
                            onChange={(e) => {
                                setAddress({
                                    ...address,
                                    secondaryAddress: e?.target?.value
                                })
                            }}
                        />
                    </div>
                </div>
                <div className={'map'}>
                    <div className="current-location" onClick={getCurrentLocation}>
                        <CurrentLocationIcon title={''}/>
                    </div>
                    <GoogleMapReact
                        bootstrapURLKeys={{
                            key: GOOGLE_MAP_KEY,
                            libraries: 'places'
                        }}
                        defaultCenter={defaultAddress}
                        distanceToMouse={() => {
                            // console.log('distanceToMouse');
                        }}
                        onDrag={() => {
                            setDragging(true);
                        }}
                        onDragEnd={() => {
                            setDragging(false);
                        }}
                        defaultZoom={MAP_DEFAULT_ZOOM}
                        onChange={onChange}
                        options={{
                            // fullscreenControl: false,
                        }}
                        yesIWantToUseGoogleMapApiInternals={true}
                        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                    >
                        {/*<Marker/>*/}

                    </GoogleMapReact>
                </div>
            </div>
            <div className={'right-part'}>
                <div className="content-subtitle">{staticTexts?.cart_order_details_title}</div>
                <div className="content-box">
                    <div className={'order-right-info'}>
                        <div className={'order-info'}>
                            <InputGroup
                                inputType={'input'}
                                type={"text"}
                                name={'name'}
                                value={order?.name}
                                error={errors?.name}
                                label={staticTexts?.cart_label_name}
                                maxLength={99}
                                onChange={getInputValues}
                            />
                            <InputGroup
                                inputType={'input'}
                                type={"text"}
                                name={'email'}
                                value={order?.email}
                                error={errors?.email}
                                label={staticTexts?.cart_label_email}
                                maxLength={50}
                                onChange={getInputValues}
                            />
                            <InputGroup
                                inputType={'input'}
                                type={"text"}
                                name={'phoneNumber'}
                                value={order?.phoneNumber}
                                error={errors?.phoneNumber}
                                label={staticTexts?.cart_label_phoneNumber}
                                maxLength={8}
                                onChange={getInputValues}
                                className={'phone-input'}
                            ><span className="phone-code">+374</span></InputGroup>
                            <div className={'payment-checks'}>
                                <Radio.Group onChange={(e) => {
                                    setPaymentType(e.target?.value);
                                }} value={paymentType}>
                                    <Radio disabled={false}
                                           value={PAYMENTS_TYPES.CARD}>{staticTexts?.cart_payment_type_online}</Radio>
                                    <Radio value={PAYMENTS_TYPES.CASH}>{staticTexts?.cart_payment_type_cash}</Radio>
                                    {/*<Radio disabled={!props?.bonusCard}*/}
                                    {/*       value={PAYMENTS_TYPES.BONUS_CARD}>{staticTexts?.cart_payment_type_bonusCard}</Radio>*/}
                                </Radio.Group>
                            </div>
                        </div>
                    </div>
                    <div className={`order-text ${errors?.agreement ? 'error' : ''}`}>
                        <Checkbox checked={agreement} onChange={() => {
                            setAgreement(!agreement)
                        }}>
                            {staticTexts?.agreement_text_sale_terms} &nbsp;
                            <Link to={'/privacy-policy'}>{staticTexts?.agreement_text_privacy}</Link>
                            &nbsp;{staticTexts?.agreement_text_and}&nbsp;
                            <Link
                                to={'/privacy-policy'}>{staticTexts?.agreement_text_personal_data_processing_policy}</Link>
                        </Checkbox>
                    </div>
                    <PrimaryButton
                        title={staticTexts?.cart_btn_make_order}
                        cb={sendOrder}
                        loading={requestLoading}
                        className={'confirm-button'}
                        disabled={disabledBtn || !userCart?.length || loggedInUser?.isBlocked}/>
                </div>
            </div>
        </div>

        <InfoModal
            // bonusCardError={staticTexts?.cart_not_enough_bonus_points}
            visible={modalVisible}
            toggleModal={toggleModal}
            successModal={successModal}
            errorModal={errorModal}
        />
    </div>
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'requestLoading',
        'address',
        'isLoggedIn',
        'selectedAddress',
        'loggedInUser',
        'userCart',
        'residences',
        'bonusCard',
        'promoCodes',
        'currentCurrency',
    ])
};

const mapDispatchToProps = {
    changeProductQuantityInCart,
    clearUserCart,
    removeProductFromCart,
    AddOrder,
    toggleNotificationRow,
    // GetResidences,
    // ClearResidences,
    GetBonusCards
};


export default connect(mapStateToProps, mapDispatchToProps)(CartOrder);
