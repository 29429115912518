// import packages
import React, { Component } from "react";

//Import styles
import "./categorySubItems.scss";
import { history } from "../../../configs/history";

class CategorySubItems extends Component {
    constructor () {
        super();
        this.state = {};
    }

    render () {
        const { data, categoryId, subCategoryId, staticTexts } = this.props;
        return !!data?.length && <div className="category-items-wrapper">
            <div className="category-sub-list-wrapper">
                <div className={`category-sub-item ${!subCategoryId ? 'selected-sub-category' : ''}`}
                     onClick={() => {
                         history.push(`/category/${categoryId}`)
                     }}
                >
                    <span className={"category-name"}>{staticTexts?.sub_categories_option_all}</span>
                </div>
                {data?.map(item => {
                    return <div key={item.id}
                                className={`category-sub-item ${item?.id === subCategoryId ? 'selected-sub-category' : ''}`}
                                onClick={() => {
                                    history.push(`/category/${categoryId}/${item.id}`)
                                }}
                    >
                        <span className={"category-name"}>{item?.title}</span>
                    </div>
                })}
            </div>
        </div>
    }
}


export default CategorySubItems;
