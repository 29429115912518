// Import packages
import React from "react";
import InfiniteScroll from 'react-infinite-scroller';

// Import styles
import './productList.scss';


// Import components
import ProductCard from "../../components/uiElements/cards/productCard/ProductCard";
import {LoadingLinear} from "../../components/uiElements/loading/Loading";
import {NoResult, ProductEmpty, SearchEmpty} from "../../assets/images";
import {useLocation} from "react-router-dom";


export function ProductList(props) {
    let {loadMoreItems, products, loading, noResult, gridView = false} = props;
    const location = useLocation();
    let inSearchPage = location.pathname.includes('search')

    // console.log("inSearchPage", inSearchPage)
    return <InfiniteScroll
        hasMore={products?.hasMore}
        loadMore={loadMoreItems ? loadMoreItems : () => {
        }}
        className={'products-list-wrapper'}
        pageStart={0}
        initialLoad={false}>
        {
            !!products?.list?.length ? products.list.map(item => {
                return <ProductCard gridView={gridView} data={item} key={item.id} />
            }) :
                <div className="no-products-wrapper">
                {loading ?
                    <LoadingLinear/> : <>
                    {inSearchPage ? <SearchEmpty className={'no-products'} /> : <ProductEmpty className={'no-products'}/>}
                        <p className="no-result-text">{noResult && noResult}</p>
                    </>
                }
            </div>
        }
    </InfiniteScroll>
}
