// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {history} from "../../configs/history";

//import styles
import "./cart.scss";

//Import Components
// import CartProductCard from "../../components/cards/CartProductCard";
import InfoModal from "../../components/uiElements/infoModal/InfoModal";
import {PrimaryButton} from "../../components/uiElements/buttons/Buttons";
import {InputGroup} from "../../components/uiElements/inputGroup/inputGroup";

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {
    changeProductQuantityInCart,
    clearUserCart,
    removeProductFromCart,
    AddOrder,
    toggleNotificationRow,
    // GetResidences,
    // ClearResidences,
    // GetBonusCards,
    getCartProducts,
    GetProductsByIds
} from "../../redux/actions";

import {_urlPromoCodes, request} from "../../redux/api";
import {CartEmpty} from "../../assets/images";
import CartProductCard from "../../components/uiElements/cards/cartProductCard/CartProductCard";
import {CURRENCIES_TYPES} from "../../constants/constTypes";

// import {getCurrency} from "../../utils/helperFunctions";

function Cart(props) {
    const {loggedInUser} = props;
    const [order, setOrder] = useState({
        promoCode: '',
        checkPromoCode: true,
        checkCodeMessage: '',
        bonusCardError: false,
        promoCodeError: false,
        discountRate: ""
    });

    const {userCart, currentCurrency, isLoggedIn} = props;
    const [modalVisible, setModalVisible] = useState(false);
    const [goSignIn, setGoSignIn] = useState(false);
    const [confirmModal, setConfirmModal] = useState({
        visible: false,
        descriptionText: ""
    });
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    })

    useEffect(() => {
        // props.GetResidences("5ef386e57a9e4b23b16dd200");
        // // isLoggedIn && props.GetBonusCards();
        setOrder({
            ...order,
            address: loggedInUser.selectedAddress
        });
        // return function cleanup() {
        //     props.ClearResidences()
        // };
    }, [loggedInUser.selectedAddress, currentCurrency, props.selectedLanguage]);

    useEffect(() => {
        let productIds = [];
        !!userCart?.length && userCart.forEach(item => {
            item?.product?.id && productIds.push(item?.product?.id)
        });
        productIds.length && props.GetProductsByIds({
            productIds: [...productIds],
        }).then((res) => {
            props.getCartProducts(res.data)
        });
    }, [props.selectedLanguage]);

    const getInputValues = (e) => {
        const {name, value} = e.target;
        value ? setOrder({
                ...order,
                [name]: value,
                checkCodeMessage: '',
                checkPromoCode: false,
                discountRate: ""
            }) :
            setOrder({
                checkPromoCode: true,
            })

    };

    const toggleModal = () => {
        setModalVisible(!modalVisible);
    };
    const cancelOrderProductInModal = () => {
        const notAvailableProducts = userCart?.filter(item => !item.product.isAvailable)
        notAvailableProducts.map(item => {
            props.removeProductFromCart(item?.product?.id)
        })
        setModalVisible(false)

    }
    const makeOrder = () => {
        if (isLoggedIn) {
            if (!userCart?.filter(item => !item.product.isAvailable)?.length) {
                history.push({
                    pathname: '/cart/order',
                    state: {
                        cart: order,
                        sum,
                    }
                });
                setGoSignIn(false);
            } else {
                setModalVisible(true)
                setConfirmModal({
                    visible: true,
                    descriptionText: staticTexts?.modal_cart_has_not_available_product
                })
                setGoSignIn(false);
            }
        } else {
            setModalVisible(true)
            setGoSignIn(true);
        }

    };

    const CheckPromoCode = () => {
        const {staticTexts} = props;
        const reqData = {};
        reqData.code = order.promoCode;
        const requestData = {
            url: `${_urlPromoCodes}`,
            method: "POST",
            data: reqData,
        };
        request(requestData)
            .then((res) => {
                const data = res && res.data;
                setOrder({
                    ...order,
                    checkCodeMessage: staticTexts?.cart_promoCode_success,
                    discountRate: data.discountRate,
                    checkPromoCode: true
                })
            }).catch((err) => {
            if (err && err.response && (err.response.status === 404 || err.response.status === 400)) {
                setOrder({
                    ...order,
                    promoCode: '',
                    checkCodeMessage: staticTexts?.cart_promoCode_error,
                    discountRate: '',
                    checkPromoCode: true
                })
            }
            if (err && err.response && err.response.status === 403) {
                setOrder({
                    ...order,
                    promoCode: '',
                    checkCodeMessage: staticTexts?.cart_promoCode_error_already_used,
                    discountRate: '',
                    checkPromoCode: true
                })
            }
        })
    };

    const {residences, staticTexts} = props;

    // let deliveryPrice = !!residences?.length ? residences?.find(item => item?.id === '5ef386e57a9e4b23b16dd200')?.deliveryPrice : 600;
    let deliveryPrice = 0;

    let totalAmount = userCart?.map(cartItem => cartItem?.totalPrice).reduce((sum, price) => sum + price, 0);

    let productsCount = userCart?.map(cartItem => cartItem?.quantity).reduce((sum, price) => sum + price, 0);

    let discountedPrices = userCart?.map(cartItem => {
        return cartItem?.product?.oldPrice && cartItem?.product?.oldPrice > cartItem?.product?.price
            ? cartItem?.quantity * (cartItem?.product?.oldPrice - cartItem?.product?.price) : 0
    });

    let discountedAmount = discountedPrices?.reduce((sum, price) => sum + price, 0);

    let payableAmount = !!order?.discountRate ? (totalAmount - (totalAmount * order?.discountRate / 100)) + deliveryPrice
        : totalAmount + deliveryPrice;

    // let sum = (totalAmount + deliveryPrice) - ((totalAmount + deliveryPrice) * order?.discountRate / 100);
    let sum = totalAmount;

    return <div className={`cart-wrapper ${!userCart?.length && "cart-empty"}`}>
        {!!userCart?.length && <div className="cart-header">
            {staticTexts?.cart_products_count?.replace('%d', userCart?.length)}
        </div>}

        <div className={"cart-main-content"}>{userCart?.length ?
            <>
                <div className={'card-list'}>
                    <div className={'cart-products'}>
                        {userCart.map(cartItem => {
                            return <CartProductCard data={cartItem?.product}
                                                    staticTexts={staticTexts}
                                                    quantityInCart={cartItem?.quantity}
                                                    currentCurrency={currentCurrency}
                                                    key={cartItem?.product?.id}
                                                    changeProductQuantityInCart={props.changeProductQuantityInCart}
                                                    removeProductFromCart={props.removeProductFromCart}/>
                        })}
                    </div>
                    <div className={'clear-cart'}
                         onClick={props?.clearUserCart}>{staticTexts?.cart_clear_cart}</div>
                </div>
                <div className="payment-details">
                    <div className="payment-details-title">{staticTexts?.cart_order_details_title}</div>
                    <div className="payment-details-content">
                        {userCart?.length > 0 && <>
                            <div className={'summery-item'}>
                                <div>{staticTexts?.cart_order_details_all_products}</div>
                                <div className={'line'}/>
                                <div className="item-number">{productsCount}</div>
                            </div>
                            <div className={'summery-item'}>
                                <div>{staticTexts?.cart_order_details_amount}</div>
                                <div className={'line'}/>
                                <div className="item-number">
                                    {totalAmount}&nbsp;
                                    {CURRENCIES_TYPES.find(item => item.id === "AMD")?.icon}
                                </div>
                            </div>
                            {/*<div className={'summery-item'}>*/}
                            {/*    <div>{staticTexts?.cart_order_details_shipping}</div>*/}
                            {/*    <div className={'line'}/>*/}
                            {/*    <div className="item-number shipping-amount">*/}
                            {/*        {deliveryPrice}&nbsp;*/}
                            {/*        {CURRENCIES_TYPES.find(item => item.id === "AMD")?.icon}*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className={'summery-item'}>
                                <div>{staticTexts?.cart_order_details_discount}</div>
                                <div className={'line'}/>
                                <div className={'discounted_amount'}>
                                    {discountedAmount}&nbsp;
                                    {CURRENCIES_TYPES.find(item => item.id === "AMD")?.icon}
                                </div>
                            </div>
                            {order?.discountRate && order?.promoCode.length === 12 && <div className={'summery-item'}>
                                <span>{staticTexts?.cart_discount_size}:</span>
                                <span>{order?.discountRate} %</span>
                            </div>}
                            <div className={'summery-item payable-amount'}>
                                <div>{staticTexts?.cart_order_details_total_price}</div>
                                <div className={'line'}/>
                                <div className="item-number">
                                    {payableAmount}&nbsp;
                                    {CURRENCIES_TYPES.find(item => item.id === "AMD")?.icon}
                                </div>
                            </div>
                        </>}

                        {order?.checkPromoCode ?
                            <div onClick={makeOrder}>
                                <PrimaryButton className="order-btn" title={staticTexts?.cart_btn_make_order}
                                               disabled={!order?.checkPromoCode}/>
                            </div>
                            : <PrimaryButton title={staticTexts?.cart_btn_make_order}
                                             disabled={!order?.checkPromoCode}
                            />}

                        <div className={'promoCode-description'}>{staticTexts?.cart_promoCode_description}</div>
                    </div>
                    <div className={'promoCode'}>
                        <div className={"promoCode-input"}>
                            <InputGroup
                                inputType={'input'}
                                type={"text"}
                                name={'promoCode'}
                                value={order?.promoCode}
                                placeholder={staticTexts?.cart_label_promoCode}
                                maxLength={12}
                                disabled
                                onChange={getInputValues}
                            />
                            <PrimaryButton
                                title={staticTexts?.cart_send_promoCode_btn}
                                disabled={order?.promoCode?.length < 12}
                                className={'small-btn'}
                                cb={CheckPromoCode}/>
                        </div>

                        <div
                            className={"checkCode-message"}>{order?.checkCodeMessage && order?.checkCodeMessage}</div>
                    </div>
                </div>
            </> :
            <div className={"cart-empty-section"}>
                <CartEmpty/>
                <span>{staticTexts?.cart_empty_cart}</span>
                <Link to={"/products"}>{staticTexts?.cart_link_to_products_list}</Link>
            </div>
        }</div>
        <InfoModal
            bonusCardError={staticTexts?.cart_not_enough_bonus_points}
            visible={modalVisible}
            goSignIn={goSignIn}
            confirmModal={confirmModal?.visible}
            descriptionText={confirmModal?.descriptionText}
            toggleModal={toggleModal}
            cancelOrderInModal={cancelOrderProductInModal}
        />
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'isLoggedIn',
        'selectedAddress',
        'loggedInUser',
        'userCart',
        'residences',
        'bonusCard',
        'promoCodes',
        'currentCurrency',
        'selectedLanguage',
    ])
};

const mapDispatchToProps = {
    changeProductQuantityInCart,
    clearUserCart,
    removeProductFromCart,
    AddOrder,
    toggleNotificationRow,
    // GetResidences,
    // ClearResidences,
    // GetBonusCards,
    getCartProducts,
    GetProductsByIds
};


export default connect(mapStateToProps, mapDispatchToProps)(Cart);
