// import packages
import React from "react";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {connect} from "react-redux";

// import assets
import './notFound.scss'

// import components
import {PrimaryButton} from "../../components/uiElements/buttons/Buttons";
import {NotFoundEmpty} from "../../assets/images";

function NotFound(props) {

    const {staticTexts} = props

    return <div className="no-result-empty-view">
        <NotFoundEmpty/>
        <p>{staticTexts?.not_found_empty_view}</p>
        <PrimaryButton link={"/"} title={staticTexts?.rout_item_home}/>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
    ])
};
export default connect(mapStateToProps)(NotFound);
