import {AUTH_CONSTS, ORDERS_CONSTS} from "../constants";

export const initialState = {
    orders: {},
    singleOrder: null,
    orderStatuses: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ORDERS_CONSTS.GET_ORDERS:
            return {
                ...state,
                orders: {
                    ...state.orders,
                    [action.payload.status ? action.payload.status : 'all']: {
                        itemsList: action.payload.reset ? action.payload.data : [...state.orders[action.payload.status ? action.payload.status : 'all'].itemsList, ...action.payload.data],
                        status: action.payload.status,
                        hasMore: action.payload.hasMore
                    }
                }
            };

        case ORDERS_CONSTS.GET_SINGLE_ORDER:
            return {
                ...state,
                singleOrder: action.payload,
            };
        case ORDERS_CONSTS.CLEAR_SINGLE_ORDER:
            return {
                ...state,
                singleOrder: null,
            };
        case ORDERS_CONSTS.GET_ORDER_STATUSES:
            return {
                ...state,
                orderStatuses: action.payload
            };
        case ORDERS_CONSTS.CANCELED_ORDER:
            const newStatus = state.orderStatuses?.find(status => status.id === "5ee8dda24ac0e93f4336fcdd")
            return {
                ...state,
                orders: {
                    ...state.orders,
                    [action.payload.status]: {
                        itemsList: state.orders?.[action.payload.status]?.itemsList?.map(item => item.id === action.payload ?
                            {
                                ...item,
                                status: {
                                    ...newStatus
                                }
                            } : item
                        ),
                    },
                    all: {
                        itemsList: state.orders?.all?.itemsList?.map(item => item.id === action?.payload ?
                            {
                                ...item,
                                status: {
                                    ...newStatus
                                }
                            } : item
                        ),
                    }
                }
            };
        case ORDERS_CONSTS.DELETE_ORDER:
            return {
                ...state,
                orders: {
                    ...state.orders,
                    [action.payload.status]: {
                        itemsList: state.orders?.[action.payload.status]?.itemsList?.filter(order => order.id !== action?.payload?.id),
                    },
                    all: {
                        itemsList: state.orders?.all?.itemsList?.filter(order => order.id !== action?.payload?.id),
                    }
                }
            };

        case AUTH_CONSTS.LOG_OUT:
            return {
                ...state,
                ...initialState
            };
        default:
            return state;
    }
}
