// Import packages
import React, {useState, useEffect} from "react";
import {connect} from 'react-redux';
import moment from "moment";
import {useParams} from "react-router-dom";
import {Tabs} from "antd";
import InfiniteScroll from 'react-infinite-scroller';

// Import styles
import './orders.scss';
import {ClearIcon, OrdersEmpty} from "../../../assets/images";

// Import components
import OrderCard from "../../../components/uiElements/cards/orderCard/OrderCard";
import {PrimaryButton} from "../../../components/uiElements/buttons/Buttons";
import InfoModal from "../../../components/uiElements/infoModal/InfoModal";

//import utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {
    GetOrders,
    GetOrderStatuses,
    DeleteOrder,
    CanceledOrder,
    GetSingleOrder,
    ClearSingleOrder
} from "../../../redux/actions";
import {history} from "../../../configs/history";
import {generateImageMediaUrl} from "../../../utils/generateMediaUrl";
import {CURRENCIES_TYPES} from "../../../constants/constTypes";
import {LoadingOutlined} from "@ant-design/icons";
import {resizeImage} from "../../../utils/resizeImage";


const {TabPane} = Tabs;

function Orders(props) {
    const [modalVisible, setModalVisible] = useState(false);
    const [isFetching, setFetching] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [activeTab, setActiveTab] = useState("all");
    const [canRateOrder, setCanRateOrder] = useState(false);
    const params = useParams();
    let {id} = params;

    useEffect(() => {
        setLoaded(true)
        props.GetOrders().then(() => {
            setLoaded(false)
            if (id) {
                props.GetSingleOrder(id).then((res) => {
                    let updatedAt = moment(res?.updatedAt).format('YYYY MM DD HH:mm:ss');
                    let productUpdateTime = new Date(updatedAt).getTime()
                    let now = new Date().getTime();
                    setCanRateOrder((now - productUpdateTime) / 60000 > 1)
                })
            }
        });
        return function cleanup() {
            props.ClearSingleOrder()
        };
    }, [props.selectedLanguage]);

    useEffect(() => {
        props.getPageTitle(staticTexts?.orders_history_title)
    }, []);

    const changeTab = (status) => {
        const {orders} = props;

        setActiveTab(status)
        clearSingleOrder()
        const isGot = Object.keys(orders).find(item => item === status)
        !isGot && setLoaded(true)
        !isGot && props.GetOrders({status}).then(() => {
            setLoaded(false)
        });
    }

    const selectOrder = (num) => {
        history.push(`/profile/orders/${num}`)
        props.GetSingleOrder(num).then((res) => {
            console.log("res.rated", res.rated)
            let updatedAt = moment(res?.updatedAt).format('YYYY MM DD HH:mm:ss');
            let productUpdateTime = new Date(updatedAt).getTime()
            let now = new Date().getTime();
            setCanRateOrder((now - productUpdateTime) / 60000 > 1)

        })
    }

    const clearSingleOrder = () => {
        props.ClearSingleOrder()
        history.push("/profile/orders")
    }

    const toggleModal = () => {
        setModalVisible(!modalVisible);
    };

    const cancelOrderInModal = () => {
        props.CanceledOrder(singleOrder?.id);
        setModalVisible(false)
    }

    const loadMoreItems = () => {
        const {orders} = props;
        if (!isFetching) {
            setFetching(true);
            orders[activeTab ? activeTab : 'all']?.hasMore && props.GetOrders({
                reset: false,
                offset: orders[activeTab ? activeTab : 'all']?.itemsList?.length,
                status: activeTab === "all" ? '' : activeTab,
            }).finally(() => {
                setFetching(false);
            });
        }
    };

    const {staticTexts, loggedInUser, orders, singleOrder} = props

    return <section className='orders-wrapper'>
        <div className="orders-tabs">
            <Tabs onChange={changeTab} defaultActiveKey='1'>
                <TabPane tab={staticTexts?.orders_types_tabs_all_orders_tab} key="all">
                    <InfiniteScroll
                        hasMore={orders?.['all']?.hasMore}
                        loadMore={loadMoreItems}
                        pageStart={0}
                        useWindow={true}
                        className={`${singleOrder?.orderNumber ? "hide-list" : ""}`}
                        initialLoad={false}>
                        <>{!loaded ?
                            (!!props.orders?.['all']?.itemsList?.length ? props.orders?.['all']?.itemsList.map(item => {
                                    return <div onClick={() => selectOrder(item?.orderNumber)}
                                                className={`order-card ${item?.orderNumber === params.id ? "active-order" : ""}`}>
                                        <OrderCard key={item.id} data={item}
                                                   staticTexts={staticTexts}
                                                   status={props.orderStatuses.find(status => status.id === item.status.id)}/>
                                    </div>
                                }) :
                                <div className="orders-empty-view">
                                    <OrdersEmpty/>
                                    <p>{staticTexts?.orders_empty_view}</p>
                                </div>)
                            : <LoadingOutlined
                                style={{fontSize: 22, color: "#666", marginTop: "40px", marginLeft: "20px"}}/>}
                        </>
                    </InfiniteScroll>
                </TabPane>
                {
                    !!props?.orderStatuses?.length && props.orderStatuses?.map(status => {
                        return <TabPane tab={status.title} key={status.id}>
                            <InfiniteScroll
                                hasMore={orders?.[status?.id]?.hasMore}
                                loadMore={loadMoreItems}
                                pageStart={0}
                                useWindow={true}
                                className={`${singleOrder?.orderNumber ? "hide-list" : ""}`}
                                initialLoad={false}>
                                <>{!loaded ?
                                    (orders[status?.id] && !!orders[status?.id]?.itemsList?.length ? orders[status?.id]?.itemsList.map(item => {

                                            return status.id === item.status.id &&
                                                <div onClick={() => selectOrder(item?.orderNumber)}
                                                     className={`order-card ${item?.orderNumber === params.id ? "active-order" : ""}`}>
                                                    <OrderCard key={item.id}
                                                               data={item}
                                                               status={status.id === item.status.id ? item.status : ''}/>
                                                </div>
                                        }) :
                                        <div className="orders-empty-view">
                                            <OrdersEmpty/>
                                            <p>{staticTexts?.orders_empty_view}</p>
                                        </div>) : <LoadingOutlined
                                        style={{fontSize: 22, color: "#666", marginTop: "40px", marginLeft: "20px"}}/>}
                                </>
                            </InfiniteScroll>
                        </TabPane>
                    })
                }
                {singleOrder?.orderNumber &&
                <div className="order-details">
                    <div className="order-details-header">
                        <div className="details-title">{staticTexts?.orders_details_title}</div>
                        <div onClick={clearSingleOrder}><ClearIcon/></div>
                    </div>
                    {singleOrder?.products?.length && <div className="details-products-list">
                        {singleOrder?.products?.map(data => {
                            let imageUrl = resizeImage(generateImageMediaUrl((data?.mediaMain || data?.medias)
                                && (data?.mediaMain?.path ? data?.mediaMain?.path : data?.medias[0]?.path)));
                            let imageAlt = (data?.mediaMain || data?.medias);
                            return <div className="product-item">
                                <div className="image-wrapper">
                                    <div className={'image-proportion'}>
                                        <img src={imageUrl} className="product-card-img"
                                             alt={imageAlt?.altAttribute ? imageAlt?.altAttribute : ''}/>
                                    </div>
                                </div>
                                <div className="product-content">
                                    <div className="product-title">{data?.title || ''}</div>
                                    <div className="price-content">
                                        {data?.purchasedQuantity} x&nbsp;
                                        {data?.price && <div className={`price`}>
                                            <span>{data?.price}</span>&nbsp;
                                            {CURRENCIES_TYPES.find(item => item.id === "AMD")?.icon}
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>}
                    {singleOrder?.products?.length &&
                    <div
                        className="products-count-wrapper">{singleOrder?.products?.length} {staticTexts?.orders_details_products_count}</div>}
                    <div className="order-info-wrapper">
                        <div className="order-info-item">
                            <span className="info-item-title">{staticTexts?.orders_details_address}</span>
                            <span className="info-item-text">{singleOrder?.address}</span>
                        </div>
                        <div className="order-info-item">
                            <span className="info-item-title">{staticTexts?.orders_details_receiver}</span>
                            <span
                                className="info-item-text">{`${loggedInUser?.firstName} ${loggedInUser?.lastName ? loggedInUser?.lastName : ""}`}</span>
                        </div>
                        <div className="order-info-item">
                            <span className="info-item-title">{staticTexts?.orders_details_date}</span>
                            <span
                                className="info-item-text">{moment(singleOrder.createdAt).format("DD MMM YYYY")}</span>
                        </div>
                        <div className="order-info-item">
                            <span className="info-item-title">{staticTexts?.orders_details_status}</span>
                            <span className="info-item-text"
                                  style={{color: singleOrder?.status?.color}}>{singleOrder?.status?.title}</span>
                        </div>
                        <div className="order-info-item price-item">
                            <span className="info-item-title">{staticTexts?.order_total_amount}</span>
                            <span
                                className="info-item-text">{singleOrder?.sum} {CURRENCIES_TYPES.find(item => item.id === "AMD")?.icon}</span>
                        </div>
                    </div>
                    <div className="btns-wrapper">
                        {(!singleOrder?.rated) ? ((singleOrder?.status?.id === "5ee8dd7c4ac0e93f4336fcd3" && canRateOrder) ?
                            <PrimaryButton link={`/rate/${singleOrder?.orderNumber}`}
                                           title={staticTexts?.orders_right_btn_rate_order}
                            /> : <PrimaryButton className="cancel-btn without-hover"
                                                title={staticTexts?.orders_right_btn_cancel_order}
                                                cb={() => setModalVisible(true)}
                                                disabled={singleOrder?.status?.id !== "5ee8dd7c4ac0e93f4336fcda"}
                            />) : ""}
                        <PrimaryButton className="delete-btn without-hover"
                                       title={staticTexts?.orders_right_btn_delete_order}
                                       cb={() => {
                                           props.DeleteOrder(singleOrder?.id, singleOrder?.status?.id);
                                           history.push("/profile/orders")
                                           clearSingleOrder()
                                       }}
                                       disabled={singleOrder?.status?.id === "5ee8dd984ac0e93f4336fcdb" || singleOrder?.status?.id === "5ee8dd7c4ac0e93f4336fcda"}
                        />
                    </div>
                </div>}
            </Tabs>
            <InfoModal
                visible={modalVisible}
                toggleModal={toggleModal}
                confirmModal
                descriptionText={staticTexts?.orders_cancel_order_modal_text}
                cancelOrderInModal={cancelOrderInModal}
            />
        </div>
    </section>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
    'loggedInUser',
    'selectedLanguage',
    'orders',
    'orderStatuses',
    'requestLoading',
    'singleOrder',
]);

const mapDispatchToProps =
    {
        GetOrders,
        GetOrderStatuses,
        DeleteOrder,
        CanceledOrder,
        GetSingleOrder,
        ClearSingleOrder
    }
;

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
