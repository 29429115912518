import {AUTH_CONSTS, BLOGS_CONSTS} from "../constants";

export const initialState = {
    blogs: {
        itemsList: [],
        hasMore: false,
        badge: undefined,
    },
    singleBlog: null,
    blogTypes: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case BLOGS_CONSTS.GET_BLOGS:
            return {
                ...state,
                blogs: {
                    ...state.blogs,
                    [action.payload.badge ? action.payload.badge : 'all']: {
                        itemsList: action.payload.reset ? action.payload.data : [...state.blogs[action.payload.badge ? action.payload.badge : 'all'].itemsList, ...action.payload.data],
                        badge: action.payload.badge,
                        hasMore: action.payload.hasMore
                    }
                }
            };
        case BLOGS_CONSTS.GET_SINGLE_BLOG:
            return {
                ...state,
                singleBlog: action.payload,
            };
        case BLOGS_CONSTS.GET_BLOG_TYPES:
            return {
                ...state,
                blogTypes: action.payload,
            };
        case AUTH_CONSTS.LOG_OUT:
            return {
                ...state,
                ...initialState
            };
        default:
            return state;
    }
}
