// import packages
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { connect } from "react-redux";

//Import styles
import "./categories.scss";

//Import Components
import { SliderButton } from "../../../components/uiElements/buttons/Buttons";
import { generateImageMediaUrl } from "../../../utils/generateMediaUrl";
import { getPropsFromState } from "../../../redux/mapStateToProps";

// Import utils

class Categories extends Component {
    constructor () {
        super();
        this.state = {
            disabledPrev: true,
            disabledNext: false,
        };
    }

    componentDidMount () {
        let itemCount = this.getShowSlideCount();
        this.setState({
            ...this.state,
            disabledNext: this.props?.categories?.length - 1 < itemCount,
        });
    }

    getShowSlideCount = () => {
        let width = window.innerWidth;
        if (width > 1300) return 6;
        else if (width > 1000) return 5;
        else if (width > 800) return 4;
        else if (width > 600) return 3;
        else if (width > 340) return 2;
        else return 1;
    };

    afterSlideChange = (next) => {
        let itemCount = this.getShowSlideCount();
        this.setState({
            disabledPrev: next === 0,
            disabledNext: next + itemCount === this.props.categories?.length,
        });
    };

    render () {
        const { categories, staticTexts } = this.props;
        // console.log("categories", categories)
        const { disabledPrev, disabledNext } = this.state;
        const settings = {
            dots: false,
            arrows: false,
            infinite: false,
            speed: 500,
            slidesToShow: 6,
            slidesToScroll: 1,
            centerMode: false,
            swipe: false,
            ref: categories => (this.categories = categories),
            beforeChange: (current, next) => this.afterSlideChange(next),
            responsive: [
                {
                    breakpoint: 1300,
                    settings: {
                        slidesToShow: 5,
                    }
                },
                {
                    breakpoint: 1000,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 340,
                    settings: {
                        slidesToShow: 1,
                    }
                },
            ]
        };
        return !!categories?.length && <div className="home-categories-wrapper">
            <div className="categories-header">
                <h2 className="categories-title">{staticTexts?.homepage_categories_title}</h2>
                {
                    !!categories?.length &&
                    <SliderButton
                        className={"with-header"}
                        disabledPrev={disabledPrev}
                        disabledNext={disabledNext}
                        cbPrev={() => this.categories.slickPrev()}
                        cbNext={() => this.categories.slickNext()}
                    />
                }
            </div>
            <div className="categories-list-wrapper">
                {
                    <Slider {...settings}>
                        {!!categories.length && categories?.map(item => {
                            return <Link className="category-item-card"
                                         to={`/category/${item.id}`}
                                         key={item.id}>
                                <div className="category-item-card-content">
                                    <div className={`category-item `}>
                                        <img src={generateImageMediaUrl(item?.icon?.path)}
                                             alt={item?.icon?.altAttribute ? item.icon?.altAttribute : ''}/>
                                        <div className="item-title">{item.title}</div>
                                    </div>
                                </div>
                            </Link>
                        })}
                    </Slider>
                }
            </div>
        </div>
    }
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
    'categories',
]);

export default connect(mapStateToProps)(Categories);
