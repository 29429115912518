// import packages
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

//import assets
import './marketDetails.scss'
// import {ReactComponent as RightIcon} from "../../assets/images/icons/ic_back_dark.svg";

// Import Components
import ProductCard from "../../../components/uiElements/cards/productCard/ProductCard";
import MetaTags from "../../../components/MetaTags";


// Import utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {generateImageMediaUrl} from "../../../utils/generateMediaUrl";
import {GetMarketById} from "../../../redux/actions";
import {RightArrowIcon} from "../../../assets/images";

function MarketDetails(props) {
    const {marketById} = props;
    const {id} = props.match.params;
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
       props.GetMarketById(id)
    }, [])
    const staticTexts = props?.staticTexts
    return <div className='market-details'>
        <MetaTags title={props?.location?.state?.title || marketById?.title}
                  image={generateImageMediaUrl(generateImageMediaUrl(marketById?.mediaMain?.path))}/>

        <div className="rout">
                <span className="rout-item">
                    <Link to={'/'}>{staticTexts?.rout_item_home} <RightArrowIcon title={''}/></Link>
                </span>
            <span className="rout-item">
                    <Link to={'/markets'}>{staticTexts?.rout_item_mini_markets} <RightArrowIcon title={''}/></Link>
                </span>
            <span className="rout-item">{props?.location?.state?.title || marketById?.title}
                </span>
        </div>
        <div className="details-header">
            <div className="market-details-title">{props?.location?.state?.title || marketById?.title}</div>
            {marketById?.products?.length && <div className="product-count">{marketById?.products.length} {staticTexts?.banner_product_count_text}</div>}
        </div>
        {marketById?.products?.length && <div className="market-details-cards">
           <div className="cards-wrapper">
               {
                   marketById?.products?.map(item => {
                       return <ProductCard data={item} key={item.id} gridView/>
                   })
               }
           </div>
        </div>}
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'marketById',
        'staticTexts',
    ])
};

const mapDispatchToProps = {
    //redux actions
    GetMarketById
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketDetails)
