// import packages
import React, {memo, useState} from "react";

//Import assets
import "./cart-product-card.scss";

//Import Components
import InfoModal from "../../../uiElements/infoModal/InfoModal";

// Import utils
// import {getCurrency} from "../../utils/helperFunctions";
import {generateImageMediaUrl} from "../../../../utils/generateMediaUrl";
import {ClearIcon, MinusIcon, PlusIcon} from "../../../../assets/images";
import {CURRENCIES_TYPES} from "../../../../constants/constTypes";
import {resizeImage} from "../../../../utils/resizeImage";


const CartProductCard = memo((props) => {
    const {data, quantityInCart, staticTexts} = props;
    const [modalVisible, setModalVisible] = useState(false);
    const [productsIsEmpty, setProductsIsEmpty] = useState(false);

    function decrement() {
        props.changeProductQuantityInCart(data?.id, quantityInCart - 1)
    }

    function increment() {
        if (quantityInCart < data?.quantity) {
            props.changeProductQuantityInCart(data?.id, quantityInCart + 1)
        } else if (!data?.quantity) {
            props.changeProductQuantityInCart(data?.id, quantityInCart + 1)
        }
        if (quantityInCart === data?.quantity) {
            setProductsIsEmpty(true);
            setModalVisible(true);
        }
    }

    function removeFromCart() {
        props.removeProductFromCart(data?.id)
    }

    const toggleModal = () => {
        setModalVisible(!modalVisible);
    };

    let imageUrl = resizeImage(generateImageMediaUrl((data?.mediaMain || data?.medias)
        && (data?.mediaMain?.path ? data?.mediaMain?.path : data?.medias[0]?.path)));

    let imageAlt = (data?.mediaMain || data?.medias);

    return <div className={`cart-product-card-wrapper ${!data?.isAvailable ? "disabled-card" : ""}`}>
        <div className="image-wrapper">
            <div className={'image-proportion'}>
                <img src={imageUrl} className="product-card-img"
                     alt={imageAlt?.altAttribute ? imageAlt?.altAttribute : ''}/>
            </div>
        </div>
        <div className="product-content">
            <div className="title-box">
                <div className="product-title">{data?.title || ''}</div>
                <div className="price-content">
                    {data?.price && <div className={`price ${data?.oldPrice ? 'new-price' : ''}`}>
                        <span>{data?.price}</span>&nbsp;
                        {CURRENCIES_TYPES.find(item => item.id === "AMD")?.icon}
                    </div>}
                    {data?.oldPrice && <div className="old-price">
                        <span>{data?.oldPrice}</span>
                        &nbsp;
                        {CURRENCIES_TYPES.find(item => item.id === "AMD")?.icon}
                    </div>}
                </div>
                {!data?.isAvailable && <span className={'not-available'}>{staticTexts?.cart_product_is_not_available}</span>}
            </div>
            <div className="counter-box">
                <div className="counter">
                    <button className='down' onClick={decrement}><MinusIcon title={''}/></button>
                    {quantityInCart}
                    <button className='up' onClick={increment}><PlusIcon title={''}/></button>
                </div>
            </div>
            <div className="product-sum">
                {data?.price && (data?.price) * quantityInCart.toFixed(1)} &nbsp;
                {data?.price && CURRENCIES_TYPES.find(item => item.id === "AMD")?.icon}
            </div>
            <div className={'delete-btn'}>
                <ClearIcon title={''} onClick={removeFromCart}/>
            </div>
        </div>
        <InfoModal
            visible={modalVisible}
            toggleModal={toggleModal}
            productsIsEmpty={productsIsEmpty}
        />
    </div>
});

export default CartProductCard;


