// import packages
import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

//import assets
import './markets.scss'
import {generateImageMediaUrl} from "../../../utils/generateMediaUrl";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {ArrowIcon, RightArrowIcon} from "../../../assets/images";

// Import Components

// Import utils


function Markets(props) {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    })

    const {markets, count, staticTexts} = props;
    const marketsList =  count ? markets?.slice(0, count) : markets

    return !!marketsList?.length && <div className='markets-wrapper'>
        {!count && <div className="rout">
                <span className="rout-item">
                    <Link to={'/'}>{staticTexts?.rout_item_home} <RightArrowIcon title={''}/></Link>
                </span>
            <span className="rout-item">{staticTexts?.rout_item_mini_markets}</span>
        </div>}
        <div className="markets-header">
            <div className="title">{staticTexts?.homepage_mini_markets_title}</div>
            {count && <Link to={'/markets'}>
                <div className="view-more">{staticTexts?.homepage_btn_see_all}</div>
            </Link>}
        </div>
        <div className="markets-content">
            {marketsList.map(item => {
                return <div className='markets-item' key={item?.id}>
                    <Link to={{
                        pathname: `/market/${item?.id}`,
                    }}>
                        <div className="image-wrapper">
                            <img src={generateImageMediaUrl(item?.mediaMain?.path)}
                                 alt={item?.mediaMain?.altAttribute ? item.mediaMain?.altAttribute : ''}/>
                            <div className="inner-side">
                                <div>
                                    <div className="title">
                                        {item?.title}
                                    </div>
                                    <div className="description">
                                        {item?.description}
                                    </div>
                                </div>
                                <div className="markets-btn">{staticTexts?.btn_see_more} <ArrowIcon/></div>
                            </div>
                        </div>
                    </Link>
                </div>
            })}
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
    ])
};
export default connect(mapStateToProps)(Markets);
