export const USER_CONSTS = {
    ADD_USER: "ADD_USER",
    ACTIVATE_USER: "ACTIVATE_USER",
    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    RESET_PASSWORD: "RESET_PASSWORD",
    GET_CURRENT_USER: "GET_CURRENT_USER",
    UPDATE_CURRENT_USER: "UPDATE_CURRENT_USER",
    UPDATE_CURRENT_USER_PASSWORD: "UPDATE_CURRENT_USER_PASSWORD",
    SEARCH_USERS: "SEARCH_USERS",
    GET_SINGLE_USER: "GET_SINGLE_USER",
    CLEAR_SINGLE_USER: "CLEAR_SINGLE_USER",
};
