export const USER_ERROR_TYPES = Object.freeze({
    USER_ALREADY_EXISTS: 'USER_ALREADY_EXISTS',
    USER_NOT_FOUND: 'USER_NOT_FOUND',
    USER_ALREADY_ACTIVATED: 'USER_ALREADY_ACTIVATED',
    USER_NOT_ACTIVATED: 'USER_NOT_ACTIVATED',
    CODE_NOT_FOUND: 'CODE_NOT_FOUND',
    CODE_INVALID: 'CODE_INVALID',
})

export const NOTIFICATION_ROW_MODES = {
    SUCCESS: 'success',
    ERROR: 'error',
    INFO: 'info',
};

export const NOTIFICATION_TYPES_LIST = {
    NEW_MESSAGE: 'NEW_MESSAGE',
};

export const SORT_TYPES = {
    price: 'Գնի',
    updatedAt: 'Ամսաթվի',
};

export const PAYMENTS_TYPES = {
    CARD:'CARD',
    CASH:'CASH',
    BONUS_CARD:'BONUS_CARD'
};

export const SLIDER_URL_TYPES = {
    PRODUCT:'/product',
    SECTION:'/section-details',
    BANNER:'/market',
    CUSTOM_PAGE:'/custom-page',
};

export const CURRENCIES_TYPES = [
    {
        name: "AMD",
        id: "AMD",
        icon: "֏"
    }, {
        name: "RUB",
        id: "RUB",
        icon: "₽"
    }, {
        name: "USD",
        id: "USD",
        icon: "$"
    }, {
        name: "EUR",
        id: "EUR",
        icon: "€"
    }
];


export const PRODUCTS_SEARCH_PAGES= [
    {
        key: "products",
        path: '/products'
    },
    {
        key: "search",
        path: '/search'
    },
    {
        key: "category",
        path: '/category'
    },
]
export const NOTIFICATION_TYPES = {
    CHANGED_ORDER_STATUS: "CHANGED_ORDER_STATUS",
    INFORMATION: "INFORMATION"
}
