// import packages
import React, {Component} from "react";
import {Link} from "react-router-dom";
import Slider from "react-slick";

//Import styles
import "./productsSlider.scss";

//Import Components
import ProductCard from "../../components/uiElements/cards/productCard/ProductCard";
import {SliderButton} from "../uiElements/buttons/Buttons";

// Import utils

class Section extends Component {
    constructor() {
        super();
        this.state = {
            disabledPrev: true,
            disabledNext: false,
        };
    }

    componentDidMount() {
        let itemCount = this.getShowSlideCount();
        this.setState({
            ...this.state,
            disabledNext: this.props?.products?.list?.length - 1 < itemCount,
        });
    }

    getShowSlideCount = () => {
        let width = window.innerWidth;
        if (width > 1260) return 4;
        else if (width > 1000) return 3;
        else if (width > 700) return 2;
        else if (width > 500) return 1;
        else return 1;
    };

    afterSlideChange = (next) => {
        let itemCount = this.getShowSlideCount();
        this.setState({
            disabledPrev: next === 0,
            disabledNext: next + itemCount === this.props.products?.length - 1,
        });
    };

    render() {
        const {products, title, id} = this.props;
        const {disabledPrev, disabledNext} = this.state;
        const settings = {
            dots: false,
            arrows: false,
            infinite: false,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 1,
            centerMode: false,
            ref: section => (this.section = section),
            beforeChange: (current, next) => this.afterSlideChange(next),
            responsive: [
                {
                    breakpoint: 1260,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 1000,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 1,
                    }
                },
            ]
        };

        return !!products?.length && <div className="section-wrapper">
            <div className="section-header">
                <Link to={{
                    pathname: `/section-details/${id}`,
                    state: {title}
                }}>
                    <h2 className="section-title">{title}</h2>
                </Link>
                {
                    products?.length > this.getShowSlideCount() &&
                    <SliderButton
                        className={"with-header"}
                        disabledPrev={disabledPrev}
                        disabledNext={disabledNext}
                        cbPrev={() => this.section.slickPrev()}
                        cbNext={() => this.section.slickNext()}
                    />
                }
            </div>
            <div className="section-list-wrapper">
                {
                    <Slider {...settings}>
                        {products?.map(item => {
                            return <ProductCard gridView={true} data={item} key={item.id}/>
                        })}
                    </Slider>
                }
            </div>
        </div>
    }
}


export default Section;
