import React, { Component } from "react";
import { connect } from "react-redux";
import { Collapse, Checkbox, Slider, InputNumber } from "antd";

//import assets
import './filters.scss'
import { RightArrowIcon } from "../../../assets/images";

// Import utils
import { getPropsFromState } from "../../../redux/mapStateToProps";
import { MAX_PRICE, MIN_PRICE } from "../../../constants/acceptedConsts";
import { CURRENCIES_TYPES } from "../../../constants/constTypes";
import typeOf from "validator/es/lib/util/typeOf";

const { Panel } = Collapse;

class Filters extends Component {

    constructor (props) {
        super(props);
        this.state = {
            minPrice: MIN_PRICE,
            maxPrice: MAX_PRICE,
        };
    }

    componentDidMount () {
        const { minPrice, maxPrice, } = this.props;
        // console.log('mount', minPrice, maxPrice)
        this.setState({
            minPrice: minPrice || MIN_PRICE,
            maxPrice: maxPrice || MAX_PRICE,
        })
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        const { minPrice, maxPrice, } = this.props;
        if (minPrice === MIN_PRICE && maxPrice === MAX_PRICE
            && (this.state.minPrice !== MIN_PRICE || this.state.maxPrice !== MAX_PRICE)
            && (prevProps.minPrice !== MIN_PRICE || prevProps.maxPrice !== MAX_PRICE)
        ) {
            // console.log('set default values')
            // console.log('a', minPrice, maxPrice)
            // console.log('b', prevProps.minPrice, prevProps.maxPrice)
            // console.log('c', this.state.minPrice, this.state.maxPrice)
            this.setState({
                    minPrice: MIN_PRICE,
                    maxPrice: MAX_PRICE,
                },
            )
        }
    }

    onChangePrice = value => {
        this.setState({
            minPrice: value[0],
            maxPrice: value[1],
        })
    }

    onAfterChangePrice = ([min, max]) => {
        // console.log('onAfterChangePrice', min, max)
        this.setState({ minPrice: min, maxPrice: max })
        this.props.getPriceValues({
            minPrice: min,
            maxPrice: max,
        })
    };

    setMinMaxPrice (name, value) {
        if (name === "minPrice" && value > this.state.maxPrice) {
            return;
        }
        if (name === "maxPrice" && value < this.state.minPrice) {
            return;
        }
        this.setState({
            [name]: value,
        }, () => this.props.getPriceValues({
                minPrice: this.state.minPrice,
                maxPrice: this.state.maxPrice,
            }
        ))
    }

    render () {
        const { minPrice, maxPrice, } = this.state;
        const {
            filters, staticTexts, filterValues,
        } = this.props;
        return <div className="filters-wrapper">
            <div className="filters-title">{staticTexts?.filters_title}</div>
            <Collapse expandIconPosition={'right'}
                      expandIcon={(value) => {
                          return <RightArrowIcon className={value.isActive ? 'rotate' : ''}/>
                      }}>
                {
                    !!filters?.list?.length && filters?.list?.map(item => {
                        const values = item?.values?.map(value => {
                            return { label: value?.title, value: value.id }
                        });
                        return <Panel header={item?.title} key={item.id}>
                            <Checkbox.Group
                                value={filterValues[item.id] || []}
                                options={values}
                                onChange={(v) => this.props.getFilterValues(item.id, v)}/>
                        </Panel>
                    })
                }
            </Collapse>
            <Collapse expandIconPosition={'right'}
                      defaultActiveKey={['price']}
                      expandIcon={(value) => {
                          return <RightArrowIcon className={value.isActive ? 'rotate' : ''}/>
                      }}>
                <Panel header={staticTexts?.filters_price_panel_title} key={'price'}>
                    <div className={'price-filter'}>
                        <Slider range={true}
                                tooltipVisible={false}
                                min={MIN_PRICE}
                                max={MAX_PRICE}
                                value={[minPrice, maxPrice]}
                                onChange={this.onChangePrice}
                                onAfterChange={this.onAfterChangePrice}/>
                        <div className={'price-filter'}>
                            <div className={'price-filter-inputs'}>
                                {staticTexts?.filters_price_inputs_description}
                                <InputNumber
                                    min={MIN_PRICE}
                                    max={maxPrice}
                                    style={{ margin: '0 10px' }}
                                    value={minPrice}
                                    onChange={this.setMinMaxPrice.bind(this, 'minPrice')}
                                />
                                -
                                <InputNumber
                                    min={minPrice}
                                    max={MAX_PRICE}
                                    style={{ margin: '0 10px' }}
                                    value={maxPrice}
                                    onChange={this.setMinMaxPrice.bind(this, 'maxPrice')}
                                />
                                {CURRENCIES_TYPES.find(item => item.id === "AMD")?.icon}
                            </div>
                        </div>
                    </div>
                </Panel>
            </Collapse>
        </div>
    }

}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'filters',
        'currentCurrency'
    ])
};

const mapDispatchToProps = {};


export default connect(mapStateToProps, mapDispatchToProps)(Filters);
