// Import packages
import React, {useEffect, useState} from "react";
import {connect} from 'react-redux';

// Import styles
import './requestModal.scss';
import {ReactComponent as CloseIcon} from "../../../assets/images/icons/ic_close.svg";
import {LoadingOutlined} from "@ant-design/icons";
import {ReactComponent as SuccessImg} from "../../../assets/images/icons/ic_tick.svg";

// Import components
import {Modal} from 'antd';
import {LogoWhite, TickIcon} from "../../../assets/images";
import {InputGroup} from "../inputGroup/inputGroup";

//import utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import { enableScroll} from "../../../utils/toggleScrolling";
import {SendOrderRequest} from "../../../redux/actions";

function RequestModal(props) {
    const {
        visible,
        staticTexts,
        productId,
        toggleModal,
        requestLoading
    } = props;
    const [request, setRequest] = useState({
        productId : productId,
        phoneNumber : '',
        quantity : '',
    });

    const [errors, setErrors] = useState({
        phoneNumber : false,
        quantity : false,
    });

    const [tick, setTick] = useState(false);

    useEffect(()=>{
        setRequest({
            ...request,
            productId: props.productId
        })
    }, [productId])

    useEffect(() => {
        if (visible) {
            // disableScroll();
        } else {
            enableScroll()
        }
        return () => {
            enableScroll()
        }
    }, [visible])

    function getInputValues(e) {
        const {name, value} = e.target;
        const phoneRegExp = /^[+\d]\d*$/;
        if (value && !phoneRegExp.test(value)) {
            return;
        }
        setRequest({
            ...request,
            [name]: value

        })
        setErrors({
            ...errors,
            [name]: false
        })
    }

    function showTick() {
        setTimeout(
            () => {
                setTick(false)
                toggleModal();
            },
            2000
        )
    }

    function validate() {
        const errors = {};
        let result = true;
        Object.keys(request).forEach(key => {
            if (!request[key] || (key === 'phoneNumber' && request.phoneNumber?.length < 8)
                || (key === 'quantity' && request.quantity <= 0)) {
                errors[key] = true;
                result = false;
            }
        })
        setErrors(errors);
        return result;
    }

    function sendOrderRequest() {
        const {phoneNumber, productId, quantity} = request;

        if (validate()) {
            let reqData = {
                phoneNumber: '+374' + phoneNumber,
                quantity,
                productId,
            };

            props.SendOrderRequest(reqData).then(() => {
                setRequest({
                    productId : '',
                    phoneNumber : '',
                    quantity : '',
                });

                setTick(true)
                showTick()
            })
        }
    }

    return <Modal
        centered
        title={<LogoWhite className={"modal-header-logo"}/>}
        visible={visible}
        okButtonProps={false}
        onCancel={props.toggleModal}
        closeIcon={<CloseIcon className={'modal-close-icon'} title={''}/>}
    >
        <div className="info-modal-wrapper">
            <div className="info-modal-wrapper">
                <div className="request-title">
                    {staticTexts?.request_modal_title}
                </div>
                <div className="request-description">
                    {staticTexts?.request_modal_description}
                </div>
                <div className="form-wrapper">
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        name={'phoneNumber'}
                        value={request.phoneNumber}
                        error={errors.phoneNumber}
                        placeholder={staticTexts?.contacts_page_placeholder_phoneNumber}
                        maxLength={8}
                        onChange={getInputValues}
                        className={'phone-input'}
                    ><span className="phone-code">+374</span></InputGroup>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        name={'quantity'}
                        value={request.quantity}
                        error={errors.quantity}
                        placeholder={'Quantity'}
                        maxLength={20}
                        onChange={getInputValues}
                    />

                </div>
                    {/*<PrimaryButton title={'SEND'}*/}
                    {/*cb={sendOrderRequest}/>*/}
                <button className='PrimaryButton'
                        onClick={() => {
                            if (!requestLoading && sendOrderRequest) {
                                sendOrderRequest();
                            }
                        }}>
                    SEND
                    {
                        requestLoading ? <LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>
                            : <TickIcon title={''}
                                        className={`tick-icon ${tick ? 'show-tick' : 'hide-tick'}`}/>
                    }
                </button>
            </div>
        </div>
    </Modal>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    "staticTexts",
    "requestLoading",
]);

const mapDispatchToProps = {
    SendOrderRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestModal);
