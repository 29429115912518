// import packages
import React, {memo, useEffect, useState} from "react";
import {connect} from "react-redux";
import moment from "moment";

//Import assets
import "./bannerCard.scss";
import {CartIcon} from "../../../../assets/images";

// import utils
import {generateImageMediaUrl} from "../../../../utils/generateMediaUrl";
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {addProductToCart} from "../../../../redux/actions";
import {PrimaryButton} from "../../buttons/Buttons";
import {resizeImage} from "../../../../utils/resizeImage";


const BannerCard = memo((props) => {
    const {item, ref, key, userCart, setTime} = props;

    const [timer, setTimer] = useState("");
    const productInCart = userCart?.find(i => i.product?.id === item?.product?.id);

    const addToCart = () => {
        if (!productInCart) {
            props.addProductToCart(item?.product)
        }
    }
    useEffect(() => {
        let countDownDateA = moment(item?.date).format('YYYY MM DD HH:mm:ss');

        let countDownDate = new Date(countDownDateA).getTime()
        let x = setInterval(function () {

            let now = new Date().getTime();

            let distance = countDownDate - now;

            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);
            const time = distance > 0 ?  `${!!days ? days + "d." : ""}${hours < 10 ? "0" + hours : hours }:${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds} ` : ""

            setTimer(time)
            setTime(time)
            if (distance < 0) {
                clearInterval(x);
            }
        }, 1000);
    })


    const t = item?.title || ""
    const start = t.slice(0, t.indexOf("["))
    const mid = t.slice(t.indexOf("[") + 1, t.lastIndexOf("]")) || ""
    const end = t.slice(t.lastIndexOf("]") + 1)
    const titleJSX = t.indexOf("[") !== t.lastIndexOf("]") ? <>{start}<span>{mid}</span>{end}</> : t
    return <div className="banner-card-wrapper" key={key} ref={ref}>
        <div className="card-header">
            <div className="card-header-title">{titleJSX}</div>
            <div className="card-date-counter">{timer}</div>
        </div>
        <img src={generateImageMediaUrl(item?.mediaMain?.path ? item?.mediaMain?.path : item?.product?.mediaMain?.path)}
             className="card-image"
        />
       {/* <div className="banner-info-wrapper">
            <div className="banner-title">{item?.product?.title}</div>
            <div className="price-wrapper">
                <div>
                    {item?.product?.oldPrice && <div className="old-price">{item?.product?.oldPrice} ֏</div>}
                    {item?.product?.price && <div className="new-price">{item?.product?.price} ֏</div>}
                </div>
                {item?.product?.id && <PrimaryButton className={'cart-btn'}
                                cb={(e) => {
                                    e && e.preventDefault();
                                    e && e.stopPropagation();
                                    addToCart()
                                }}
                                title={<CartIcon title={''}/>}

                />}
            </div>
        </div>*/}

    </div>

})


const mapStateToProps = (state) => getPropsFromState(state, [
    'userCart',
    'staticTexts',
    'loggedInUser',
    'currentCurrency',
]);

const mapDispatchToProps = {
    addProductToCart
};

export default connect(mapStateToProps, mapDispatchToProps)(BannerCard);
