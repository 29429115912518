// import packages
import React, {useEffect} from "react";
import {connect} from "react-redux";

//Import assets
import './help.scss'
import {
    HelpFooter
} from "../../assets/images";

//Import components
import {PrimaryButton} from "../../components/uiElements/buttons/Buttons";

//Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {SendEmail, GetInformation} from "../../redux/actions";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {CustomHeader} from "../../components/uiElements/customHeader/CustomHeader";


function Help(props) {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        props.GetInformation()
    }, []);

    const {staticTexts, information} = props;
    const  headerInfo = information?.headers?.find(item => item?.slug === 'help');
    const  block_1 = information?.blocks?.find(item => item?.slug === "help-block-1");
    const  block_2 = information?.blocks?.find(item => item?.slug === "help-block-2");

    return <div className="help-page-wrapper">
        <CustomHeader image={headerInfo?.backgroundImage}
                      title={headerInfo?.title}
                      description={headerInfo?.description}/>

        <div className="help-info-section">
            <div className="image-wrapper">
                <div className="image-proportion">
                    <img src={generateImageMediaUrl(block_1?.mediaMain?.path)} alt=""/>
                </div>
            </div>
            <div className="help-info-content">
                <div className="help-info-title">{block_1?.title}</div>
                <div className="help-info-description"
                     dangerouslySetInnerHTML={{__html: block_1?.description}}/>
                <PrimaryButton title={staticTexts?.help_contact_btn} link={{
                    pathname: '/about',
                    state: {
                        goToContactPart: true
                    }
                }}/>
            </div>
        </div>

        <div className="help-info-section second">
            <div className="help-info-content">
                <div className="help-info-title">{block_2?.title}</div>
                <div className="help-info-description"
                     dangerouslySetInnerHTML={{__html: block_2?.description}}/>
                <PrimaryButton title={staticTexts?.help_project_plan_btn} link={'/project-plans'}/>
            </div>
            <div className="image-wrapper">
                <div className="image-proportion">
                    <img src={generateImageMediaUrl(block_2?.mediaMain?.path)} alt=""/>
                </div>
            </div>
        </div>

        <div className="help-footer-wrapper">
            <div className="help-footer-title">{staticTexts?.help_apps_title_first_part} <span>{staticTexts?.help_apps_title_orange_part}</span></div>
            <div className="help-footer-description">{staticTexts?.help_apps_description}</div>
            <PrimaryButton title={staticTexts?.help_apps_download_btn}/>
            <img src={HelpFooter} className="img-wrapper" alt={""}/>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'requestLoading',
        'selectedLanguage',
        'information',
    ])
};

const mapDispatchToProps = {
    SendEmail,
    GetInformation
}

export default connect(mapStateToProps, mapDispatchToProps)(Help);
