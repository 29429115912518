export const ORDERS_CONSTS = {
    GET_ORDERS: "GET_ORDERS",
    GET_SINGLE_ORDER: "GET_SINGLE_ORDER",
    CLEAR_SINGLE_ORDER: "CLEAR_SINGLE_ORDER",
    CLEAR_ORDER: "CLEAR_ORDER",
    CANCELED_ORDER: "CANCELED_ORDER",
    DELETE_ORDER: "DELETE_ORDER",
    ADD_ORDER: "ADD_ORDER",
    GET_ORDER_STATUSES: "GET_ORDER_STATUSES",
    CHECK_PROMO_CODE: "CHECK_PROMO_CODE",
};
