// import packages
import React, {Component} from "react";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";

//Import Components
import {Checkbox} from "antd";
import AuthWrapper from "../../components/auth/auth-wrapper/AuthWrapper";
import {PrimaryButton} from "../../components/uiElements/buttons/Buttons";
import {InputGroup} from "../../components/uiElements/inputGroup/inputGroup";

//Import Images
import { Logo, HideEyeIcon, EyeIcon } from "../../assets/images";

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {validateSignUpData} from "../../utils/validateData";
import {
    AddUser,
    toggleNotificationRow
} from "../../redux/actions";

import { NOTIFICATION_ROW_MODES, USER_ERROR_TYPES } from "../../constants/constTypes";
import {history} from "../../configs/history";

class SignUp extends Component {

    constructor() {
        super();
        this.state = {
            signUpData: {
                firstName: '',
                lastName: '',
                username: '',
                password: '',
                repeatPassword: '',
            },
            errors: {
                firstName: false,
                lastName: false,
                username: false,
                password: false,
                repeatPassword: false,
                privacyPolicy: false,
            },
            repeatPasswordVisible: false,
            passwordVisible: false,
            privacyPolicy: true,
        };
        this.signUpUser = this.signUpUser.bind(this);
        this.getInputValues = this.getInputValues.bind(this)
    }

    changePassVisibility(type) {
        this.setState({
            [type]: !this.state[type]
        })
    }

    getInputValues(e) {
        const {name, value} = e.target;
        this.setState({
            signUpData: {
                ...this.state.signUpData,
                [name]: value.trimStart(),
            }
        })
        if (this.state.errors[name]) {
            this.setState({
                errors: {
                    ...this.state.errors,
                    [name]: false
                }
            })
        }
    }

    signUpUser() {
        const {staticTexts} = this.props;
        const {signUpData, privacyPolicy} = this.state;
        let errors = {
            firstName: false,
            lastName: false,
            username: false,
            password: false,
            repeatPassword: false,
        };
        this.setState({
            errors: {...errors}
        });
        const validationResult = validateSignUpData(signUpData, errors);
        if (validationResult && privacyPolicy) {
            let reqData = {...signUpData};
            delete reqData['repeatPassword'];
            this.props.AddUser(reqData).then((res) => {
                history.push({
                    pathname: '/user/confirm-email',
                    state: {
                        redirectedFromSignUp: true,
                        username: signUpData.username
                    }
                });
                let successMessage = staticTexts?.sign_up_success;
                successMessage = successMessage?.replace('%d', signUpData.username);
                this.props.toggleNotificationRow({
                    visible: true,
                    text: successMessage,
                    disappear: 5000,
                    mode: NOTIFICATION_ROW_MODES.SUCCESS
                });
            }).catch((error) => {
                if (error && error.response?.data && error.response?.data.slug === USER_ERROR_TYPES.USER_ALREADY_EXISTS) {
                    this.props.toggleNotificationRow({
                        visible: true,
                        text: staticTexts?.sign_up_error_exist_username,
                        disappear: true,
                        mode: NOTIFICATION_ROW_MODES.ERROR
                    })
                }
            });
        } else {
            this.setState({
                errors: {
                    ...errors,
                    privacyPolicy: !privacyPolicy
                },
            });
        }
    }

    render() {
        const {requestLoading, staticTexts} = this.props;
        const {signUpData, errors, privacyPolicy, passwordVisible, repeatPasswordVisible} = this.state;

        return <AuthWrapper>
            <div className="form-content sign-up">
                <Link to={'/'} className='form-logo'>
                    <Logo/>
                </Link>
                <div className="inner-part">
                    <div className="form-title">{staticTexts?.sign_up_form_title}</div>
                    <div className="form-sub-title">
                        {staticTexts?.sign_up_header_text_for_signUp}
                    </div>
                    <InputGroup
                        inputType={'input'}
                        type={'text'}
                        placeholder={staticTexts?.sign_up_placeholder_firstName}
                        name={'firstName'}
                        value={signUpData.firstName}
                        maxLength={50}
                        error={errors.firstName}
                        onChange={this.getInputValues}
                    />
                    <InputGroup
                        inputType={'input'}
                        type={'text'}
                        placeholder={staticTexts?.sign_up_placeholder_lastName}
                        name={'lastName'}
                        value={signUpData.lastName}
                        maxLength={50}
                        error={errors.lastName}
                        onChange={this.getInputValues}
                    />
                    <InputGroup
                        inputType={'input'}
                        type={'text'}
                        placeholder={staticTexts?.sign_up_placeholder_email}
                        name={'username'}
                        value={signUpData.username}
                        maxLength={50}
                        error={errors.username}
                        onChange={this.getInputValues}
                    />
                    <InputGroup
                        inputType={'input'}
                        type={`${passwordVisible ? "text" : "password"}`}
                        placeholder={staticTexts?.sign_up_placeholder_password}
                        autocomplete={'new-password'}
                        name={'password'}
                        value={signUpData.password}
                        maxLength={50}
                        error={errors.password}
                        onChange={this.getInputValues}>
                    <span className={'visibility-icon'}
                          onClick={this.changePassVisibility.bind(this, 'passwordVisible')}>
                                                      {passwordVisible ? <HideEyeIcon title={''}/> :
                                                          <EyeIcon title={''}/>}

                       </span>
                    </InputGroup>
                    <InputGroup
                        inputType={'input'}
                        type={`${repeatPasswordVisible ? "text" : "password"}`}
                        placeholder={staticTexts?.sign_up_placeholder_repeat_password}
                        name={'repeatPassword'}
                        value={signUpData.repeatPassword}
                        maxLength={50}
                        error={errors.repeatPassword}
                        onChange={this.getInputValues}>
                    <span className={'visibility-icon'}
                          onClick={this.changePassVisibility.bind(this, 'repeatPasswordVisible')}>
                                                      {repeatPasswordVisible ? <HideEyeIcon title={''}/> :
                                                          <EyeIcon title={''}/>}

                       </span>
                    </InputGroup>
                    <div className="accept-privacy-policy">
                        <Checkbox
                            className={`privacy-policy-checkbox ${errors.privacyPolicy ? 'error' : ''}`}
                            checked={privacyPolicy}
                            onChange={() => this.setState({privacyPolicy: !privacyPolicy})}/>
                        <div className={'privacy-policy-text'}>
                            <Link to={'/privacy-policy'}
                                  className="privacy-policy-link">
                                {staticTexts?.sign_up_agreement_text}
                            </Link>
                        </div>
                    </div>
                    <PrimaryButton
                        title={staticTexts?.sign_up_btn_title_register}
                        className='sign-up-btn'
                        cb={this.signUpUser}
                        loading={requestLoading}
                        loadingText={staticTexts?.btn_loading_text}
                    />
                </div>
                <Link to={'/sign-in'} className="under-form-text">
                    {staticTexts?.sign_up_already_have_account}
                    <span>
                        {staticTexts?.sign_up_navigate_to_account}
                    </span>
                </Link>
            </div>
        </AuthWrapper>
    }
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    AddUser,
    toggleNotificationRow
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
