// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Link} from 'react-router-dom'
import {Collapse, Dropdown, Menu} from 'antd';
import {isEmail} from "validator";

// Import assets
import './footer.scss'
import {
    CollapseIcon,
    CollapseLangIcon,
    CompanyIcon,
    FbIcon,
    InstaIcon, LinkedinIcon,
    Logo, TickIcon, TwitterIcon, UserIcon
} from "../../assets/images";
import {LoadingOutlined} from "@ant-design/icons";

// Import components
import {InputGroup} from "../uiElements/inputGroup/inputGroup";
import {SendSubscribe} from "../../redux/actions";

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {ChangeLanguage} from "../../redux/actions";
import {generateFileMediaUrl, generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {resizeImage} from "../../utils/resizeImage";

const {Panel} = Collapse;

function Footer(props) {
    const [tick, setTick] = useState(false)
    const [email, setEmail] = useState('')
    const [errorEmail, setErrorEmail] = useState(false)

    const showTick = () => {
        setTimeout(
            () => {
                setTick(false)
                setEmail("")
                setErrorEmail(false)
            },
            2000,
        )

    }

    function getInputValues(e) {
        setErrorEmail(false)
        const {name, value} = e.target;
        setEmail(value)
    }

    function addSubscribers() {
        if (email && isEmail(email)) {
            props.SendSubscribe({email: email})
                .then(() => {
                    setEmail(email)
                    setTick(true)
                    showTick()
                }, errorEmail => setErrorEmail(!errorEmail))
        } else {
            setErrorEmail(true)
        }
    }

    const {languages, selectedLanguage, contacts, staticTexts, requestLoading, isLoggedIn} = props

    const menuLanguage = !!languages?.length && (
        <Menu className="languages-dropdown">
            {!!languages?.length && languages?.map((item) => {
                return selectedLanguage?.code !== item.code &&
                    <Menu.Item key={item.id}
                               onClick={() => {
                                   props.ChangeLanguage(item)
                               }}
                               className={'language-dropdown-item'}>
                        <img src={generateFileMediaUrl(item?.icon?.path)} alt=""/>
                        <div>{item.code}</div>
                    </Menu.Item>
            })
            }
        </Menu>
    );

    return <footer className='footerWrapper'>
        <div className="top-side desktop">
            <div className="col large-wrapper">
                <div className="logo-wrapper">
                    <Link to={'/'}><Logo/></Link>
                </div>
                <div className="footer-middle-section">
                    <div className="section-title">{staticTexts?.footer_address_title}</div>
                    <div className="section-description">{contacts?.address}</div>
                </div>
                <Dropdown overlay={menuLanguage}
                          trigger={['click']}
                          overlayClassName={'languages-wrapper'}
                          placement="bottomRight"
                          size={'small'}>
                    <div className="dropdown-menu-item">
                        {selectedLanguage?.icon &&
                        <img src={generateFileMediaUrl(selectedLanguage?.icon?.path)} alt=""/>}
                        <div>{selectedLanguage?.code}</div>
                        <CollapseLangIcon className={'arrow-icon'} title={''}/>
                    </div>
                </Dropdown>
            </div>
            <div className="col">
                <div className="title">{staticTexts?.footer_company_section_title}</div>
                {/*<Link to={`/`}>*/}
                {/*    <p>{staticTexts?.nav_item_home}</p>*/}
                {/*</Link>*/}
                <Link to={`/shipping`}>
                    <p>{staticTexts?.nav_item_shipping}</p>
                </Link>
                <Link to={{pathname: `/about`, state: {goToContactPart: true}}}>
                    <p>{staticTexts?.nav_item_contact_us}</p>
                </Link>
                <Link to={`/privacy-policy`}>
                    <p>{staticTexts?.nav_item_privacy_policy}</p>
                </Link>
                {isLoggedIn ?
                    <Link to={'/profile/settings'}>
                        <p>{staticTexts?.nav_item_profile}</p>
                    </Link> :
                    <Link to={`/sign-in`}>
                        <p>{staticTexts?.nav_item_sign_in}</p>
                    </Link>}
            </div>
            <div className="col">
                <div className="title">{staticTexts?.footer_support_section_title}</div>
                <Link to={`/about`}>
                    <p>{staticTexts?.nav_item_about_us}</p>
                </Link>
                <Link to={{pathname: `/about`, state: {goToContactPart: true}}}>
                    <p>{staticTexts?.nav_item_contact_us}</p>
                </Link>
                <Link to={`/blogs`}>
                    <p>{staticTexts?.nav_item_blog}</p>
                </Link>
                <Link to={`/help`}>
                    <p>{staticTexts?.nav_item_help}</p>
                </Link>
            </div>
            <div className="col">
                <div className="title">{staticTexts?.footer_shoppers_section_title}</div>
                <Link to={`/markets`}>
                    <p>{staticTexts?.nav_item_mini_markets}</p>
                </Link>
                <Link to={`/project-plans`}>
                    <p>{staticTexts?.nav_item_project_plan}</p>
                </Link>
                <Link to={`/products`}>
                    <p>{staticTexts?.nav_item_products}</p>
                </Link>
                <Link to={`/apps`}>
                    <p>{staticTexts?.nav_item_apps}</p>
                </Link>
            </div>
            <div className="col large-wrapper">
                <div className="social-wrapper">
                    <span className="social-title">{staticTexts?.footer_social_items_title}</span>
                    <div className="social-content">
                        {
                            !!contacts?.socials?.length && contacts?.socials?.map((item, index) => {
                                return <a href={item?.url} rel="noreferrer" target={'_blank'} key={index}>
                                    <img src={generateImageMediaUrl(item?.icon?.path)} alt=""/>
                                </a>
                            })
                        }
                    </div>
                </div>
                <div className="footer-middle-section">
                    <div className="section-title">{staticTexts?.footer_subscribe_title}</div>
                    <div className="section-description">{staticTexts?.footer_subscribe_description}</div>
                </div>
                <div className="contact-wrapper">
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        value={email}
                        error={errorEmail}
                        name={'email'}
                        placeholder={staticTexts?.footer_subscribe_placeholder}
                        maxLength={100}
                        onChange={getInputValues}
                    />
                    <button className='PrimaryButton'
                            onClick={() => {
                                if (!requestLoading && addSubscribers) {
                                    addSubscribers();
                                }
                            }}
                    >
                        {staticTexts?.footer_subscribe_btn}
                        {
                            requestLoading ? <LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>
                                : <TickIcon title={''} className={`tick-icon ${tick ? 'show-tick' : 'hide-tick'}`}/>
                        }

                    </button>
                </div>
            </div>
        </div>
        <div className="top-side mobile">
            <div className="logo-wrapper">
                <Logo/>
            </div>
            <Collapse accordion
                      expandIconPosition={'right'}
                      expandIcon={(value) => {
                          return <CollapseIcon className={value.isActive ? 'rotate' : ''}/>
                      }}>
                <Panel header={staticTexts?.footer_company_section_title} key="1">
                    <Link to={`/`}>
                        <p>{staticTexts?.nav_item_home}</p>
                    </Link>
                    <Link to={`/shipping`}>
                        <p>{staticTexts?.nav_item_shipping}</p>
                    </Link>
                    <Link to={`/about`}>
                        <p>{staticTexts?.nav_item_contact_us}</p>
                    </Link>
                    <Link to={`/privacy-policy`}>
                        <p>{staticTexts?.nav_item_privacy_policy}</p>
                    </Link>
                    {isLoggedIn ?
                        <Link to={'/profile/settings'}>
                            <p>{staticTexts?.nav_item_profile}</p>
                        </Link> :
                        <Link to={`/sign-in`}>
                            <p>{staticTexts?.nav_item_sign_in}</p>
                        </Link>}
                </Panel>
                <Panel header={staticTexts?.footer_support_section_title} key="2">
                    <Link to={`/about`}>
                        <p>{staticTexts?.nav_item_about_us}</p>
                    </Link>
                    <Link to={`/about`}>
                        <p>{staticTexts?.nav_item_contact_us}</p>
                    </Link>
                    <Link to={`/blogs`}>
                        <p>{staticTexts?.nav_item_blog}</p>
                    </Link>
                    <Link to={`/help`}>
                        <p>{staticTexts?.nav_item_help}</p>
                    </Link>
                </Panel>
                <Panel header={staticTexts?.footer_shoppers_section_title} key="3">
                    <Link to={`/markets`}>
                        <p>{staticTexts?.nav_item_mini_markets}</p>
                    </Link>
                    <Link to={`/project-plans`}>
                        <p>{staticTexts?.nav_item_project_plan}</p>
                    </Link>
                    <Link to={`/products`}>
                        <p>{staticTexts?.nav_item_products}</p>
                    </Link>
                    <Link to={`/apps`}>
                        <p>{staticTexts?.nav_item_apps}</p>
                    </Link>
                </Panel>
                <Panel header={"Contact"} key="4">
                    <p>Address: {contacts?.address}</p>
                    <p>Email: {contacts?.email}</p>
                </Panel>
            </Collapse>
            <div className={'top-side-bottom-wrapper'}>
                <div className="social-wrapper">
                    <span className="social-title">{staticTexts?.footer_social_items_title}</span>
                    <div className="social-content">
                        {
                            contacts?.socials?.length && contacts?.socials?.map((item, index) => {
                               return <a href={item?.url} target={'_blank'} key={index}>
                                   <img src={generateImageMediaUrl(item?.icon?.path)} alt=""/>
                               </a>
                            })
                        }
                    </div>
                </div>
                <Dropdown overlay={menuLanguage}
                          trigger={['click']}
                          overlayClassName={'languages-wrapper'}
                          placement="topRight"
                          size={'small'}>
                    <div className="dropdown-menu-item">
                        {selectedLanguage?.icon &&
                        <img src={generateFileMediaUrl(selectedLanguage?.icon?.path)} alt=""/>}
                        <div>{selectedLanguage?.code}</div>
                        <CollapseLangIcon className={'arrow-icon'} title={''}/>
                    </div>
                </Dropdown>
            </div>

        </div>
        <div className="bottom-side">
            <div className="bottom-side-main-content">
                <div>{staticTexts?.footer_section_copyright_text}</div>
                <div className='developed-by'>{staticTexts?.footer_section_developed_by_text}
                    <a href="https://4steps.am/" target="_blank"><CompanyIcon/></a>
                </div>
            </div>
        </div>
    </footer>

}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'languages',
        'selectedLanguage',
        'staticTexts',
        'contacts',
        'requestLoading',
        'isLoggedIn',
    ])
};

const mapDispatchToProps = {
    ChangeLanguage,
    SendSubscribe
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
