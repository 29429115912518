import {UTIL_CONSTS} from "../constants";
import {store} from "../store";

export const toggleNotificationRow = (data) => {
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.TOGGLE_NOTIFICATION_ROW,
            payload: data
        })
    }
};

export const toggleLinearLoading = (state) => {
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.TOGGLE_LINEAR_LOADING,
            payload: state
        })
    }
};


export const SetMetaData = (data) => {
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.SET_META_DATA,
            payload: data
        })
    }
};
export const ClearMetaData = () => {
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.CLEAR_META_DATA,
        })
    }
};

export const toggleProductView = (state) => {
    store.dispatch({
        type: UTIL_CONSTS.TOGGLE_PRODUCT_CARD_VIEW,
        payload: state
    })
};
