import { _hostMedia } from "../redux/api";
import defaultFileLogo from '../assets/images/default_img.jpg';
import defaultUserLogo from '../assets/images/avatar.jpg';

export function generateImageMediaUrl (path, type) {
    if (path && path?.startsWith('http')) {
        return path;
    }
    let defaultLogo = defaultFileLogo;
    if (type === 'user') {
        defaultLogo = defaultUserLogo;
    }
    return path ? `${_hostMedia}/images${path}` : defaultLogo
}

export function generateFileMediaUrl (path) {
    if (path?.startsWith('http')) {
        return path;
    }
    return path ? `${_hostMedia}/files${path}` : defaultFileLogo;
}
