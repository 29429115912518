import {USER_CONSTS, UTIL_CONSTS} from "../constants";
import {_urlMedia, _urlSubscribe, _urlUsers, request} from "../api";

export const AddUser = userData => {
    const requestData = {
        url: _urlUsers,
        method: "POST",
        data: userData,
    };
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        return request(requestData).finally(() => {
            dispatch({
                type: UTIL_CONSTS.END_LOADING
            });
        })
    };
};

export const ActivateUser = activationData => {
    const requestData = {
        url: `${_urlUsers}/activation`,
        method: "POST",
        data: activationData,
    };

    return async dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        return await request(requestData).finally(() => {
            dispatch({
                type: UTIL_CONSTS.END_LOADING
            });
        })
    };
};


export const ResendActivation = username => {
    const requestData = {
        url: `${_urlUsers}/resend-activation`,
        method: "POST",
        data: {
            "username": username
        },
    };
    return request(requestData);
};

export const CheckForgotPasswordCode = reqData => {
    const requestData = {
        url: `${_urlUsers}/check-reset-password-code`,
        method: "POST",
        data: reqData
    };
    return async dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        return await request(requestData).finally(() => {
            dispatch({
                type: UTIL_CONSTS.END_LOADING
            });
        })
    };

};

export const CheckChangeEmailCode = reqData => {
    const requestData = {
        url: `${_urlUsers}/change-username`,
        method: "POST",
        data: reqData,
        token: true
    };
    return async dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        return await request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                data && await dispatch({
                    type: USER_CONSTS.UPDATE_CURRENT_USER,
                    payload: data
                });
            }).finally(() => {
            dispatch({
                type: UTIL_CONSTS.END_LOADING
            });
        })
    };

};


export const GetCurrentUser = () => {
    const requestData = {
        url: `${_urlUsers}/current`,
        languageFlag: true,
        method: "Get",
        token: true,
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const user = res && res.data;
                // console.log(user, 'USER');
                if (user) {
                    await dispatch({
                        type: USER_CONSTS.GET_CURRENT_USER,
                        payload: user
                    });
                }
            }).catch(() => {
            })
    }
};

export const UpdateCurrentUser = userData => {
    const requestData = {
        url: `${_urlUsers}/current`,
        method: "PATCH",
        token: true,
        languageFlag: true,
        data: userData
    };

    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                data && await dispatch({
                    type: USER_CONSTS.UPDATE_CURRENT_USER,
                    payload: data
                });
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                });
            })
    }
};

export const SearchUser = searchData => {
    let requestUrl = `${_urlUsers}/search`;

    Object.keys(searchData).forEach((data, index) => {
        requestUrl += `${index > 0 ? "&" : "?"}${[data]}=${searchData[data]}`
    });

    const requestData = {
        url: requestUrl,
        token: true,
    };

    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: USER_CONSTS.SEARCH_USERS,
                    payload: data
                })
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                });
            })
    }
};

export const GetUserViaId = id => {
    const requestData = {
        url: `${_urlUsers}/${id}`,
        token: true,
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: USER_CONSTS.GET_SINGLE_USER,
                    payload: data
                })
            })
    };
};


export const ChangeUserEmail = username => {
    const requestData = {
        url: `${_urlUsers}/send-change-username`,
        method: "POST",
        token: true,
        data: {
            "username": username
        },
    };
    return async dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        return await request(requestData).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                });
            })
    };
};

export const ForgotUserPassword = username => {
    const requestData = {
        url: `${_urlUsers}/forgot-password`,
        method: "POST",
        data: {
            "username": username
        },
    };
    return async dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        return await request(requestData).finally(() => {
            dispatch({
                type: UTIL_CONSTS.END_LOADING
            });
        })
    };
};

export const ResetCurrentUserPassword = resetData => {
    const requestData = {
        url: `${_urlUsers}/reset-password`,
        method: "POST",
        data: resetData,
    };

    return async dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        return await request(requestData).finally(() => {
            dispatch({
                type: UTIL_CONSTS.END_LOADING
            });
        })
    };
};


export const UpdateCurrentUserPassword = updatedData => {
    const requestData = {
        url: `${_urlUsers}/current/password`,
        method: "PUT",
        token: true,
        data: updatedData
    };

    return dispatch => {
        return request(requestData)
    }
};

export const UploadUserProfilePicture = (formData, fileType) => {
    const requestData = {
        url: `${_urlMedia}/${fileType}`,
        token: true,
        method: "POST",
        data: formData,
        customHeaders: {
            "Content-Type": "multipart/form-data",
            scope: "access:member",
        }
    };

    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        return request(requestData)
            .then(res => res?.data)
            .catch(() => null).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                });
            });
    }
};

export const SendSubscribe = (data) => {
    const requestData = {
        url: `${_urlSubscribe}`,
        method: "POST",
        data
    };
    return dispatch => {
        // dispatch({type:UTIL_CONSTS.START_LOADING});
        return request(requestData).finally(() => {
            // dispatch({type:UTIL_CONSTS.END_LOADING})
        })
    }
};
