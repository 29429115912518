import {_urlOrders, _urlOrderStatuses, _urlPayment, _urlRequest, request} from "../api";
import {ORDERS_CONSTS, UTIL_CONSTS} from "../constants";

export const GetOrders = ({
                              offset = 0,
                              limit = 20,
                              reset = true,
                              status = '',
                          } = {}) => {

    const requestData = {
        url: `${_urlOrders}?offset=${offset}&limit=${limit}`,
        method: "GET",
        languageFlag: true,
        token: true
    };
    status && (requestData.url += `&status=${status}`);

    return dispatch => {
        dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                // console.log(data, "DATA");
                data && dispatch({
                    type: ORDERS_CONSTS.GET_ORDERS,
                    payload: {
                        data: data,
                        reset,
                        status,
                        hasMore: data?.length === limit
                    }
                });
                dispatch({type: UTIL_CONSTS.END_LOADING});
            })
            .catch(err => {
            })
    };
};

export const GetSingleOrder = (id) => {
    const requestData = {
        url: `${_urlOrders}/${id}`,
        method: "GET",
        languageFlag: true,
        token: true
    };

    return dispatch => {
        dispatch({ type: UTIL_CONSTS.START_LOADING });
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: ORDERS_CONSTS.GET_SINGLE_ORDER,
                    payload: data
                });
                dispatch({ type: UTIL_CONSTS.END_LOADING });
                return data
            })
    };
};

export const ClearSingleOrder = () => {
    return async dispatch => {
        await dispatch({
            type: ORDERS_CONSTS.CLEAR_SINGLE_ORDER
        })
    };
};

export const AddOrder = (data) => {
    const requestData = {
        url: `${_urlOrders}`,
        method: "POST",
        data,
        token: true,
        languageFlag: true,
    };
    return dispatch => {
        return request(requestData)
    };
};

export const CanceledOrder = (id) => {
    const requestData = {
        url: `${_urlOrders}/${id}/cancel`,
        method: "PUT",
        token: true,
    };
    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: ORDERS_CONSTS.CANCELED_ORDER,
                    payload: id
                })
            })
    };
};

export const DeleteOrder = (id, status) => {
    const requestData = {
        url: `${_urlOrders}/${id}`,
        method: "DELETE",
        token: true,
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: ORDERS_CONSTS.DELETE_ORDER,
                    payload: {
                        id,
                        status
                    }
                })
            })
    };
};

export const GetOrderStatuses = () => {
    const requestData = {
        url: `${_urlOrderStatuses}`,
        method: "GET",
        languageFlag: true,
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: ORDERS_CONSTS.GET_ORDER_STATUSES,
                    payload: data
                })
            })
    };
};

export const SendOrderRequest = (data) => {
    const requestData = {
        url: `${_urlRequest}`,
        method: "POST",
        token: true,
        data
    };
    return dispatch => {
        dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData).finally(() => {
            dispatch({type: UTIL_CONSTS.END_LOADING})
        })
    }
};



export const OrderPaymentCallback = (orderId) => {
    console.log('_urlPayment',_urlPayment)
    const requestData = {
        url: `${_urlPayment}/callback?orderId=${orderId}`,
        method: "GET",
        token: true,
    };
    console.log('requesturl',requestData.url)
    return dispatch => {
        return request(requestData)
    }
};


