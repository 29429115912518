// import packages
import React, {Component} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {Tabs} from 'antd';
import moment from "moment";
import InfiniteScroll from 'react-infinite-scroller';

//Import assets
import './blog.scss'
import {ArrowIcon} from "../../assets/images";

//Import components
import {CustomHeader} from "../../components/uiElements/customHeader/CustomHeader";

//Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetBlogTypes, GetBlogs} from "../../redux/actions";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";


const {TabPane} = Tabs;

class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: undefined,
            blogData: {},
        };
        this.img = React.createRef();
        this.changeTab = this.changeTab.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        this.props.GetBlogTypes()
        this.props.GetBlogs();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedLanguage} = this.props;

        if (selectedLanguage && prevProps.selectedLanguage && selectedLanguage.code !== prevProps.selectedLanguage.code) {
            this.props.GetBlogTypes();
            this.props.GetBlogs();
        }
    }

    changeTab(key) {
        const {blogs} = this.props;

        if (key !== 'all') {
            this.setState({
                activeTab: key
            })
            const isGot =  Object.keys(blogs).find(item => item === key)

            !isGot && this.props.GetBlogs({
                        badge: key,
                    });
        }
    }

    loadMoreItems() {
        const {blogs} = this.props;

        const {activeTab} = this.state;

        blogs[activeTab ? activeTab : 'all']?.hasMore && this.props.GetBlogs({
            reset: false,
            offset: blogs[activeTab ? activeTab : 'all']?.itemsList?.length,
            badge: activeTab,
        });
    };

    render() {
        const {staticTexts, blogTypes, blogs, information} = this.props;
        const  headerInfo = information?.headers?.find(item => item?.slug === 'blog');

        return <div className="blog-page-wrapper">
            <CustomHeader image={headerInfo?.backgroundImage}
                          title={headerInfo?.title}
                          description={headerInfo?.description}/>

            <Tabs defaultActiveKey="1" onChange={this.changeTab}>
                <TabPane tab={staticTexts?.blog_tabs_tab_all} key="all">
                    {
                        !!blogs?.all?.itemsList?.length &&
                        <InfiniteScroll
                            hasMore={blogs?.['all']?.hasMore}
                            loadMore={this.loadMoreItems}
                            className={'blog-list'}
                            pageStart={0}
                            useWindow={true}
                            initialLoad={false}>
                            {blogs?.['all']?.itemsList.map((item) => {
                                return <Link className="blog-card"
                                             key={item?.id}
                                             to={{
                                                 pathname: `/blog/${item?.slug}`,
                                                 state: {
                                                     data: item,
                                                 }
                                             }}>
                                    <div className="image-wrapper">
                                        <div className="image-proportion">
                                            <div className="badge"
                                                 style={{backgroundColor: item?.badge?.color}}>{item?.badge?.title}</div>

                                            <img src={generateImageMediaUrl(item?.mediaMain?.path)}
                                                 alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}
                                                 title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}/>
                                        </div>
                                    </div>
                                    <div className="date-part">
                                        <div className="date">{moment(item.updatedAt).format("DD.MM.YYYY")}</div>
                                        <Link to={{
                                            pathname: `/blog/${item?.slug}`,
                                            state: {data: item}
                                        }}>
                                            <div className="details-btn">
                                                <ArrowIcon title={''}/>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="item-info-title">{item?.title}</div>
                                </Link>

                            })}
                        </InfiniteScroll>
                    }
                </TabPane>
                {
                    !!blogTypes?.length && blogTypes.map(item => {
                        return <TabPane tab={item?.title} key={item?.id}>
                            <InfiniteScroll
                                hasMore={blogs[item?.id]?.hasMore}
                                loadMore={this.loadMoreItems}
                                className={'blog-list'}
                                pageStart={0}
                                useWindow={true}
                                initialLoad={false}>
                                {blogs[item?.id] && !!blogs[item?.id]?.itemsList?.length && blogs[item?.id]?.itemsList.map((item) => {
                                    return <div className="blog-card" key={item?.id}>
                                        <div className="image-wrapper">
                                            <div className="image-proportion">
                                                <div className="badge"
                                                     style={{backgroundColor: item?.badge?.color}}>{item?.badge?.title}</div>
                                                <img src={generateImageMediaUrl(item?.mediaMain?.path)}
                                                     title={item?.mediaMain?.title ? item?.mediaMain?.title : ''}
                                                     alt={item?.mediaMain?.altAttribute ? item?.mediaMain?.altAttribute : ''}/>
                                            </div>
                                        </div>
                                        <div className="date-part">
                                            <div className="date">{moment(item.updatedAt).format("DD.MM.YYYY")}</div>
                                            <Link to={{
                                                pathname: `/blog/${item?.slug}`,
                                                state: {
                                                    data: item,
                                                }
                                            }}>
                                                <div className="details-btn">
                                                    <ArrowIcon title={''}/>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="item-info-title">{item?.title}</div>
                                    </div>

                                })}
                            </InfiniteScroll>
                        </TabPane>
                    })
                }
            </Tabs>
        </div>
    }
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'selectedLanguage',
        'staticTexts',
        'blogTypes',
        'blogPage',
        'blogs',
        'information',
    ])
};
const mapDispatchToProps = {
    GetBlogTypes,
    GetBlogs,
};

export default connect(mapStateToProps, mapDispatchToProps)(Blog);