// import packages
import React, {Component} from "react";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {history} from "../../configs/history";
import isEmail from "validator/es/lib/isEmail";

//Import Components
import AuthWrapper from "../../components/auth/auth-wrapper/AuthWrapper";
import {PrimaryButton} from "../../components/uiElements/buttons/Buttons";
import {InputGroup} from "../../components/uiElements/inputGroup/inputGroup";

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {ChangeUserEmail, ForgotUserPassword, toggleNotificationRow} from "../../redux/actions";
import {NOTIFICATION_ROW_MODES, USER_ERROR_TYPES} from "../../constants/constTypes";
import {Logo} from "../../assets/images";


class ForgotPassword extends Component {
    constructor() {
        super();
        this.state = {
            passwordData: {
                username: '',
            },
            errors: {
                username: false,
            },
            canSendEmail: true
        };

        this.getInputValues = this.getInputValues.bind(this);
        this.forgotPassword = this.forgotPassword.bind(this);
    }

    componentDidMount() {
        const username = this.props?.location?.state?.username;
        if (username) {
            this.setState({
                passwordData: {
                    username,
                },
            });
            history.replace();
        }
    }

    getInputValues(e) {
        const {name, value} = e.target;
        this.setState({
            passwordData: {
                ...this.state.passwordData,
                [name]: value.trimStart(),
            }
        })
    }

    forgotPassword() {
        const {staticTexts} = this.props;
        const {passwordData} = this.state;
        const {username} = this.state.passwordData;
        const changeEmail = this.props?.location?.state?.changeEmail;
        if (!username || !isEmail(username)) {
            this.setState({
                errors: {
                    username: true,
                },
            });
            return;
        }
        if (changeEmail) {
            this.props.ChangeUserEmail(username).then(() => {
                history.push({
                    pathname: '/user/confirm-email',
                    state: {
                        redirectedFromChangeEmail: true,
                        username: passwordData.username
                    }
                });
                let successMessage = staticTexts?.forgot_email_send_success;
                successMessage = successMessage?.replace('%d', username);
                this.props.toggleNotificationRow({
                    visible: true,
                    text: successMessage,
                    disappear: true,
                    mode: NOTIFICATION_ROW_MODES.SUCCESS
                });
            }).catch((error) => {
                let errorMessage = staticTexts?.notification_default_error;
                if (error && error.response && error.response.data.slug === USER_ERROR_TYPES.USER_ALREADY_EXISTS) {
                    errorMessage = staticTexts?.forgot_user_already_exist_error?.replace('%d', username);
                }
                this.props.toggleNotificationRow({
                    visible: true,
                    text: errorMessage,
                    disappear: true,
                    mode: NOTIFICATION_ROW_MODES.ERROR
                });
            })
        } else {
            this.props.ForgotUserPassword(username).then(() => {
                history.push({
                    pathname: '/user/confirm-email',
                    state: {
                        redirectedFromForgotPassword: true,
                        username: passwordData.username
                    }
                });
                let successMessage = staticTexts?.forgot_email_send_success;
                successMessage = successMessage?.replace('%d', username);
                this.props.toggleNotificationRow({
                    visible: true,
                    text: successMessage,
                    disappear: true,
                    mode: NOTIFICATION_ROW_MODES.SUCCESS
                });
            }).catch((error) => {
                let errorMessage = staticTexts?.notification_default_error;
                if (error && error.response && error.response.slug === USER_ERROR_TYPES.USER_NOT_FOUND) {
                    errorMessage = staticTexts?.forgot_email_send_error?.replace('%d', username);
                }
                this.props.toggleNotificationRow({
                    visible: true,
                    text: errorMessage,
                    disappear: true,
                    mode: NOTIFICATION_ROW_MODES.ERROR
                });
            })
        }
    }

    render() {
        const {requestLoading, staticTexts, location} = this.props;
        const {passwordData, errors} = this.state;
        const isEditEmail = location?.pathname.includes('edit-email')

        return <AuthWrapper>
            <div className="form-content  form-password-content">
                <Link to={'/'} className='form-logo'>
                    <Logo/>
                </Link>
                <div className="inner-part">
                    <div className="form-title">
                        {
                            isEditEmail ? staticTexts?.change_email_header_title
                                : staticTexts?.forgot_password_header_title
                        }
                    </div>
                    <div className="form-sub-title">
                        {staticTexts?.forgot_password_header_description}
                    </div>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        name={'username'}
                        value={passwordData.username}
                        error={errors.username}
                        placeholder={staticTexts?.forgot_password_placeholder_username}
                        maxLength={50}
                        onChange={this.getInputValues}
                        onKeyDown={(e) => {
                            e.key === 'Enter' && this.forgotPassword()
                        }}
                    />
                    <PrimaryButton
                        title={staticTexts?.forgot_password_btn_title_send}
                        className="password-btn without-hover"
                        disabled={!isEmail(passwordData?.username)}
                        loading={requestLoading}
                        loadingText={staticTexts?.btn_loading_text}
                        cb={this.forgotPassword}/>
                </div>
                {/*<Link to={'/sign-up'} className="have-account">*/}
                {/*    {staticTexts?.login_dont_have_account}*/}
                {/*    <span>{staticTexts?.login_create_an_account}</span>*/}
                {/*</Link>*/}
            </div>
        </AuthWrapper>
    }
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    ForgotUserPassword,
    toggleNotificationRow,
    ChangeUserEmail
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
