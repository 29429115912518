import {AUTH_CONSTS, GENERAL_CONSTS, PRODUCTS_CONSTS} from "../constants";
import {StaticTexts} from "../../constants/staticTexts";

export const initialState = {
    languages: [],
    selectedLanguage: {
        code: "hy"
    },
    currentCurrency: {AMD_AMD: 1},
    translation: StaticTexts.hy,
    staticTexts: StaticTexts.hy,
    singleCategory: null,
    singleFilter: null,
    mainSlider: [],
    categories: [],
    filters: {
        list: [],
        categoryId: null,
    },
    banners: [],
    markets: [],
    marketById: {},
    projects: [],
    sections: [],
    singleSection: {
        list: [],
        hasMore: false,
    },
    regions: [],
    residences: [],
    contacts: {},
    information: {},
    customPages: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GENERAL_CONSTS.GET_LANGUAGES:
            const mainLanguage = action.payload.find(lg => lg.isMain);
            const selectedLanguageIsExist = state.selectedLanguage && state.selectedLanguage.code
                && action.payload.find(lg => lg.code === state.selectedLanguage.code);
            const newSelectedLanguage = selectedLanguageIsExist ? state.selectedLanguage :
                (mainLanguage ? mainLanguage : action.payload[0]);

            const newState = {
                ...state,
                languages: action.payload,
                selectedLanguage: newSelectedLanguage,
            };
            if (state.staticTexts && state.staticTexts[newSelectedLanguage?.code]) {
                newState.translation = state.staticTexts[newSelectedLanguage?.code]
            }
            return newState;
        case GENERAL_CONSTS.CHANGE_LANGUAGE:
            return {
                ...state,
                selectedLanguage: action.payload,
            };
        case GENERAL_CONSTS.GET_CURRENCY:
            return {
                ...state,
                currentCurrency: action.payload,
            };
        case GENERAL_CONSTS.GET_STATIC_TEXTS:
            return {
                ...state,
                staticTexts: action.payload[state.selectedLanguage?.code] || StaticTexts[state.selectedLanguage?.code]
            };
        case GENERAL_CONSTS.GET_SLIDER:
            return {
                ...state,
                mainSlider: action.payload
            };
        case GENERAL_CONSTS.GET_CATEGORIES:
            return {
                ...state,
                categories: action.payload
            };
        case GENERAL_CONSTS.GET_SINGLE_CATEGORY:
            return {
                ...state,
                singleCategory: action.payload,
            };
        case GENERAL_CONSTS.GET_FILTERS:
            return {
                ...state,
                filters: {
                    list: action.payload.data,
                    categoryId: action.payload.categoryId,
                }
            };
        case GENERAL_CONSTS.GET_SINGLE_FILTER:
            return {
                ...state,
                singleFilter: action.payload,
            };
        case GENERAL_CONSTS.GET_BANNERS:
            return {
                ...state,
                banners: action.payload
            };
        case GENERAL_CONSTS.GET_MARKETS:
            return {
                ...state,
                markets: action.payload
            };
        case GENERAL_CONSTS.GET_MARKET_BY_ID:
            return {
                ...state,
                marketById: action.payload
            };

        case GENERAL_CONSTS.CLEAR_MARKET_BY_ID:
            return {
                ...state,
                marketById: null,
            };
        case GENERAL_CONSTS.GET_PROJECTS:
            return {
                ...state,
                projects: action.payload
            };
        case GENERAL_CONSTS.GET_SECTIONS:
            return {
                ...state,
                sections: action.payload
            };
        case GENERAL_CONSTS.GET_SINGLE_SECTION:
            const newSection = {
                list: action.payload.reset ? action.payload.data : [...state.singleSection.list, ...action.payload.data],
                hasMore: action.payload.hasMore
            };
            return {
                ...state,
                singleSection: newSection,
            };
        case GENERAL_CONSTS.CLEAR_SINGLE_SECTION:
            return {
                ...state,
                singleSection: {...initialState.singleSection},
            };
        case GENERAL_CONSTS.GET_REGIONS:
            return {
                ...state,
                regions: action.payload,
            };
        case GENERAL_CONSTS.GET_RESIDENCES:
            return {
                ...state,
                residences: action.payload,
            };
        case GENERAL_CONSTS.CLEAR_RESIDENCES:
            return {
                ...state,
                residences: initialState.residences,
            };
        case GENERAL_CONSTS.GET_CONTACTS:
            return {
                ...state,
                contacts: action.payload
            };
        case GENERAL_CONSTS.GET_INFORMATION:
            return {
                ...state,
                information: action.payload
            };
        case PRODUCTS_CONSTS.TOGGLE_PRODUCT_LIKE:
            return {
                ...state,
                sections: state.sections?.map(section => {
                        return {
                            ...section,
                            products: section?.products?.map(item => item.id === action.payload.id ?
                                {
                                    ...item,
                                    isFavorite: action.payload.likeState
                                } : item
                            ),
                        }
                    }
                ),
                banners: state.banners?.map(banner => {
                        return {
                            ...banner,
                            products: banner?.products?.map(item => item.id === action.payload.id ?
                                {
                                    ...item,
                                    isFavorite: action.payload.likeState
                                } : item
                            ),
                        }
                    }
                ),
                singleSection: {
                    ...state.singleSection,
                    list: state.singleSection?.list?.map(item => item.id === action.payload.id ?
                        {
                            ...item,
                            isFavorite: action.payload.likeState
                        } : item
                    )
                },
                marketById: {
                    ...state.marketById,
                    products: state.marketById?.products?.map(item => item.id === action.payload.id ?
                        {
                            ...item,
                            isFavorite: action.payload.likeState
                        } : item
                    )

                },
            };
        case GENERAL_CONSTS.GET_CUSTOM_PAGES:
            return {
                ...state,
                customPages: action.payload,
            };
        default:
            return state;
    }
}
