// import packages
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// Import assets
import './social-logins.scss';
import { AppleAuthIcon, FacebookAuthIcon, GoogleAuthIcon } from "../../../assets/images";

// import components
import { AuthorisationButton } from "../../uiElements/buttons/Buttons";

// import utils
import { FACEBOOK_APP_ID, GOOGLE_CLIENT_ID } from "../../../configs/variables";
import { getPropsFromState } from "../../../redux/mapStateToProps";
import { SocialSignIn } from "../../../redux/actions";

class LoginOptionsCard extends React.Component {

    constructor (props) {
        super();
        this.responseGoogle = this.responseGoogle.bind(this);
        this.responseFacebook = this.responseFacebook.bind(this);
        this.responseApple = this.responseApple.bind(this);
    }


    responseGoogle (response) {
        const { SocialSignIn, location } = this.props;
        const redirectTo = location?.state?.redirectTo;
        console.log('google login response', response);
        if (response?.tokenId) {
            SocialSignIn(response.tokenId, 'google', redirectTo).catch((error) => {
                const status = error && error.response && error.response.data && error.response.data.type;
                // console.log("error.response", error.response);
                if (status) {
                    this.handleSignInErrors(status);
                }
            })
        }
    }

    responseFacebook (response) {
        const { SocialSignIn, location } = this.props;
        const redirectTo = location?.state?.redirectTo;
        // console.log('facebook login response', response);
        //alert('facebook login response' + JSON.stringify(response));
        if (response?.accessToken) {
            SocialSignIn(response.accessToken, 'facebook', redirectTo).catch((error) => {
                const status = error && error.response && error.response.data && error.response.data.type;
                // console.log("error.response", error.response);
                if (status) {
                    this.handleSignInErrors(status);
                }
            })
        }
    }

    responseApple (response) {
        const { SocialSignIn, location } = this.props;
        const redirectTo = location?.state?.redirectTo;
        // console.log('facebook login response', response);
        //alert('facebook login response' + JSON.stringify(response));
        if (response?.authorization?.id_token) {
            SocialSignIn(response.authorization.id_token, 'apple/web', redirectTo).catch((error) => {
                const status = error && error.response && error.response.data && error.response.data.type;
                // console.log("error.response", error.response);
                if (status) {
                    this.handleSignInErrors(status);
                }
            })
        }
    }

    render () {
        const { link, staticTexts } = this.props
        return <div className="login-options-wrapper">
            <GoogleLogin
                clientId={GOOGLE_CLIENT_ID}
                onSuccess={this.responseGoogle}
                autoLoad={false}
                cookiePolicy={'single_host_origin'}
                prompt={'select_account'}
                onFailure={(error) => {
                    // console.log(JSON.stringify(error));
                    //alert('ERROR' + JSON.stringify(error));
                }}
                render={renderProps => (
                    <AuthorisationButton
                        onClick={renderProps.onClick}
                        title={staticTexts?.login_with_google}
                        className='social-sign-in-btn with-google'
                        svg={
                            <GoogleAuthIcon className="authorisation-button-image" title={''}/>
                        }
                    />
                )}
            />
            <FacebookLogin
                appId={FACEBOOK_APP_ID}
                autoLoad={false}
                callback={this.responseFacebook}
                render={renderProps => (
                    <AuthorisationButton
                        onClick={renderProps.onClick}
                        title={staticTexts?.login_with_facebook}
                        className='social-sign-in-btn with-facebook'
                        svg={
                            <FacebookAuthIcon className="authorisation-button-image" title={''}/>
                        }
                    />
                )}
            />
            {/*    <AuthorisationButton
            onClick={() => {
                window.AppleID && window.AppleID.auth && window.AppleID?.auth.signIn
                && window.AppleID.auth.signIn().then((resData) => {
                    console.log('resData', resData);
                    this.responseApple(resData)
                })
            }}
            title={staticTexts?.login_with_apple}
            className='social-sign-in-btn with-apple'
            svg={<span className='apple-button-image-wrapper'>
                        <AppleAuthIcon className="authorisation-button-image" title={''}/>
                                    <img src={AppleAuthIcon} className="authorisation-button-image" alt={''}/>
                                </span>}
        />*/}

           {/* {link === "/sign-in" ? <Link to={link} className="have-account">
                {staticTexts?.sign_up_already_have_account}
                <span>{staticTexts?.sign_up_navigate_to_account}</span>
            </Link> : (link === "/sign-up" ? <Link to={link} className="have-account">
                {staticTexts?.login_dont_have_account}
                <span>{staticTexts?.login_create_an_account}</span>
            </Link> : "")}*/}

        </div>
    }
}


const mapStateToProps = (state) => getPropsFromState(state, ['staticTexts']);

const mapDispatchToProps = { SocialSignIn };


export default connect(mapStateToProps, mapDispatchToProps)(LoginOptionsCard)
