import {CART_CONSTS} from "../constants";


export const getCartProducts = (product) => {
    return dispatch => {
        dispatch({
            type: CART_CONSTS.GET_CART_PRODUCTS,
            payload: {
                product
            }
        })
    }
};

export const addProductToCart = (product) => {
    console.log("product", product)
    return dispatch => {
        dispatch({
            type: CART_CONSTS.ADD_TO_CART,
            payload: {
                product
            }
        })
    }
};

export const removeProductFromCart = (id) => {
    return dispatch => {
        dispatch({
            type: CART_CONSTS.REMOVE_FROM_CART,
            payload: id
        })
    }
};

export const clearUserCart = () => {
    return dispatch => {
        dispatch({
            type: CART_CONSTS.CLEAR_USER_CART
        })
    }
};

export const changeProductQuantityInCart = (productId, quantity) => {
    return dispatch => {
        if (quantity > 0) {
            dispatch({
                type: CART_CONSTS.CHANGE_PRODUCT_COUNT_IN_CART,
                payload: {
                    id: productId,
                    quantity,
                }
            })
        } else {
            dispatch({
                type: CART_CONSTS.REMOVE_FROM_CART,
                payload: productId
            })
        }
    }
};

export const AddAddress = ({mainAddress, lat, lng, secondaryAddress}) => {
    // console.log(mainAddress, secondaryAddress, lat, lng)
    return dispatch => {
        dispatch({
            type: CART_CONSTS.ADD_ADDRESS,
            payload: {
                mainAddress,
                lat,
                lng,
                secondaryAddress
            }
        })
    }
};
