// import packages
import React, {Component} from "react";
import Slider from "react-slick";

//import assets
import "./mainSlider.scss";

// Import Components
import {PrimaryButton, SliderButton} from "../../../components/uiElements/buttons/Buttons";

// Import utils
import {generateImageMediaUrl} from "../../../utils/generateMediaUrl";
import {SLIDER_URL_TYPES} from "../../../constants/constTypes";
import {history} from "../../../configs/history";

class MainSlider extends Component {
    constructor() {
        super();
        this.state = {
            slideIndex: 0,
            itemHeight: 0,
            sliderCircleEnded: false,
            firstImageLoaded: false,
            loadedImg: false
        };
        this.pagination = React.createRef();
        this.img = React.createRef();
        this.handleImageLoaded = this.handleImageLoaded.bind(this);
    }

    getSliderIndex = (index) => {
        this.setState({
            slideIndex: index,
        });
        this.slider.slickGoTo(index);
    };

    nextSlide = (index) => {
        this.setState({
            slideIndex: this.state.slideIndex - 1 === index ? 0 : index + 1,
        });
        if (this.props.mainSlider?.length - 1 === this.state.slideIndex) {
            this.setState({
                slideIndex: 0,
            });
        }
        this.slider.slickNext();
    };

    prevSlide = (index) => {
        this.setState({
            slideIndex: index === 0 ? this.props.mainSlider?.length - 1 : index - 1,
        });
        this.slider.slickPrev();
    };

    openUrl = () => {
        const {mainSlider} = this.props;
        const {slideIndex} = this.state;

        if (!!mainSlider?.[slideIndex]?.url) {
            window.open(mainSlider[slideIndex].url);
        } else {
            const path = !!SLIDER_URL_TYPES[mainSlider[slideIndex].type] && mainSlider[slideIndex].resourceId ?
                `${SLIDER_URL_TYPES[mainSlider[slideIndex].type]}/${mainSlider[slideIndex].resourceId}` : '/';
            history.push({
                pathname: path,
                state: {
                    title: mainSlider[slideIndex].title
                }
            })
        }
    };

    afterChangeSlideIndex = (slideIndex) => {
        // console.log('afterChangeSlideIndex', slideIndex)
        this.setState({
            sliderCircleEnded: false,
        })
    };

    beforeChangeSlideIndex = (_, slideIndex) => {
        // console.log('beforeChangeSlideIndex', _, slideIndex)
        this.setState({
            slideIndex,
            sliderCircleEnded: true,
        })
    };

    handleImageLoaded() {
        if (!this.state.loadedImg) {
            this.setState({loadedImg: true});
        }
    }

    render() {
        const {mainSlider, staticTexts} = this.props;
        const {slideIndex, loadedImg} = this.state;
        const settingsSlider = {
            dots: true,
            arrows: false,
            infinite: true,
            speed: 400,
            autoplay: true,
            pauseOnHover: false,
            swipeToSlide: false,
            autoplaySpeed: 5000,
            lazyLoad: 'progressive',
            swipe: window.innerWidth < 700,
            afterChange: this.afterChangeSlideIndex,
            beforeChange: this.beforeChangeSlideIndex,
            ref: slider => (this.slider = slider),
            slidesToShow: 1,
            slidesToScroll: 1,
        };

        return !!mainSlider?.length && <div className="slider-wrapper">
            <div className="image-wrapper">
                <div className={'main-slider-items'}>
                    <div className={'main-slider-inner-part'}>
                        <Slider {...settingsSlider}>
                            {mainSlider?.map(item => {
                                const t = item?.title || ""
                                const start = t.slice(0, t.indexOf("["))
                                const mid = t.slice(t.indexOf("[") + 1, t.lastIndexOf("]")) || ""
                                const end = t.slice(t.lastIndexOf("]") + 1)
                                const titleJSX = t.indexOf("[") !== t.lastIndexOf("]")
                                    ? <>{start}<span>{mid}</span>{end}</> : t
                                return <div key={item.id}
                                            className={`slider-item ${!!item?.url ? "slide-link" : ""}`}
                                            ref={this.img}
                                            onClick={this.openUrl}>
                                    <picture>
                                        <source media="(max-width:701px)"
                                                srcSet={generateImageMediaUrl(item?.mediaMainMobile?.path)}/>
                                        <img src={generateImageMediaUrl(item?.mediaMain?.path)}
                                             alt={item?.mediaMain?.altAttribute ? item.mediaMain?.altAttribute : ''}/>
                                    </picture>
                                    <div className="slider-info-wrapper">
                                        <div className="info-content">
                                            <div className="slide-title">{titleJSX}</div>
                                            <div className="slide-sub-title">{item?.description}</div>
                                            <PrimaryButton title={staticTexts?.homepage_slider_btn_text}/>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </Slider>
                        <SliderButton
                            cbPrev={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.slider.slickPrev()
                            }}
                            cbNext={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.slider.slickNext()
                            }}
                        />

                    </div>
                </div>
            </div>
        </div>
    }
}

export default MainSlider;
