// Import packages
import React, {memo, useState} from "react";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";

// Import assets
import './mobileMenu.scss';
import {BackIcon, LocationIcon, RightArrowIcon} from "../../../assets/images";

// Import components
import {Menu} from 'antd';
import {PrimaryButton} from "../../uiElements/buttons/Buttons";
import AddressMapModal from "../../uiElements/addressMapModal/AddressMapModal";

// Import utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {ChangeLanguage} from "../../../redux/actions";

const {SubMenu} = Menu;

const MobileMenu = memo((props) => {
    const [firstParent, setFirstParent] = useState(undefined);
    const [secondParent, setSecondParent] = useState(undefined);
    const [openModal, toggleOpenModal] = useState(false);
    const [address, setAddress] = useState('');
    const {
        className,
        categories,
        selectedAddress,
        staticTexts,
        toggleMobileMenu,
        selectedLanguage,
        languages,
        mobileMenuIsOpen
    } = props;

    const getAddress = (address) => {
        toggleOpenModal(false);
        setAddress(address)
    };

    const toggleModal = () => {
        toggleOpenModal(!openModal);
        props.toggleMobileMenu();
    };

    return <section className={`mobile-menu-wrapper ${mobileMenuIsOpen ? "open" : ""}`}>
        <div className={`mobile-menu-content ${className ? className : ""}`}>
            <div
                className={`${categories?.[firstParent]?.children && categories?.[firstParent]?.children?.length ? 'hide' : 'show'}`}>
                <PrimaryButton className='shipping-address-btn' cb={toggleModal}
                               title={<>
                                   <LocationIcon title={''}/>
                                   <span>{selectedAddress ? selectedAddress :
                                       staticTexts?.header_default_address}
                                </span>
                               </>}
                />
                <Menu mode="inline">
                    <SubMenu
                        key="sub2"
                        title={<span>{staticTexts?.nav_item_categories}</span>}
                    >
                        {
                            !!categories?.length && categories?.map((item, index) => {
                                return <Menu.Item key={item?.id}>
                                    <Link to={`/category/${item.id}`} onClick={props.toggleMobileMenu}>
                                        {item?.title}
                                    </Link>
                                    {!!item?.children?.length &&
                                    <RightArrowIcon
                                        className={'right-arrow'} onClick={() => setFirstParent(index)}/>}
                                </Menu.Item>
                            })
                        }
                    </SubMenu>
                </Menu>
                <nav>
                    <Menu mode="inline">
                        <SubMenu
                            key="sub2"
                            title={<span>{staticTexts?.nav_item_help}</span>}
                        >
                            <Menu.Item>
                                <Link to={"/about"} onClick={props.toggleMobileMenu}>{staticTexts?.nav_item_about_us}</Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link to={"/blogs"} onClick={props.toggleMobileMenu}>{staticTexts?.nav_item_blog}</Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link to={"/help"} onClick={props.toggleMobileMenu}>{staticTexts?.nav_item_help}</Link>
                            </Menu.Item>
                        </SubMenu>
                    </Menu>
                    <Link to="/project-plans" className="menu-item" onClick={props.toggleMobileMenu}>{staticTexts?.nav_item_project_plan}</Link>
                    <Link to="/products" className="menu-item" onClick={props.toggleMobileMenu}>{staticTexts?.nav_item_products}</Link>
                    <Link to="/shipping" className="menu-item" onClick={props.toggleMobileMenu}>{staticTexts?.nav_item_shipping}</Link>
                    <Link to="/apps" className="menu-item" onClick={props.toggleMobileMenu}>{staticTexts?.nav_item_apps}</Link>
                </nav>
            </div>
            {
                categories?.[firstParent]?.children?.length &&
                <div className="categories-first-sub">
                    <div className="header" onClick={() => setFirstParent(undefined)}>
                        <BackIcon
                            className={'left-arrow'}/>{staticTexts?.btn_back}
                    </div>
                    {
                        !!props?.categories?.[firstParent]?.children?.length &&
                        props?.categories?.[firstParent]?.children?.map((item, index) => {
                            return <Link to={`/category/${categories?.[firstParent].id}/${item.id}`} className="category-item"
                                         onClick={props.toggleMobileMenu}
                                         key={item.id}>
                                {item?.title}
                                {!!item?.children?.length &&
                                <RightArrowIcon
                                    className={'right-arrow'} onClick={() => setSecondParent(index)} />}
                            </Link>
                        })
                    }
                </div>
            }

            {categories?.[firstParent]?.children
            && !!categories?.[firstParent]?.children?.[secondParent]?.children?.length &&
            <div className="categories-second-sub">
                <div className="header" onClick={() => setSecondParent(undefined)}>
                    <BackIcon
                        className={'left-arrow'}/>{staticTexts?.btn_back}
                </div>
                {
                    categories?.[firstParent]?.children?.[secondParent]?.children?.map(item => {
                        return <div className="category-item" key={item.id}>
                            {item?.title}
                            {item?.children &&
                            <RightArrowIcon className={'right-arrow'}/>}
                        </div>
                    })
                }
            </div>
            }
        </div>
        <div className={`mobile-menu-overlay`} onClick={toggleMobileMenu}/>
        <AddressMapModal addresFromHeader
                         isOpen={openModal}
                         closeModal={() => toggleOpenModal(false)}
                         getAddress={getAddress}/>
    </section>
});

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contacts',
        'selectedLanguage',
        'languages',
    ])
};
const mapDispatchToProps = {ChangeLanguage};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);