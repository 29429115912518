export const GENERAL_CONSTS = {
    GET_STATIC_TEXTS: "GET_STATIC_TEXTS",
    GET_LANGUAGES: "GET_LANGUAGES",
    CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
    GET_CURRENCY: "GET_CURRENCY",
    GET_SLIDER: "GET_SLIDER",
    GET_CATEGORIES: "GET_CATEGORIES",
    GET_SINGLE_CATEGORY: "GET_SINGLE_CATEGORY",
    GET_FILTERS: "GET_FILTERS",
    GET_SINGLE_FILTER: "GET_SINGLE_FILTER",
    GET_BANNERS: "GET_BANNERS",
    GET_MARKETS: "GET_MARKETS",
    GET_MARKET_BY_ID: "GET_MARKET_BY_ID",
    CLEAR_MARKET_BY_ID: "CLEAR_MARKET_BY_ID",
    GET_PROJECTS: "GET_PROJECTS",
    GET_SECTIONS: "GET_SECTIONS",
    GET_SINGLE_SECTION: "GET_SINGLE_SECTION",
    CLEAR_SINGLE_SECTION: "CLEAR_SINGLE_SECTION",
    GET_REGIONS: "GET_REGIONS",
    GET_RESIDENCES: "GET_RESIDENCES",
    CLEAR_RESIDENCES: "CLEAR_RESIDENCES",
    GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
    GET_CONTACTS: "GET_CONTACTS",
    SEND_MESSAGE: "SEND_MESSAGE",
    GET_CUSTOM_PAGES: "GET_CUSTOM_PAGES",
    GET_INFORMATION: "GET_INFORMATION",
};
