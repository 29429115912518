import { AUTH_CONSTS, PRODUCTS_CONSTS } from "../constants";

export const initialState = {
    singleProduct: null,
    productReviews: {},
    productReviewReplies: {},

    products: {
        list: [],
        count: 0,
        text: '',
        hasMore: false,
        state: null,
    },
    identicalProducts: [],
    specialProducts: [],
    userFavoriteProducts: {
        list: [],
        hasMore: false,
    },
    suggestionsList: [],
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case PRODUCTS_CONSTS.GET_PRODUCTS:
            const newProductsList = {
                list: action.payload.reset ? action.payload.data : [...state.products.list, ...action.payload.data],
                count: action.payload.count,
                text: action.payload.text,
                hasMore: action.payload.hasMore,
                state: action.payload.state
            };
            return {
                ...state,
                products: newProductsList
            };
        case PRODUCTS_CONSTS.GET_SINGLE_PRODUCT:
            return {
                ...state,
                singleProduct: action.payload,
            };
        case PRODUCTS_CONSTS.CLEAR_SINGLE_PRODUCT:
            return {
                ...state,
                singleProduct: null,
            };
        case PRODUCTS_CONSTS.GET_IDENTICAL_PRODUCTS:
            return {
                ...state,
                identicalProducts: action.payload,
            };
        case PRODUCTS_CONSTS.GET_SPECIAL_PRODUCTS:
            return {
                ...state,
                specialProducts: action.payload,
            };
        case PRODUCTS_CONSTS.TOGGLE_PRODUCT_LIKE:
            return {
                ...state,
                products: {
                    ...state.products,
                    list: state.products.list.map(item => item.id === action.payload.id ?
                        {
                            ...item,
                            isFavorite: action.payload.likeState
                        } : item
                    )
                },
                userFavoriteProducts: {
                    ...state.userFavoriteProducts,
                    list: state.userFavoriteProducts.list?.filter(item => item.id !== action.payload.id),
                },
                identicalProducts: state.identicalProducts.map(item => item.id === action.payload.id ?
                    {
                        ...item,
                        isFavorite: action.payload.likeState
                    } : item
                ),
                specialProducts: state.specialProducts.map(item => item.id === action.payload.id ?
                    {
                        ...item,
                        isFavorite: action.payload.likeState
                    } : item
                ),

                singleProduct: state.singleProduct && {
                    ...state.singleProduct, isFavorite: action.payload.likeState
                }
            };
        case PRODUCTS_CONSTS.GET_FAVORITE_PRODUCTS:
            return {
                ...state,
                userFavoriteProducts: {
                    list: action.payload.reset ? action.payload.data : [...state.userFavoriteProducts.list, ...action.payload.data],
                    hasMore: action.payload.hasMore
                },
            };
        case PRODUCTS_CONSTS.CLEAR_PRODUCTS:
            return {
                ...state,
                products: { ...initialState.products }
            };
        case PRODUCTS_CONSTS.CLEAR_PRODUCT_FILTERS:
            return {
                ...state,
                products: { ...initialState.products }
            };
        case PRODUCTS_CONSTS.GET_PRODUCTS_REVIEWS:
            return {
                ...state,
                productReviews: action.payload,
            };

        case PRODUCTS_CONSTS.UPDATE_PRODUCT_REVIEW:
            let review = state.productReviews?.reviews.filter(review => review.id !== action.payload.data.id);
            review.unshift(action.payload.data);
            return {
                ...state,
                productReviews: {
                    ...state.productReviews,
                    reviews: review,
                }
            };

        case PRODUCTS_CONSTS.ADD_PRODUCTS_REVIEW_REPLIES:
            // console.log(action.payload, 'action.payload');
            return {
                ...state,
                productReviews: {
                    ...state.productReviews,
                    reviews: state.productReviews.reviews.map(item => item.id === action.payload ?
                        {
                            ...item,
                            repliesCount: item.repliesCount + 1
                        } : item
                    ),
                }
            };

        case PRODUCTS_CONSTS.DELETE_PRODUCTS_REVIEWS:
            return {
                ...state,
                productReviews: {
                    ...state.productReviews,
                    count: state.productReviews?.reviews.length - 1,
                    reviews: state.productReviews?.reviews.filter(review => review.id !== action.payload),
                }
            };

        case PRODUCTS_CONSTS.CLEAR_PRODUCTS_REVIEWS:
            return {
                ...state,
                productReviews: { ...initialState.productReviews }
            };
        case PRODUCTS_CONSTS.GET_PRODUCTS_REVIEW_REPLIES:
            return {
                ...state,
                productReviewReplies: {
                    ...state.productReviewReplies,
                    [action.payload.id]: action.payload.data
                },
            };
        case PRODUCTS_CONSTS.CLEAR_PRODUCTS_REVIEW_REPLIES:
            return {
                ...state,
                productReviewReplies: { ...initialState.productReviews },
            };
        case PRODUCTS_CONSTS.DELETE_PRODUCTS_REVIEW_REPLIES:
            return {
                ...state,
                productReviewReplies: {
                    ...state.productReviewReplies,
                    [action.payload.reviewId]: state.productReviewReplies[action.payload.reviewId]?.filter(review => review.id !== action.payload.repliesId),
                },
                productReviews: {
                    ...state.productReviews,
                    reviews: state.productReviews?.reviews?.map(item => item.id === action.payload.reviewId ?
                        {
                            ...item,
                            repliesCount: item.repliesCount - 1
                        } : item
                    ),
                }
            };

        case AUTH_CONSTS.LOG_OUT:
            return {
                ...state,
                ...initialState
            };
        default:
            return state;
    }
}
