// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import {useParams, useHistory} from 'react-router-dom';

//Import assets
import './profile.scss';

//Import Components
import Settings from "./settings/Settings";


// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {LogOut} from "../../redux/actions";
// import Favorites from "../components/profile/Favorites";
// import BonusCard from "../components/profile/BonusCard";
// import Orders from "../components/profile/Orders";

import {resizeImage} from "../../utils/resizeImage";
import {generateFileMediaUrl, generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {
    ProfileFavIcon,
    ProfileInfoIcon,
    ProfileLogoutIcon,
    ProfileNotificationIcon,
    ProfileOrdersIcon
} from "../../assets/images";
import Favorites from "./favorites/Favorites";
import Orders from "./orders/Orders";
import Notifications from "./notifications/Notifications";
// import Orders from "./orders/Orders";


const Profile = (props) => {
    const params = useParams();
    const history = useHistory();

    let {pageName} = params;
    const {staticTexts} = props;
    const [pageTitle, setPageTitle] = useState("");

    useEffect(() => {
        if (!props.isLoggedIn) {
            history.push("/");
        }
        if (!pageName) {
            history.push("/profile/settings");
        }
    }, [pageName]);

    const getPageTitle = (title) => {
        setPageTitle(title)
    }
    return <div className="user-details-wrapper">
        <div className="left-side-menu">
            <div className="left-side-menu-fixed">
                <div className="left-side-menu-header">
                    <img
                        src={resizeImage(generateImageMediaUrl(props.loggedInUser?.profilePicturePath?.path, 'user'), 200, 200)}
                        className={'profile-img'} alt=""/>
                    <div className="menu-header-content">
                        <div
                            className="header-name">{props.loggedInUser.firstName + ' ' + props.loggedInUser.lastName}</div>
                        <div className="header-email">{props.loggedInUser.username}</div>
                    </div>
                </div>
                <ul className="aside-menu-list">
                    <li>
                        <NavLink to="/profile/settings" activeClassName='active'>
                            <ProfileInfoIcon title={""}/>
                            <span>{staticTexts?.profile_left_menu_settings}</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/profile/favorites" activeClassName='active'>
                            <ProfileFavIcon title={""}/>
                            <span>{staticTexts?.profile_left_menu_favorites}</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/profile/orders" activeClassName='active'>
                            <ProfileOrdersIcon title={""}/>
                            <span>{staticTexts?.profile_left_menu_orders}</span>
                        </NavLink>
                    </li>
                    <li className="menu-notification">
                        <NavLink to="/profile/notifications" activeClassName='active'>
                            <ProfileNotificationIcon title={""}/>
                            <span>{staticTexts?.profile_left_menu_notifications}</span>
                        </NavLink>
                        {!!props.nonOpenedNotificationCount && <div className="badge">
                            {props?.nonOpenedNotificationCount < 10 ? props?.nonOpenedNotificationCount : '9+'}
                        </div>}
                    </li>
                    <li onClick={props.LogOut} className="logout-wrapper"><ProfileLogoutIcon
                        title={""}/><span>{staticTexts?.profile_left_menu_logout}</span></li>
                </ul>
            </div>
        </div>
        <div className="profile-content-wrapper">
            <div className="profile-content-header">{pageTitle}</div>
            <div className="content-wrapper">
                {pageName === 'settings' && <Settings getPageTitle={getPageTitle}/>}
                {pageName === 'favorites' && <Favorites getPageTitle={getPageTitle}/>}
                {pageName === 'orders' && <Orders getPageTitle={getPageTitle}/>}
                {pageName === 'notifications' && <Notifications getPageTitle={getPageTitle}/>}
            </div>
        </div>
    </div>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
    'isLoggedIn',
    'loggedInUser',
    'nonOpenedNotificationCount',
]);

const mapDispatchToProps = {LogOut};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);


