// Import packages
import React, {Component} from "react";
import {Route, Switch, Router,} from "react-router-dom";

// Import utils
import {history} from "../configs/history";

// Import pages
import Layout from "../containers/Layout";
import Homepage from "../containers/homepage/Homepage";
import ProductDetails from "../containers/productDetails/ProductDetails";
import NotFound from "../containers/notFound/NotFound";

import SignIn from "../containers/authentication/SignIn";
import SignUp from "../containers/authentication/SignUp";
import VerifyEmail from "../containers/verifyEmail/VerifyEmail";
import ForgotPassword from "../containers/forgotPassword/ForgotPassword";
import ResetPassword from "../containers/forgotPassword/ResetPassword";
import Profile from "../containers/profile/Profile";
import Cart from "../containers/cart/Cart";
import CartOrder from "../containers/cartOrder/CartOrder";
import Search from "../containers/search/Search";
import About from "../containers/about/About";
import MarketsList from "../containers/markets/MarketsList";
import MarketDetails from "../containers/markets/marketDetails/MarketDetails";
import Blog from "../containers/blog/Blog";
import BlogDetails from "../containers/blogDetails/BlogDetails";
import Help from "../containers/help/Help";
import ProjectPlans from "../containers/projectPlans/ProjectPlans";
import Rate from "../containers/rate/Rate";
import PrivacyPolicy from "../containers/privacy/PrivacyPolicy";
import Apps from "../containers/apps/Apps";
import Shipping from "../containers/shipping/Shipping";
import PaymentCallback from "../containers/PaymentCallback";
import CustomPage from "../containers/customPages/CustomPage";
import Rated from "../containers/rated/Rated";

export default class Routes extends Component {
    render() {
        return <Router history={history}>
            <Layout>
                <Switch>
                    <Route exact path="/" component={Homepage}/>
                    <Route exact path="/product/:id" component={ProductDetails}/>
                    <Route exact path="/sign-in" component={SignIn}/>
                    <Route exact path="/sign-up" component={SignUp}/>
                    <Route exact path="/user/confirm-email" component={VerifyEmail}/>
                    <Route exact path="/user/forgot-password" component={ForgotPassword}/>
                    <Route exact path="/user/edit-email" component={ForgotPassword}/>
                    <Route exact path="/user/reset-password" component={ResetPassword}/>
                    <Route exact path="/profile/:pageName/:id?" component={Profile}/>
                    <Route exact path="/cart" component={Cart}/>
                    <Route exact path="/cart/order" component={CartOrder}/>

                    <Route exact path="/products" component={Search}/>
                    <Route exact path="/search" component={Search}/>
                    <Route exact path="/category/:category/:subCategory?" component={Search}/>

                    <Route exact path="/rate/:orderId" component={Rate}/>
                    <Route exact path="/rated" component={Rated}/>
                    <Route exact path="/about" component={About}/>
                    <Route exact path="/help" component={Help}/>
                    <Route exact path="/apps" component={Apps}/>
                    <Route exact path="/shipping" component={Shipping}/>
                    <Route exact path="/markets" component={MarketsList}/>
                    <Route exact path="/project-plans" component={ProjectPlans}/>
                    <Route exact path="/market/:id" component={MarketDetails}/>
                    <Route exact path="/blogs" component={Blog}/>
                    <Route exact path="/blog/:slug" component={BlogDetails}/>
                    <Route exact path="/privacy-policy" component={PrivacyPolicy}/>
                    <Route exact path="/payment/callback" component={PaymentCallback}/>
                    <Route exact path="/custom-page/:id" component={CustomPage}/>
                    <Route exact path="/404" component={NotFound}/>
                    <Route exact path="*" component={NotFound}/>
                </Switch>
            </Layout>
        </Router>
    }
}
