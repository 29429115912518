import React from "react";

export const StaticTexts = {
    en: {
        //Notification defaults
        notification_default_error: "Something went wrong. Please try again!-en",
        notification_default_success: "Operation was successfully completed-en",
        notification_user_account_blocked_error: "Հարգելի օգտատեր Ձեր հաշիվը ապաակտիվացված է, խնդրում ենք կապ հաստատել %dի հետ-en",
        notification_user_account_blocked_service_center: "սպասարկման կենտրոն",

        //Sign-in
        login_error_invalid_username: "INVALID_USERNAME",
        login_error_invalid_password: "INVALID_PASSWORD",
        login_error_blocked_account: "BLOCKED_ACCOUNT",
        login_error_unverified_account: "UNVERIFIED_ACCOUNT",
        login_header_text_signIn: "Enter your credentials to access your account",
        login_form_title: "Welcome",
        login_placeholder_email: "Email",
        login_placeholder_password: "Password",
        login_forgot_password_text: "Forgot your Password?",
        login_btn_title_login: "Login",
        login_with_google: "Sign in with Google",
        login_with_facebook: "Sign in with Facebook",
        login_with_apple: "Sign in with Apple",
        login_dont_have_account: "Don't have an account yet?",
        login_create_an_account: "Create an account",

        //Sign-up
        sign_up_success: "The confirmation message will be resend to %d . To access all of your features, please verify your email address.-en",
        sign_up_error_exist_username: "Username already exist-en",
        sign_up_header_text_for_signUp: "Enter your details to create a new account",
        sign_up_form_title: "Welcome",
        sign_up_placeholder_firstName: "Name",
        sign_up_placeholder_lastName: "Surname",
        sign_up_placeholder_email: "Էլ․ հասցե-en",
        sign_up_placeholder_password: "Գաղտնաբառ-en",
        sign_up_placeholder_repeat_password: "Կրկնել Գաղտնաբառը-en",
        sign_up_btn_title_register: "Գրանցվել-en",
        sign_up_already_have_account: "Արդեն ունե՞ք հաշիվ`-en",
        sign_up_navigate_to_account: "Sign in account",
        sign_up_agreement_text: "I agree to the terms of the sale, the privacy policy.",

        //Account Activation
        activation_success: "Your profile successfully Activated!. Please Logged in to Continue-en",
        activation_error_link_date_expired: "Activation link date expired. Please resend the confirmation email-en",
        activation_error_account_already_activated: "Your account already activated. Please Logged in to Continue-en",
        activation_error_account_invalid_code: "activation error account invalid code",

        //Verify Email
        resend_email_success: "The confirmation message will be resent to %d . To access all of your features, please verify your email address.-en",
        resend_email_error_account_already_activated: "Your profile Active!. Please Logged in to Continue-en",
        activation_link_date_expired: "Activation link date expired-en",
        activation_link_resend_email_text: "Please resend the confirmation email-en",
        activation_link_resend_email_link: "resend the confirmation email.-en",
        verify_email_title: "Confirm your email",
        verify_email_description: "We sent a confirmation email to-en",
        verify_email_description_not_seen: "If you do not see it yet?",
        verify_email_resend_email_text: "You can send your confirmation email again.",
        verify_email_description_seconds: "сек",
        verify_email_send_code_btn: "Подтвердить-en",

        //Forgot Password
        forgot_email_send_success: "Successfully send-en",
        forgot_email_send_error: "User with %d username does not exist.-en",
        forgot_user_already_exist_error: "%d օգտանունով օգտատեր արդեն գոյություն ունի-en",
        forgot_password_header_title: "Forgot password",
        change_email_header_title: "Change email",
        forgot_password_header_description: "Enter your credentials to access your account",
        forgot_password_placeholder_username: "Email-en",
        forgot_password_btn_title_send: "Send-en",
        forgot_password_can_send_email: "In %d seconds you can resend Email-en",

        //User Profile
        profile_update_success: "Profile successfully updated-en",
        password_update_success: "Password successfully updated-en",
        password_update_error_not_valid: "The specified password is not valid-en",
        profile_left_menu_settings: "Անձնական տվյալներ-en",
        profile_left_menu_favorites: "Հավանած ապրանքներ-en",
        profile_left_menu_orders: "Գնումերի պատմություն-en",
        profile_left_menu_notifications: "Ծանոցումեր-en",
        profile_left_menu_logout: "Դուրս գալ-en",

        //Profile Favorites
        favorites_title: "Հավանած ապրանքներ-en",
        favorites_empty_view: "Հավանած ապրանքներ չկան-en",

        //Profile Notifications
        notifications_title: "Ծանուցումներ-en",
        notifications_details_title: "Notification Details",
        notifications_status: "Կարգավիճակ -en",
        notification_delete_success: "Ծանուցումը հաջողությամբ ջնջվեց-en",
        notification_delete_button: "Ջնջել-en",
        notification_empty_view: "Այս պահին ծանուցում չունեք-en",

        //Profile Orders History
        orders_history_title: "Գնումերի պատմություն-en",
        orders_types_tabs_all_orders_tab: "Բոլորը-en",
        orders_empty_view: "Այս պահին ոչինչ չունեք կցված-en",
        orders_order_number_title: "Order Number:",
        orders_order_date_title: "Order Date:",
        orders_details_title: "Detail Order",
        orders_details_products_count: "Items",
        orders_details_address: "Address",
        orders_details_receiver: "Receiver",
        orders_details_date: "Date",
        orders_details_status: "Status",
        orders_right_btn_cancel_order: "Չեղարկել-en",
        orders_right_btn_rate_order: "Գնահատել-en",
        orders_right_btn_delete_order: "Ջնջել-en",
        order_total_amount: "Կազմած գումար-en",
        orders_cancel_order_modal_text: "Ցանկանու՞մ եք չեղարկել պատվերը-en",

        //Profile settings
        settings_title: "Կոնտակտային տվյալներ-en",
        settings_update_profile_image: "Update Image",
        settings_upload_profile_image: "Upload Image",
        settings_file_size_message: "File size is too big",
        settings_label_firstName: "Անուն-en",
        settings_label_lastName: "Ազգանուն-en",
        settings_label_phoneNumber: "Հեռ. համար-en",
        settings_label_email: "Email",
        settings_label_country: "Country",
        settings_label_city: "City",
        settings_label_address: "Հասցե-en",
        settings_btn_remember_user_data: "Հիշել տվյալները-en",

        //Profile Change Password
        change_password_label_old_password: "Հին գաղտնաբառ-en",
        change_password_placeholder_password: "Գաղտնաբառ-en",
        change_password_label_new_password: "Նոր գաղտնաբառ-en",
        change_password_label_repeat_password: "Կրկնել գաղտնաբառը-en",
        change_password_btn_change: "Փոխել գաղտնաբառը-en",

        //Search
        search_empty_view: "Your search have no result-en",

        //Products
        products_empty_view: "Product is not found-en",

        //Not Found Page
        not_found_empty_view: "Sorry, page not found-en",

        //Agreement text
        agreement_text_sale_terms: "Ես համաձայն եմ վաճառքի պայմաններին,-en",
        agreement_text_privacy: "գաղտնիության-en",
        agreement_text_and: "և-en",
        agreement_text_personal_data_processing_policy: "անձնական տվյալների մշակման քաղաքականությանը-en",

        //Cart
        cart_products_count: "There are %d products-en",
        cart_product_is_not_available: "Out on Stock",
        cart_clear_cart: "Մաքրել զամբյուղը-en",
        cart_discount_size: "Զեղչի չափ-en",
        cart_order_details_title: "Payment Details",
        cart_order_details_all_products: "All Products",
        cart_order_details_amount: "Order Amount",
        cart_order_details_shipping: "Shipping",
        cart_order_details_discount: "Discount",
        cart_order_details_total_price: "Total Price",
        cart_order_address_title: "Select Shipping Address",
        cart_label_delivery_address: "Շենք, Մուտք, Հարկ, Բնակարան-en",
        cart_label_name: "Անուն Ազգանուն-en",
        cart_label_phoneNumber: "Հեռ․ համար-en",
        cart_label_email: "Էլ․ հասցե-en",
        cart_payment_type_online: "Առցանց վճարում-en",
        cart_payment_type_cash: "Կանխիկ վճարում-en",
        cart_payment_type_bonusCard: "Բոնուս քարտով վճարում-en",
        cart_label_promoCode: "Զեղչի կոդ-en",
        cart_send_promoCode_btn: "SEND",
        cart_promoCode_error: "Նման պրոմոկոդ գոյություն չունի-en",
        cart_promoCode_success: "Պրոմոկոդը գոյություն ունի-en",
        cart_promoCode_error_already_used: "Այս պրոմոկոդն արդեն օգտագործվել է-en",
        cart_promoCode_description: "Մուտքագրեք կոդ համարը, որպեսզի վճարեք պատվերի մինչև 10% -ը բոնուսներով: Բոնուսները դուրս գրելու համար հաջորդ քայլում ընտրեք վճարման եղանակը Առցանց վճարում-en",
        cart_btn_make_order: "Կատարել վճարում-en",
        cart_empty_cart: "Ձեր զամբյուղը դատարկ է-en",
        cart_link_to_products_list: "Ապրանքների ցանկ-en",
        cart_not_enough_bonus_points: "Բոնուս քարտի միավորները բավարար չեն պատվեր կատարելու համար-en",

        //Navigation Items
        nav_item_profile: "Profile",
        nav_item_sign_in: "Sign In",
        nav_item_notification: "Notification",
        nav_item_favorite: "Favorite",
        nav_item_cart: "My Cart",
        nav_item_project_plan: "Project Plan",
        nav_item_products: "Products",
        nav_item_shipping: "Shipping",
        nav_item_apps: "Apps",
        nav_item_about_us: "About",
        nav_item_contact_us: "Contact",
        nav_item_help: "Help",
        nav_item_blog: "Blog",
        nav_item_home: "Home",
        nav_item_mini_markets: "Mini Markets",
        nav_item_categories: "Categories",
        nav_item_privacy_policy: "Privacy policy",
        nav_item_already_have_account: "Արդեն ունե՞ք հաշիվ-en",
        nav_item_registration_page_signIn: "Մուտք գործել-en",
        nav_item_bonus_card_back_to_account: "Վերադարնալ հաշիվ-en",

        //Header Box
        header_default_address: "Yerevan, Armenia",
        header_dropdown_title: "Help",
        header_search_placeholder: "Search by name and by category…",
        header_search_btn: "Search",

        //Routs
        rout_item_home: "Գլխավոր-en",
        rout_item_search: "Որոնում-en",
        rout_item_mini_markets: "Մինի մարկետներ-en",
        rout_item_products_list: "Ապրանքների ցանկ-en",
        rout_item_discounted_products: "Զեղչված ապրանքներ-en",

        //Sort Part
        sort_select_label: "Սորտավորել ըստ-en",
        sort_select_placeholder: "Դասավորել ըստ-en",
        sort_type_price: 'Գնի-en',
        sort_type_updated_at: 'Ամսաթվի-en',

        //Homepage
        homepage_slider_btn_text: "Buy now",
        homepage_btn_see_all: "See All",
        homepage_banners_title: "Discounted products",
        homepage_categories_title: "Categories",
        homepage_mini_markets_title: "Mini markets",
        homepage_project_plans_title: "Project Plan",
        homepage_download_app_title: "Smartlife [applications] already available",
        homepage_download_app_description: "Store in tightly closed manufacturer's packaging at temperatures from away from sources of heat and ignition",
        homepage_download_app_btn: "Download",
        homepage_info_first_btn: "Delivery free of charge - 120 km",
        homepage_info_second_btn: "Spring deals starting at 8490 ֏ Shop now!",
        homepage_special_products_title: "Special offers",

        //Banners
        banner_title_mini_markets: "Մինի մարկետներ-en",
        banner_product_count_text: "ապրանք-en",

        //Products
        category_details_header: "Բոլոր ապրանքները-en",
        sub_categories_option_all: "Բոլորը-en",

        //Product Card
        product_card_add_to_cart_text: "Add to Cart",
        product_card_to_order_text: "To Order",

        //Product Details
        product_details_code: "Code",
        product_details_like_product: "Favorite",
        product_details_share_product: "Share",
        product_details_share_dropdown_copy: "Copy url",
        product_details_share_dropdown_facebook: "Facebook",
        product_details_is_available: "In Stock",
        product_details_is_not_available: "Out on Stock",
        product_details_delivery_info: "120km free shipping",
        product_details_order_bottom_info_title: "Ways to get an order",
        product_details_order_bottom_info_first_item: "[Take on your own] in 30 minutes",
        product_details_order_bottom_info_second_item: "[Delivery] 2-3 days, free shipping",
        product_details_tabs_description: "Նկարագրություն-en",
        product_details_tabs_details: "Բնութագիր-en",
        product_details_tabs_character: "Մանրամասներ-en",
        product_details_similar_products: "Similar Products",
        product_details_copy_link_copy: "Copy",
        product_details_copy_link_copied: "Copied",
        product_details_bottom_left_text_orange_part: "in 30 minutes",
        product_details_bottom_left_text_last_part: "Take on your own",
        product_details_bottom_right_text_orange_part: "Delivery",
        product_details_bottom_right_text_last_part: "2-3 days, free shipping",

        //Rate
        rate_products_desktop_title: "Product",
        rate_products_mobile_title: "Product rate",
        rate_right_title: "Rate",
        rate_right_subtitle_rated: "Rated:",
        rate_right_subtitle_your_rate: "Your rate:",
        rate_btn_title: "Rate",
        already_rated: "You have already rated",


        //Buttons
        btn_loading_text: "Սպասել-en",
        btn_buy_now: "Գնել հիմա-en",
        btn_see_more: "See more",
        btn_back: "Back",

        //Select
        select_option_all: "Բոլորը-en",

        //Filters
        filters_title: "Ֆիլտրեր-en",
        filters_price_panel_title: "Գին-en",
        filters_price_inputs_description: "Գին-en",
        filters_btn_clear: "Մաքրել-en",

        //Map Modal
        map_modal_title: "Մուտքագրեք հասցեն-en",
        map_modal_label_residence_street: "Քաղաք, փողոց-en",
        map_modal_label_building: "Շենք-en",
        map_modal_label_building_entrance: "Մուտք-en",
        map_modal_label_building_floor: "Հարկ-en",
        map_modal_label_building_house: "Բնակարան-en",
        map_modal_btn_cancel: "Չեղարկել-en",
        map_modal_btn_save: "Պահպանել-en",

        //Footer
        footer_address_title: "Address",
        footer_company_section_title: "Company",
        footer_support_section_title: "Support",
        footer_shoppers_section_title: "Shoppers",
        footer_social_items_title: "Follow us",
        footer_subscribe_title: "Subscribe",
        footer_subscribe_description: "Subscribe, find out about discounts, deals, new products.",
        footer_subscribe_placeholder: "Enter your email",
        footer_subscribe_btn: "Subscribe",
        footer_section_copyright_text: "© Copyright 2020 Online Supermarket All rights reserved.",
        footer_section_developed_by_text: "Մշակումը ՝-en",


        //About
        about_contact_btn: "Contact us",
        about_green_content_title: "We empower people create economic opportunities, giving new ways to live safely and comfortably.",
        about_green_content_item_technology_title: "Technology",
        about_green_content_item_technology_description: "creates inspiring ecommerce experiences for our buyers, sellers and developers.",
        about_green_content_item_impact_title: "Impact",
        about_green_content_item_impact_description: "Every day, people build businesses on eBay. Nonprofit organizations raise vital funds.",
        about_green_content_item_recognition_title: "Recognition",
        about_green_content_item_recognition_description: "There are many variations of passages but the majority have suffered alter humour.",
        about_contact_part_title: "Contact us",
        about_contact_part_description: "Dear customer, you can contact us in these ways, we are always available and we are ready to help you with love.",
        about_send_message_title: "Send Message",
        about_send_message_description: "Temperatures from away from sources of heat ignition",
        about_send_message_name_placeholder: "Name",
        about_send_message_phone_placeholder: "Phone",
        about_send_message_email_placeholder: "Email address",
        about_send_message_text_placeholder: "Type text",
        about_send_message_btn: "Send",
        about_info_section_address_title: "Address",
        about_info_section_working_hours_title: "Working hours",
        about_info_section_phone_title: "Phone number",

        //Shipping
        shipping_title: "Let's talk in more detail, Why do you need all this",
        shipping_description: "Contrary to popular belief, Lorem Ipsum is not simply random text. has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard Mcdhj js Clintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, cure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.",

        //Apps
        apps_title: "Smart Application",
        apps_description: "Dear customer, you can contact us in these ways, we are always available և we are ready.",
        apps_section1_title: "iOS Application",
        apps_section1_description: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard Mcdhj js Clintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsdxv ddvdum passage.Contrary to popular belief, Lorem Ipsum is not sidvdmply random text. It has roots in a piece of classical Latin literadvdvture from 45 BC, making it over 2000 years old. Richard Mcdhj js Clintock, a Latin professor at Hampden-Sydney College in Virginia looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage.",
        apps_section2_title: "Android Application",
        apps_section2_description: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard Mcdhj js Clintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsdxv ddvdum passage.Contrary to popular belief, Lorem Ipsum is not sidvdmply random text. It has roots in a piece of classical Latin literadvdvture from 45 BC, making it over 2000 years old. Richard Mcdhj js Clintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage.",

        //Blog
        blog_tabs_tab_all: "All",
        blog_copy_link_text_copy: "Copy",
        blog_copy_link_text_copied: "Copied",

        //Help
        help_contact_btn: "Contact",
        help_project_plan_btn: "Project Plan",
        help_apps_title_first_part: "App",
        help_apps_title_orange_part: "Support",
        help_apps_description: "With the SmartLife app on your smartphone, tablet, or computer. Click here to get all the Ring app help you need",
        help_apps_download_btn: "Download",

        //Contacts
        contacts_page_title: "Կոնտակտներ-en",
        contacts_page_left_subTitle_history: "Կոնտակտներ-en",
        contacts_page_left_description: "Հարգելի հաճախորդներ, բոլոր հարցերի համար կարող եք կապվել կենտրոնի հետ հեռախոսհամարով Աշխատանքային ժամերին Հարգելի հաճախորդներ, բոլոր հարցերի համար կարող եք կապվել կենտրոնի հետ հեռախոսհամարով Աշխատանքային ժամերին 10: 00-ից 22: 00-ն-en",
        contacts_page_left_subTitle_contacts: "Կոնտակտային տվյալներ-en",
        contacts_page_form_title: "Ուղարկեք մեզ հաղորդագրոթյուն-en",
        contacts_page_placeholder_firstName: "Անուն-en",
        contacts_page_placeholder_email: "Էլ․ հասցե-en",
        contacts_page_placeholder_phoneNumber: "Հեռ.համար-en",
        contacts_page_placeholder_message: "Գրեք ձեր հաղորդագրոթյունը…-en",
        contacts_page_btn_send_message: "Ուղարկել հաղորդագրոթյուն-en",

        //Products List
        products_list_page_title: "Ապրանքների ցանկ-en",

        //Modal
        modal_create_account_text: "Հասնելիություն ձեռք բերելու համար անհրաժեշտ է ստեղծել հաշիվ:-en",
        modal_btn_register: "Գրանցվել-en",
        modal_not_available_text: "Այս պահին պահեստում առկա չէ-en",
        modal_attach_card_success: "Դուք հաջողությամբ կցել եք ձեր բոնուս քարտը-en",
        modal_btn_products_list: "Ապրանքնրի ցանկ-en",
        modal_order_success: "Ձեր գործարքը հաջողությամբ կատարվել է-en",
        modal_order_error: "Գործարքը չհաջողվեց կատարել-en",
        modal_error_title: "Ցավում ենք-en",
        modal_success_title: "Շնորհավորում ենք-en",
        modal_warning_title: "Զգուշացում-en",
        modal_cart_has_not_available_product: "Զամբյուղում կան ոչ հասանելի ապրանքներ",
        modal_close_modal_btn: "Փակել",
        modal_cancel_modal_btn: "Չեղարկել",
        modal_see_order_btn: "Տեսնել պատվերը",
        modal_btn_return_to_cart: "Վերադառնալ զամբյուղ",
        request_modal_title: "Dear customer, this product is currently out of stock",
        request_modal_description: "If you want to order from this product, please specify your phone number, then our specialist will be contacted you for approval.",

    },
    hy: {
        //Notification defaults
        notification_default_error: "Ինչ որ բան այն չէ. Խնդրում ենք կրկին փորձել!",
        notification_default_success: "Գործողությունը հաջողությամբ կատարվեց",
        notification_user_account_blocked_error: "Հարգելի օգտատեր Ձեր հաշիվը ապաակտիվացված է, խնդրում ենք կապ հաստատել %dի հետ",
        notification_user_account_blocked_service_center: "սպասարկման կենտրոն",

        //Sign-in
        login_error_invalid_username: "Սխալ օգտանուն",
        login_error_invalid_password: "Սխալ գաղտնաբառ",
        login_error_blocked_account: "Այս հաշիվը արգելափակված է",
        login_error_unverified_account: "Այս հաշիվը հաստատված չէ",
        login_form_title: "Welcome",
        login_with_google: "Google-ի միջոցով",
        login_with_facebook: "Facebook-ի միջոցով",
        login_with_apple: "Sign in with Apple",
        login_placeholder_email: "Էլ․ հասցե",
        login_placeholder_password: "Գաղտնաբառ",
        login_forgot_password_text: "Մոռացե՞լ եք Գաղտնաբառը",
        login_btn_title_login: "Մուտք",
        login_header_text_signIn: "Enter your credentials to access your account",
        login_dont_have_account: "Don't have an account yet?",
        login_create_an_account: "Create an account",

        //Sign-up
        sign_up_success: "Հաստատման հաղորդագրությունը կուղարկվի %d էլ․ հասցեին: Ձեր բոլոր հնարավորություններից օգտվելու համար հաստատեք ձեր էլ. Փոստի հասցեն",
        sign_up_error_exist_username: "Օգտանունն արդեն գոյություն ունի",
        sign_up_header_text_for_signUp: "Enter your details to create a new account",
        sign_up_form_title: "Welcome",
        sign_up_placeholder_firstName: "Անուն",
        sign_up_placeholder_lastName: "Ազգանուն",
        sign_up_placeholder_email: "Էլ․ հասցե",
        sign_up_placeholder_password: "Գաղտնաբառ",
        sign_up_placeholder_repeat_password: "Կրկնել Գաղտնաբառը",
        sign_up_btn_title_register: "Գրանցվել",
        sign_up_already_have_account: "Արդեն ունե՞ք հաշիվ`",
        sign_up_navigate_to_account: "Войди аккаунт-hy",
        sign_up_agreement_text: "I agree to the terms of the sale, the privacy policy.",

        //Account Activation
        activation_success: "Ձեր հաշիվը հաջողությամբ ակտիվացված է: Խնդրում ենք մուտք գործել ՝ շարունակելու համար",
        activation_error_link_date_expired: "Ակտիվացման ժամկետըը լրացել է: Խնդրում ենք նորից ուղարկել հաստատման հաղորդագրություն",
        activation_error_account_already_activated: "Ձեր հաշիվը արդեն ակտիվ է: Խնդրում ենք մուտք գործել ՝ շարունակելու համար",
        activation_error_account_invalid_code: "activation error account invalid code",
        //Verify Email
        resend_email_success: "Հաստատման հաղորդագրությունը կուղարկվի %d էլ․ հասցեին: Ձեր բոլոր հնարավորություններից օգտվելու համար հաստատեք ձեր էլ. Փոստի հասցեն",
        resend_email_error_account_already_activated: "Ձեր հաշիվն ակտիվ է: Խնդրում ենք մուտք գործել ՝ շարունակելու համար",
        activation_link_date_expired: "Ակտիվացման ժամկետըը լրացել է:",
        activation_link_resend_email_text: "Խնդրում ենք նորից ուղարկել հաստատման հաղորդագրություն",
        activation_link_resend_email_link: "Նորից ուղարկել հաստատման հաղորդագրություն",
        verify_email_title: "Confirm your email",
        verify_email_description: "Մենք ուղարկել ենք հաստատման հաղորդագրություն հետևյալ էլ․ հասցեին․",
        verify_email_description_not_seen: "If you do not see it yet? ",
        verify_email_description_seconds: "վայրկյանից",
        verify_email_resend_email_text: "You can send your confirmation email again.",
        verify_email_send_code_btn: "Հաստատել",

        //Forgot Password
        forgot_email_send_success: "Հաջողությամբ ուղարկված է",
        forgot_email_send_error: "%d օգտանունով օգտատեր գոյություն չունի:",
        forgot_password_header_title: "Forgot password",
        change_email_header_title: "Change email",
        forgot_password_header_description: "Enter your credentials to access your account",
        forgot_password_placeholder_username: "Էլ․ հասցե",
        forgot_password_btn_title_send: "Ուղարկել",
        forgot_password_can_send_email: "%d վայրկյանից կրկին կարող եք ուղարկել հաղորդագրություն",

        //User Profile
        profile_update_success: "Հաշիվը հաջողությամբ թարմացվել է",
        password_update_success: "Գաղտնաբառը հաջողությամբ թարմացվել է",
        password_update_error_not_valid: "Նշված գաղտնաբառը վավեր չէ",
        profile_left_menu_settings: "Անձնական տվյալներ",
        profile_left_menu_favorites: "Հավանած ապրանքներ",
        profile_left_menu_orders: "Գնումերի պատմություն",
        profile_left_menu_notifications: "Ծանոցումեր",
        profile_left_menu_logout: "Դուրս գալ",

        //Search
        search_empty_view: "Որոնման արդյունք չկա",

        //Products
        products_empty_view: "Ապրանքը չի գտնվել",

        //Not Found Page
        not_found_empty_view: "Ներեցեք, էջը չի գտնվել",

        //Agreement text
        agreement_text_sale_terms: "Ես համաձայն եմ վաճառքի պայմաններին,",
        agreement_text_privacy: "գաղտնիության",
        agreement_text_and: "և",
        agreement_text_personal_data_processing_policy: "անձնական տվյալների մշակման քաղաքականությանը",

        //Cart
        cart_products_count: "There are %d products",
        cart_product_is_not_available: "Out on Stock",
        cart_clear_cart: "Մաքրել զամբյուղը",
        cart_discount_size: "Զեղչի չափ",
        cart_order_details_title: "Payment Details",
        cart_order_details_all_products: "All Products",
        cart_order_details_amount: "Order Amount",
        cart_order_details_shipping: "Shipping",
        cart_order_details_discount: "Discount",
        cart_order_details_total_price: "Total Price",
        cart_send_promoCode_btn: "SEND",
        cart_order_address_title: "Select Shipping Address",
        cart_label_delivery_address: "Շենք, Մուտք, Հարկ, Բնակարան",
        cart_label_name: "Անուն Ազգանուն",
        cart_label_phoneNumber: "Հեռ․ համար",
        cart_label_email: "Էլ․ հասցե",
        cart_payment_type_online: "Առցանց վճարում",
        cart_payment_type_cash: "Կանխիկ վճարում",
        cart_payment_type_bonusCard: "Բոնուս քարտով վճարում",
        cart_label_promoCode: "Զեղչի կոդ",
        cart_promoCode_error: "Նման պրոմոկոդ գոյություն չունի",
        cart_promoCode_success: "Պրոմոկոդը գոյություն ունի",
        cart_promoCode_error_already_used: "Այս պրոմոկոդն արդեն օգտագործվել է",
        cart_promoCode_description: "Մուտքագրեք կոդ համարը, որպեսզի վճարեք պատվերի մինչև 10% -ը բոնուսներով: Բոնուսները դուրս գրելու համար հաջորդ քայլում ընտրեք վճարման եղանակը Առցանց վճարում",
        cart_btn_make_order: "Կատարել վճարում",
        cart_empty_cart: "Ձեր զամբյուղը դատարկ է",
        cart_not_enough_bonus_points: "Բոնուս քարտի միավորները բավարար չեն պատվեր կատարելու համար",


        //Navigation Bar
        nav_item_profile: "Profile-hy",
        nav_item_sign_in: "Sign In-hy",
        nav_item_notification: "Notification-hy",
        nav_item_favorite: "Favorite-hy",
        nav_item_cart: "My Cart-hy",
        nav_item_project_plan: "Project Plan-hy",
        nav_item_products: "Products-hy",
        nav_item_shipping: "Shipping-hy",
        nav_item_apps: "Apps-hy",
        nav_item_about_us: "About-hy",
        nav_item_contact_us: "Contact-hy",
        nav_item_help: "Help-hy",
        nav_item_blog: "Blog-hy",
        nav_item_home: "Home-hy",
        nav_item_mini_markets: "Mini Markets-hy",
        nav_item_categories: "Categories-hy",
        nav_item_privacy_policy: "Privacy policy-hy",
        nav_item_already_have_account: "Արդեն ունե՞ք հաշիվ",
        nav_item_registration_page_signIn: "Մուտք գործել",
        nav_item_bonus_card_back_to_account: "Վերադարնալ հաշիվ",

        //Mobile menu
        mobile_menu_login: "Մուտք",
        mobile_menu_placeholder_search: "Փնտրել",
        mobile_menu_btn_back: "Հետ",

        //Header Box
        header_default_address: "Yerevan, Armenia-hy",
        header_dropdown_title: "Help-hy",
        header_search_placeholder: "Search by name and by category…-hy",
        header_search_btn: "Search-hy",

        //Routs
        rout_item_home: "Գլխավոր",
        rout_item_search: "Որոնում",
        rout_item_mini_markets: "Մինի մարկետներ",
        rout_item_products_list: "Ապրանքների ցանկ",
        rout_item_discounted_products: "Զեղչված ապրանքներ",

        //Sort Part
        sort_select_label: "Սորտավորել ըստ",
        sort_select_placeholder: "Դասավորել ըստ",
        sort_type_price: 'Գնի',
        sort_type_updated_at: 'Ամսաթվի',

        //Homepage
        homepage_slider_btn_text: "Buy now-hy",
        homepage_btn_see_all: "See All-hy",
        homepage_banners_title: "Product of the Day-hy",
        homepage_categories_title: "Product of the Day-hy",
        homepage_mini_markets_title: "Mini markets-hy",
        homepage_project_plans_title: "Project Plan-hy",
        homepage_download_app_title: "Smartlife [applications] already available-hy",
        homepage_download_app_description: "Store in tightly closed manufacturer's packaging at temperatures from away from sources of heat and ignition-hy",
        homepage_download_app_btn: "Download-hy",
        homepage_info_first_btn: "Delivery free of charge - 120 km-hy",
        homepage_info_second_btn: "Spring deals starting at 8490 ֏ Shop now!-hy",
        homepage_special_products_title: "Cam assortment-hy",

        //Banners
        banner_title_mini_markets: "Մինի մարկետներ",
        banner_product_count_text: "ապրանք",

        //Products
        category_details_header: "Բոլոր ապրանքները",
        sub_categories_option_all: "Բոլորը",

        //Product Card
        product_card_add_to_cart_text: "Ավելացնել",
        product_card_to_order_text: "To Order",

        //Product Details
        product_details_code: "Code",
        product_details_like_product: "Favorite",
        product_details_share_product: "Share",
        product_details_share_dropdown_copy: "Copy url",
        product_details_share_dropdown_facebook: "Facebook",
        product_details_is_available: "In Stock",
        product_details_is_not_available: "Out on Stock",
        product_details_delivery_info: "120km free shipping",
        product_details_order_bottom_info_title: "Ways to get an order",
        product_details_order_bottom_info_first_item: "[Take on your own] in 30 minutes",
        product_details_order_bottom_info_second_item: "[Delivery] 2-3 days, free shipping",
        product_details_tabs_description: "Նկարագրություն-en",
        product_details_tabs_details: "Բնութագիր-en",
        product_details_tabs_character: "Մանրամասներ-en",
        product_details_similar_products: "Similar Products",
        product_details_copy_link_copy: "Copy",
        product_details_copy_link_copied: "Copied",
        product_details_bottom_left_text_orange_part: "in 30 minutes",
        product_details_bottom_left_text_last_part: "Take on your own",
        product_details_bottom_right_text_orange_part: "Delivery",
        product_details_bottom_right_text_last_part: "2-3 days, free shipping",


        //Rate
        rate_products_desktop_title: "Product",
        rate_products_mobile_title: "Product rate",
        rate_right_title: "Rate",
        rate_right_subtitle_rated: "Rated:",
        rate_right_subtitle_your_rate: "Your rate:",
        rate_btn_title: "Rate",
        already_rated: "Դուք արդեն գնահատել եք",


        //Profile Favorites
        favorites_title: "Հավանած ապրանքներ",
        favorites_empty_view: "Հավանած ապրանքներ չկան",

        //Profile Notifications
        notifications_title: "Ծանուցումներ",
        notifications_details_title: "Notification Details",
        notifications_status: "Կարգավիճակ",
        notifications_turn_off_text: "Անջատել ծանոցումերը",
        notification_delete_success: "Ծանուցումը հաջողությամբ ջնջվեց",
        notification_delete_button: "Ջնջել",
        notification_empty_view: "Այս պահին ծանուցում չունեք",

        //Profile Orders History
        orders_history_title: "Գնումերի պատմություն",
        orders_types_tabs_all_orders_tab: "Բոլորը",
        orders_empty_view: "Այս պահին ոչինչ չունեք կցված",
        orders_order_number_title: "Order Number:",
        orders_order_date_title: "Order Date:",
        orders_details_title: "Detail Order",
        orders_details_products_count: "Items",
        orders_details_address: "Address",
        orders_details_receiver: "Receiver",
        orders_details_date: "Date",
        orders_details_status: "Status",
        orders_right_btn_cancel_order: "Չեղարկել",
        orders_right_btn_rate_order: "Գնահատել",
        orders_cancel_order_modal_text: "Ցանկանու՞մ եք չեղարկել պատվերը",
        orders_right_btn_delete_order: "Ջնջել",
        order_total_amount: "Կազմած գումար",

        //Profile settings
        settings_title: "Կոնտակտային տվյալներ",
        settings_update_profile_image: "Update Image",
        settings_upload_profile_image: "Upload Image",
        settings_file_size_message: "File size is too big",
        settings_label_firstName: "Անուն",
        settings_label_lastName: "Ազգանուն",
        settings_label_phoneNumber: "Հեռ. համար",
        settings_label_email: "Email",
        settings_label_country: "Country",
        settings_label_city: "City",
        settings_label_address: "Հասցե",
        settings_btn_remember_user_data: "Հիշել տվյալները",

        //Profile Change Password
        change_password_label_old_password: "Հին գաղտնաբառ",
        change_password_placeholder_password: "Գաղտնաբառ",
        change_password_label_new_password: "Նոր գաղտնաբառ",
        change_password_label_repeat_password: "Կրկնել գաղտնաբառը",
        change_password_btn_change: "Փոխել գաղտնաբառը",

        //Buttons
        btn_loading_text: "Սպասել",
        btn_buy_now: "Գնել հիմա",

        //Select
        select_option_all: "Բոլորը",

        //Filters
        filters_title: "Ֆիլտրեր",
        filters_price_panel_title: "Գին",
        filters_price_inputs_description: "Գին",
        filters_btn_clear: "Մաքրել",

        //Map Modal
        map_modal_title: "Մուտքագրեք հասցեն",
        map_modal_label_residence_street: "Քաղաք, փողոց",
        map_modal_label_building: "Շենք",
        map_modal_label_building_entrance: "Մուտք",
        map_modal_label_building_floor: "Հարկ",
        map_modal_label_building_house: "Բնակարան",
        map_modal_btn_cancel: "Չեղարկել",
        map_modal_btn_save: "Պահպանել",

        //Footer
        footer_address_title: "Address-hy",
        footer_company_section_title: "Company-hy",
        footer_support_section_title: "Support-hy",
        footer_shoppers_section_title: "Shoppers-hy",
        footer_social_items_title: "Follow us-hy",
        footer_subscribe_title: "Subscribe-hy",
        footer_subscribe_description: "Subscribe, find out about discounts, deals, new products.-hy",
        footer_subscribe_placeholder: "Enter your email-hy",
        footer_subscribe_btn: "Subscribe-hy",
        footer_section_copyright_text: "© Copyright 2020 Online Supermarket All rights reserved.-hy",
        footer_section_developed_by_text: "Մշակումը ՝",

        //About
        about_contact_btn: "Contact us",
        about_green_content_title: "We empower people create economic opportunities, giving new ways to live safely and comfortably.",
        about_green_content_item_technology_title: "Technology",
        about_green_content_item_technology_description: "creates inspiring ecommerce experiences for our buyers, sellers and developers.",
        about_green_content_item_impact_title: "Impact",
        about_green_content_item_impact_description: "Every day, people build businesses on eBay. Nonprofit organizations raise vital funds.",
        about_green_content_item_recognition_title: "Recognition",
        about_green_content_item_recognition_description: "There are many variations of passages but the majority have suffered alter humour.",
        about_contact_part_title: "Contact us",
        about_contact_part_description: "Dear customer, you can contact us in these ways, we are always available and we are ready to help you with love.",
        about_send_message_title: "Send Message",
        about_send_message_description: "Temperatures from away from sources of heat ignition",
        about_send_message_name_placeholder: "Name",
        about_send_message_phone_placeholder: "Phone",
        about_send_message_email_placeholder: "Email address",
        about_send_message_text_placeholder: "Type text",
        about_send_message_btn: "Send",
        about_info_section_address_title: "Address",
        about_info_section_working_hours_title: "Working hours",
        about_info_section_phone_title: "Phone number",

        //Blog
        blog_tabs_tab_all: "All",
        blog_copy_link_text_copy: "Copy",
        blog_copy_link_text_copied: "Copied",

        //Help
        help_contact_btn: "Contact",
        help_project_plan_btn: "Project Plan",
        help_apps_title_first_part: "App",
        help_apps_title_orange_part: "Support",
        help_apps_description: "With the SmartLife app on your smartphone, tablet, or computer. Click here to get all the Ring app help you need",
        help_apps_download_btn: "Download",

        //Contacts
        contacts_page_title: "Կոնտակտներ",
        contacts_page_right_subTitle_history: "Կոնտակտներ",
        contacts_page_right_description: "Հարգելի հաճախորդներ, բոլոր հարցերի համար կարող եք կապվել կենտրոնի հետ հեռախոսհամարով Աշխատանքային ժամերին Հարգելի հաճախորդներ, բոլոր հարցերի համար կարող եք կապվել կենտրոնի հետ հեռախոսհամարով Աշխատանքային ժամերին 10: 00-ից 22: 00-ն",
        contacts_page_right_subTitle_contacts: "Կոնտակտային տվյալներ",
        contacts_page_form_title: "Ուղարկեք մեզ հաղորդագրոթյուն",
        contacts_page_placeholder_firstName: "Անուն",
        contacts_page_placeholder_email: "Էլ․ հասցե",
        contacts_page_placeholder_phoneNumber: "Հեռ.համար",
        contacts_page_placeholder_message: "Գրեք ձեր հաղորդագրոթյունը…",
        contacts_page_btn_send_message: "Ուղարկել հաղորդագրոթյուն",

        //Products List
        products_list_page_title: "Ապրանքների ցանկ",

        //Modal
        modal_create_account_text: "Հասնելիություն ձեռք բերելու համար անհրաժեշտ է ստեղծել հաշիվ:",
        modal_btn_register: "Գրանցվել",
        modal_not_available_text: "Այս պահին պահեստում առկա չէ",
        modal_attach_card_success: "Դուք հաջողությամբ կցել եք ձեր բոնուս քարտը",
        modal_btn_products_list: "Ապրանքնրի ցանկ",
        modal_order_success: "Ձեր գործարքը հաջողությամբ կատարվել է",
        modal_order_error: "Գործարքը չհաջողվեց կատարել",
        modal_error_title: "Ցավում ենք",
        modal_success_title: "Շնորհավորում ենք",
        modal_warning_title: "Զգուշացում-ru",
        modal_cart_has_not_available_product: "Զամբյուղում կան ոչ հասանելի ապրանքներ",
        modal_close_modal_btn: "Փակել",
        modal_cancel_modal_btn: "Չեղարկել",
        modal_see_order_btn: "Տեսնել պատվերը",
        request_modal_title: "Dear customer, this product is currently out of stock",
        request_modal_description: "If you want to order from this product, please specify your phone number, then our specialist will be contacted you for approval.",
    },
    ru: {
        //Notification defaults
        notification_default_error: "Ինչ որ բան այն չէ. Խնդրում ենք կրկին փորձել!-ru",
        notification_default_success: "Գործողությունը հաջողությամբ կատարվեց-ru",
        notification_user_account_blocked_error: "Հարգելի օգտատեր Ձեր հաշիվը ապաակտիվացված է, խնդրում ենք կապ հաստատել %dի հետ-ru",
        notification_user_account_blocked_service_center: "սպասարկման կենտրոն",

        //Sign-in
        login_error_invalid_username: "Սխալ օգտանուն-ru",
        login_error_invalid_password: "Սխալ գաղտնաբառ-ru",
        login_error_blocked_account: "Այս հաշիվը արգելափակված է-ru",
        login_error_unverified_account: "UNVERIFIED_ACCOUNT-ru",
        login_form_title: "Welcome",
        login_placeholder_email: "Адрес эл. почты",
        login_placeholder_password: "Введите пароль",
        login_btn_title_login: "войти",
        login_forgot_password_text: "Забыли Ваш пароль?",
        login_btn_title_register: "Գրանցվել-ru",
        login_header_text_signIn: "Enter your credentials to access your account",
        login_with_google: "Sign in with Google",
        login_with_facebook: "Sign in with Facebook",
        login_with_apple: "Sign in with Apple",
        login_dont_have_account: "Don't have an account yet?",
        login_create_an_account: "Create an account",

        //Sign-up
        sign_up_success: "Հաստատման հաղորդագրությունը կուղարկվի %d էլ․ հասցեին: Ձեր բոլոր հնարավորություններից օգտվելու համար հաստատեք ձեր էլ. Փոստի հասցեն-ru",
        sign_up_error_exist_username: "Օգտանունն արդեն գոյություն ունի-ru",
        sign_up_header_text_for_signUp: "Enter your details to create a new account-ru",
        sign_up_form_title: "Welcome",
        sign_up_placeholder_firstName: "Имя",
        sign_up_placeholder_lastName: "Фамилия",
        sign_up_placeholder_email: "Адрес эл. почты",
        sign_up_placeholder_password: "Пароль",
        sign_up_placeholder_repeat_password: "Повторите пароль",
        sign_up_btn_title_register: "Регистр",
        sign_up_already_have_account: "Уже есть учетной записи?",
        sign_up_navigate_to_account: "Войди аккаунт-en",
        sign_up_agreement_text: "I agree to the terms of the sale, the privacy policy.",

        //Account Activation
        activation_success: "Ձեր հաշիվը հաջողությամբ ակտիվացված է: Խնդրում ենք մուտք գործել ՝ շարունակելու համար-ru",
        activation_error_link_date_expired: "Ակտիվացման ժամկետըը լրացել է: Խնդրում ենք նորից ուղարկել հաստատման հաղորդագրություն-ru",
        activation_error_account_already_activated: "Ձեր հաշիվը արդեն ակտիվ է: Խնդրում ենք մուտք գործել ՝ շարունակելու համար-ru",
        activation_error_account_invalid_code: "activation error account invalid code",

        //Verify Email
        resend_email_success: "Հաստատման հաղորդագրությունը կուղարկվի %d էլ․ հասցեին: Ձեր բոլոր հնարավորություններից օգտվելու համար հաստատեք ձեր էլ. Փոստի հասցեն-ru",
        resend_email_error_account_already_activated: "Ձեր հաշիվն ակտիվ է: Խնդրում ենք մուտք գործել ՝ շարունակելու համար-ru",
        activation_link_date_expired: "Ակտիվացման ժամկետըը լրացել է:-ru",
        activation_link_resend_email_text: "Խնդրում ենք նորից ուղարկել հաստատման հաղորդագրություն-ru",
        activation_link_resend_email_link: "Նորից ուղարկել հաստատման հաղորդագրություն-ru",
        verify_email_title: "Confirm your email",
        verify_email_description: "We sent a confirmation email to-ru",
        verify_email_description_not_seen: "If you do not see it yet?",
        verify_email_description_seconds: "վայրկյանից-ru",
        verify_email_send_code_btn: "Подтвердить",

        //Forgot Password
        forgot_email_send_success: "Հաջողությամբ ոsւղարկված է-ru",
        forgot_email_send_error: "%d օգտանունով օգտատեր գոյություն չունի:-ru",
        forgot_password_header_title: "Forgot password-ru",
        change_email_header_title: "Change email-ru",
        forgot_password_header_description: "Enter your credentials to access your account",
        forgot_password_placeholder_username: "Էլ․ հասցե-ru",
        forgot_password_btn_title_send: "Ուղարկել-ru",
        forgot_password_can_send_email: "%d վայրկյանից կրկին կարող եք ուղարկել հաղորդագրություն-ru",

        //User Profile
        profile_update_success: "Հաշիվը հաջողությամբ թարմացվել է-ru",
        password_update_success: "Գաղտնաբառը հաջողությամբ թարմացվել է-ru",
        password_update_error_not_valid: "Նշված գաղտնաբառը վավեր չէ-ru",
        profile_left_menu_settings: "Անձնական տվյալներ-ru",
        profile_left_menu_favorites: "Հավանած ապրանքներ-ru",
        profile_left_menu_orders: "Գնումերի պատմություն-ru",
        profile_left_menu_notifications: "Ծանոցումեր-ru",
        profile_left_menu_logout: "Դուրս գալ-ru",

        //Search
        search_empty_view: "Որոնման արդյունք չկա-ru",

        //Products
        products_empty_view: "Ապրանքը չի գտնվել-ru",

        //Not Found Page
        not_found_empty_view: "Ներեցեք, էջը չի գտնվել-ru",

        //Agreement text
        agreement_text_sale_terms: "Ես համաձայն եմ վաճառքի պայմաններին,-ru",
        agreement_text_privacy: "գաղտնիության-ru",
        agreement_text_and: "և-ru",
        agreement_text_personal_data_processing_policy: "անձնական տվյալների մշակման քաղաքականությանը-ru",

        //Cart
        cart_products_count: "There are %d products-ru",
        cart_product_is_not_available: "Out on Stock",
        cart_clear_cart: "Մաքրել զամբյուղը-ru",
        cart_discount_size: "Զեղչի չափ-ru",
        cart_order_details_title: "Payment Details",
        cart_order_details_all_products: "All Products",
        cart_order_details_amount: "Order Amount",
        cart_order_details_shipping: "Shipping",
        cart_order_details_discount: "Discount",
        cart_order_details_total_price: "Total Price",
        cart_send_promoCode_btn: "SEND",
        cart_order_address_title: "Select Shipping Address",
        cart_label_delivery_address: "Շենք, Մուտք, Հարկ, Բնակարան-ru",
        cart_label_name: "Անուն Ազգանուն-ru",
        cart_label_phoneNumber: "Հեռ․ համար-ru",
        cart_label_email: "Էլ․ հասցե-ru",
        cart_payment_type_online: "Առցանց վճարում-ru",
        cart_payment_type_cash: "Կանխիկ վճարում-ru",
        cart_payment_type_bonusCard: "Բոնուս քարտով վճարում-ru",
        cart_label_promoCode: "Զեղչի կոդ-ru",
        cart_promoCode_error: "Նման պրոմոկոդ գոյություն չունի-ru",
        cart_promoCode_success: "Պրոմոկոդը գոյություն ունի-ru",
        cart_promoCode_error_already_used: "Այս պրոմոկոդն արդեն օգտագործվել է-ru",
        cart_promoCode_description: "Մուտքագրեք կոդ համարը, որպեսզի վճարեք պատվերի մինչև 10% -ը բոնուսներով: Բոնուսները դուրս գրելու համար հաջորդ քայլում ընտրեք վճարման եղանակը Առցանց վճարում-ru",
        cart_btn_make_order: "Կատարել վճարում-ru",
        cart_empty_cart: "Ձեր զամբյուղը դատարկ է-ru",
        cart_link_to_products_list: "Ապրանքների ցանկ-ru",
        cart_not_enough_bonus_points: "Բոնուս քարտի միավորները բավարար չեն պատվեր կատարելու համար-ru",


        //Navigation Bar
        nav_item_profile: "Profile-ru",
        nav_item_sign_in: "Sign In-ru",
        nav_item_notification: "Notification-ru",
        nav_item_favorite: "Favorite-ru",
        nav_item_cart: "My Cart-ru",
        nav_item_project_plan: "Project Plan-ru",
        nav_item_products: "Products-ru",
        nav_item_shipping: "Shipping-ru",
        nav_item_apps: "Apps-ru",
        nav_item_about_us: "About-ru",
        nav_item_contact_us: "Contact-ru",
        nav_item_help: "Help-ru",
        nav_item_blog: "Blog-ru",
        nav_item_home: "Home-ru",
        nav_item_mini_markets: "Mini Markets-ru",
        nav_item_categories: "Categories-ru",
        nav_item_privacy_policy: "Privacy policy-ru",
        nav_item_already_have_account: "Արդեն ունե՞ք հաշիվ-ru",
        nav_item_registration_page_signIn: "Մուտք գործել-ru",
        nav_item_bonus_card_back_to_account: "Վերադարնալ հաշիվ-ru",

        //Mobile menu
        mobile_menu_login: "Մուտք-ru",
        mobile_menu_placeholder_search: "Փնտրել-ru",
        mobile_menu_btn_back: "Հետ-ru",

        //Header Box
        header_default_address: "Yerevan, Armenia-ru",
        header_dropdown_title: "Help-ru",
        header_search_placeholder: "Search by name and by category…-ru",
        header_search_btn: "Search-ru",

        //Routs
        rout_item_home: "Գլխավոր-ru",
        rout_item_search: "Որոնում-ru",
        rout_item_mini_markets: "Մինի մարկետներ-ru",
        rout_item_products_list: "Ապրանքների ցանկ-ru",
        rout_item_discounted_products: "Զեղչված ապրանքներ-ru",

        //Sort Part
        sort_select_label: "Սորտավորել ըստ-ru",
        sort_select_placeholder: "Դասավորել ըստ-ru",
        sort_type_price: 'Գնի-ru',
        sort_type_updated_at: 'Ամսաթվի-ru',

        //Homepage
        homepage_slider_btn_text: "Buy now-ru",
        homepage_btn_see_all: "See All-ru",
        homepage_banners_title: "Product of the Day-ru",
        homepage_categories_title: "Product of the Day-ru",
        homepage_mini_markets_title: "Mini markets-ru",
        homepage_project_plans_title: "Project Plan-ru",
        homepage_download_app_title: "Smartlife [applications] already available-ru",
        homepage_download_app_description: "Store in tightly closed manufacturer's packaging at temperatures from away from sources of heat and ignition-ru",
        homepage_download_app_btn: "Download-ru",
        homepage_info_first_btn: "Delivery free of charge - 120 km-ru",
        homepage_info_second_btn: "Spring deals starting at 8490 ֏ Shop now!-ru",
        homepage_special_products_title: "Cam assortment-ru",

        //Banners
        banner_title_mini_markets: "Մինի մարկետներ-ru",
        banner_product_count_text: "ապրանք-ru",

        //Products
        category_details_header: "Բոլոր ապրանքները-ru",
        sub_categories_option_all: "Բոլորը-ru",

        //Product Card
        product_card_add_to_cart_text: "Add to Cart-ru",
        product_card_to_order_text: "To Order-ru",

        //Product Details
        product_details_code: "Code",
        product_details_like_product: "Favorite",
        product_details_share_product: "Share",
        product_details_share_dropdown_copy: "Copy url",
        product_details_is_available: "In Stock",
        product_details_is_not_available: "Out on Stock",
        product_details_delivery_info: "120km free shipping",
        product_details_order_bottom_info_title: "Ways to get an order",
        product_details_order_bottom_info_first_item: "[Take on your own] in 30 minutes",
        product_details_order_bottom_info_second_item: "[Delivery] 2-3 days, free shipping",
        product_details_tabs_description: "Նկարագրություն-en",
        product_details_tabs_details: "Բնութագիր-en",
        product_details_tabs_character: "Մանրամասներ-en",
        product_details_similar_products: "Similar Products",
        product_details_copy_link_copy: "Copy",
        product_details_copy_link_copied: "Copied",
        product_details_bottom_left_text_orange_part: "Take on your own",
        product_details_bottom_left_text_last_part: "in 30 minutes",
        product_details_bottom_right_text_orange_part: "Delivery",
        product_details_bottom_right_text_last_part: "2-3 days, free shipping",

        //Rate
        rate_products_desktop_title: "Product",
        rate_products_mobile_title: "Product rate",
        rate_right_title: "Rate",
        rate_right_subtitle_rated: "Rated:",
        rate_right_subtitle_your_rate: "Your rate:",
        rate_btn_title: "Rate",
        already_rated: "Вы уже оценили",

        //Profile Favorites
        favorites_title: "Հավանած ապրանքներ-ru",
        favorites_empty_view: "Հավանած ապրանքներ չկան-ru",

        //Profile Notifications
        notifications_title: "Ծանուցումներ-ru",
        notifications_details_title: "Notification Details",
        notifications_status: "Կարգավիճակ -ru",
        notifications_turn_off_text: "Անջատել ծանոցումերը -ru",
        notification_delete_success: "Ծանուցումը հաջողությամբ ջնջվեց-ru",
        notification_delete_button: "Ջնջել-ru",
        notification_empty_view: "Այս պահին ծանուցում չունեք-ru",

        //Profile Orders History
        orders_history_title: "Գնումերի պատմություն-ru",
        orders_types_tabs_all_orders_tab: "Բոլորը-ru",
        orders_empty_view: "Այս պահին ոչինչ չունեք կցված-ru",
        orders_order_number_title: "Order Number:",
        orders_order_date_title: "Order Date:",
        orders_details_title: "Detail Order",
        orders_details_products_count: "Items",
        orders_details_address: "Address",
        orders_details_receiver: "Receiver",
        orders_details_date: "Date",
        orders_details_status: "Status",
        orders_right_btn_cancel_order: "Չեղարկել-ru",
        orders_right_btn_rate_order: "Գնահատել-ru",
        orders_cancel_order_modal_text: "Ցանկանու՞մ եք չեղարկել պատվերը-ru",
        orders_right_btn_delete_order: "Ջնջել-ru",
        order_total_amount: "Կազմած գումար-ru",

        //Profile settings
        settings_title: "Կոնտակտային տվյալներ-ru",
        settings_update_profile_image: "Update Image",
        settings_upload_profile_image: "Upload Image",
        settings_file_size_message: "File size is too big",
        settings_label_firstName: "Անուն-ru",
        settings_label_lastName: "Ազգանուն-ru",
        settings_label_phoneNumber: "Հեռ. համար-ru",
        settings_label_email: "Email-ru",
        settings_label_country: "Country-ru",
        settings_label_city: "City-ru",
        settings_label_address: "Հասցե-ru",
        settings_btn_remember_user_data: "Հիշել տվյալները-ru",

        //Profile Change Passwo-rurd
        change_password_label_old_password: "Հին գաղտնաբառ-ru",
        change_password_placeholder_password: "Գաղտնաբառ-ru",
        change_password_label_new_password: "Նոր գաղտնաբառ-ru",
        change_password_label_repeat_password: "Կրկնել գաղտնաբառը-ru",
        change_password_btn_change: "Փոխել գաղտնաբառը-ru",

        //Buttons
        btn_loading_text: "Սպասել-ru",
        btn_buy_now: "Գնել հիմա-ru",

        //Select
        select_option_all: "Բոլորը-ru",

        //Filters
        filters_title: "Ֆիլտրեր-ru",
        filters_price_panel_title: "Գին-ru",
        filters_price_inputs_description: "Գին-ru",
        filters_btn_clear: "Մաքրել-ru",

        //Map Modal
        map_modal_title: "Մուտքագրեք հասցեն-ru",
        map_modal_label_residence_street: "Քաղաք, փողոց-ru",
        map_modal_label_building: "Շենք-ru",
        map_modal_label_building_entrance: "Մուտք-ru",
        map_modal_label_building_floor: "Հարկ-ru",
        map_modal_label_building_house: "Բնակարան-ru",
        map_modal_btn_cancel: "Չեղարկել-ru",
        map_modal_btn_save: "Պահպանել-ru",

        //Footer
        footer_address_title: "Address-ru",
        footer_company_section_title: "Company-ru",
        footer_support_section_title: "Support-ru",
        footer_shoppers_section_title: "Shoppers-ru",
        footer_social_items_title: "Follow us-ru",
        footer_subscribe_title: "Subscribe-ru",
        footer_subscribe_description: "Subscribe, find out about discounts, deals, new products.-ru",
        footer_subscribe_placeholder: "Enter your email-ru",
        footer_subscribe_btn: "Subscribe-ru",
        footer_section_copyright_text: "© Copyright 2020 Online Supermarket All rights reserved.-ru",
        footer_section_developed_by_text: "Մշակումը ՝-ru",

        //About
        about_contact_btn: "Contact us",
        about_green_content_title: "We empower people create economic opportunities, giving new ways to live safely and comfortably.",
        about_green_content_item_technology_title: "Technology",
        about_green_content_item_technology_description: "creates inspiring ecommerce experiences for our buyers, sellers and developers.",
        about_green_content_item_impact_title: "Impact",
        about_green_content_item_impact_description: "Every day, people build businesses on eBay. Nonprofit organizations raise vital funds.",
        about_green_content_item_recognition_title: "Recognition",
        about_green_content_item_recognition_description: "There are many variations of passages but the majority have suffered alter humour.",
        about_contact_part_title: "Contact us",
        about_contact_part_description: "Dear customer, you can contact us in these ways, we are always available and we are ready to help you with love.",
        about_send_message_title: "Send Message",
        about_send_message_description: "Temperatures from away from sources of heat ignition",
        about_send_message_name_placeholder: "Name",
        about_send_message_phone_placeholder: "Phone",
        about_send_message_email_placeholder: "Email address",
        about_send_message_text_placeholder: "Type text",
        about_send_message_btn: "Send",
        about_info_section_address_title: "Address",
        about_info_section_working_hours_title: "Working hours",
        about_info_section_phone_title: "Phone number",

        //Blog
        blog_tabs_tab_all: "All",
        blog_copy_link_text_copy: "Copy",
        blog_copy_link_text_copied: "Copied",

        //Help
        help_contact_btn: "Contact",
        help_project_plan_btn: "Project Plan",
        help_apps_title_first_part: "App",
        help_apps_title_orange_part: "Support",
        help_apps_description: "With the SmartLife app on your smartphone, tablet, or computer. Click here to get all the Ring app help you need",
        help_apps_download_btn: "Download",

        //Contacts
        contacts_page_title: "Կոնտակտներ-ru",
        contacts_page_left_subTitle_history: "Կոնտակտներ-ru",
        contacts_page_left_description: "Հարգելի հաճախորդներ, բոլոր հարցերի համար կարող եք կապվել կենտրոնի հետ հեռախոսհամարով Աշխատանքային ժամերին Հարգելի հաճախորդներ, բոլոր հարցերի համար կարող եք կապվել կենտրոնի հետ հեռախոսհամարով Աշխատանքային ժամերին 10: 00-ից 22: 00-ն-ru",
        contacts_page_left_subTitle_contacts: "Կոնտակտային տվյալներ-ru",
        contacts_page_form_title: "Ուղարկեք մեզ հաղորդագրոթյուն-ru",
        contacts_page_placeholder_firstName: "Անուն-ru",
        contacts_page_placeholder_email: "Էլ․ հասցե-ru",
        contacts_page_placeholder_phoneNumber: "Հեռ.համար-ru",
        contacts_page_placeholder_message: "Գրեք ձեր հաղորդագրոթյունը…-ru",
        contacts_page_btn_send_message: "Ուղարկել հաղորդագրոթյուն-ru",

        //Products List
        products_list_page_title: "Ապրանքների ցանկ-ru",

        //Modal
        modal_create_account_text: "Հասնելիություն ձեռք բերելու համար անհրաժեշտ է ստեղծել հաշիվ:-ru",
        modal_btn_register: "Գրանցվել-ru",
        modal_not_available_text: "Այս պահին պահեստում առկա չէ-ru",
        modal_attach_card_success: "Դուք հաջողությամբ կցել եք ձեր բոնուս քարտը-ru",
        modal_btn_products_list: "Ապրանքնրի ցանկ-ru",
        modal_order_success: "Ձեր գործարքը հաջողությամբ կատարվել է-ru",
        modal_order_error: "Գործարքը չհաջողվեց կատարել-ru",
        modal_error_title: "Ցավում ենք-ru",
        modal_success_title: "Շնորհավորում ենք-ru",
        modal_warning_title: "Զգուշացում-ru",
        modal_cart_has_not_available_product: "Զամբյուղում կան ոչ հասանելի ապրանքներ",
        modal_close_modal_btn: "Փակել",
        modal_cancel_modal_btn: "Չեղարկել",
        modal_see_order_btn: "Տեսնել պատվերը",
        request_modal_title: "Dear customer, this product is currently out of stock",
        request_modal_description: "If you want to order from this product, please specify your phone number, then our specialist will be contacted you for approval.",
    },
    "priceFormattingRegExp": "/\B(?=(\d{3})+(?!\d))/g",
    "renovatedPrice": "550000"
}
