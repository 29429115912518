// import packages
import React, {useEffect} from "react";
import {connect} from "react-redux";

// Import assets
import "./homepage.scss"

// Import Components
import MainSlider from "./mainSlider/MainSlider";
import Banners from "./banners/Banners";
import Categories from "./categories/Categories";
import Markets from "./markets/Markets";
import ProjectPlan from "./projectPlan/ProjectPlan";

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetSpecialProducts} from "../../redux/actions";
import ProductsSlider from "../../components/productsSlider/ProductsSlider";

function Homepage(props) {
    useEffect(() => {
            props.GetSpecialProducts()
    }, []);
    const {mainSlider, markets, staticTexts, projects} = props;

    return <div className={'Homepage'}>
        <div className={"SlidersWrapper"}>
            {!!props.mainSlider?.length && <MainSlider mainSlider={mainSlider} staticTexts={staticTexts}/>}
            {!!props.banners?.length && <Banners banners={props.banners} staticTexts={staticTexts}/>}
        </div>
        {!!props.categories?.length && <Categories banners={props.categories}/>}

        <Markets markets={markets} staticTexts={staticTexts} count={2}/>
        <ProductsSlider products={props?.specialProducts}
                        title={staticTexts?.homepage_special_products_title}/>
        <ProjectPlan plans={projects} staticTexts={staticTexts}/>
    </div>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    // names (type - string) of props
    'staticTexts',
    'isLoggedIn',
    'mainSlider',
    'markets',
    'banners',
    'categories',
    'specialProducts',
    'projects',
]);

const mapDispatchToProps = {
    GetSpecialProducts
    //redux actions
};

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
