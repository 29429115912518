// import packages
import React, { useEffect } from "react";
import { useLocation, useHistory } from 'react-router-dom'
import { getPropsFromState } from "../redux/mapStateToProps";
import { connect } from "react-redux";
import queryString from 'query-string'
import {
    OrderPaymentCallback,
    clearUserCart
} from "../redux/actions";

function PaymentCallback (props) {
    let location = useLocation();
    let history = useHistory();
    useEffect(() => {
        const queryParams = queryString.parse(location.search);
        const { orderId } = queryParams || {}
        if (orderId) {
            props.OrderPaymentCallback(orderId).then((res) => {
                const order = res.data
                props.clearUserCart()
                history.push(`/profile/orders/${order?.orderNumber}`)
            }).catch(() => {
                history.push('/')
            })
        } else {
            history.push('/')
        }
    }, [])
    return <div className="payment-callback">
        Loading ...
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [])
};
const mapDispatchToProps = {
    OrderPaymentCallback,
    clearUserCart
}
export default connect(mapStateToProps, mapDispatchToProps)(PaymentCallback);
