// import packages
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {connect} from "react-redux";

//import assets
import "./header.scss";

// Import Components
import HeaderBox from "./headerBox/HeaderBox";
import NavigationRow from "./navigationRow/NavigationRow";

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {toggleNotificationRow, LogOut} from "../../redux/actions";
import NotificationRow from "./notificationRow/NotificationRow";

function Header(props) {
    const location = useLocation();
    const {
        showAuthHeader, isLoggedIn, loggedInUser, staticTexts, contacts, notificationRowData, hideHeader
    } = props;


    return <div className={`header-wrapper ${showAuthHeader ? 'hide-sticky-header' : ''}`}>
        {!hideHeader && <HeaderBox isLoggedIn={isLoggedIn}
                    loggedInUser={loggedInUser}
                    LogOut={props.LogOut}
                    mobileMenuIsOpen={props.mobileMenuIsOpen}
                    toggleMobileMenu={props.toggleMobileMenu}/>}
        <NotificationRow data={notificationRowData}
                         toggleNotificationRow={props.toggleNotificationRow}/>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'isLoggedIn',
        'loggedInUser',
        'staticTexts',
        'notificationRowData',
    ])
};

const mapDispatchToProps = {
    toggleNotificationRow,
    LogOut
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
