// import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//Import assets
import './customPages.scss';

//Import Utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetCustomPages} from "../../redux/actions";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";

class CustomPage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const url = this.props?.match?.params?.id;
        this.props.GetCustomPages(url);
    }

    render() {
        const {customPages} = this.props;

        return <div className="custom-page-wrapper">
            <div className="custom-info-section">
                <div className="image-wrapper">
                    <img src={generateImageMediaUrl(customPages?.mediaMain?.path)} alt=""/>
                </div>
                <div className="custom-info-content">
                    <div className="custom-info-title">Let's talk in more detail,
                        Why do you need all this
                    </div>
                    <div className="custom-info-description"
                         dangerouslySetInnerHTML={{
                             __html: customPages?.content
                         }}/>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'customPages',
    ])
};

const mapDispatchToProps = {GetCustomPages};

export default connect(mapStateToProps, mapDispatchToProps)(CustomPage);