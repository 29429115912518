import {CART_CONSTS, AUTH_CONSTS} from "../constants";

export const initialState = {
    userCart: [],
    address: {
        mainAddress: '',
        lat: "",
        lng: "",
        secondaryAddress: '',
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CART_CONSTS.GET_CART_PRODUCTS:
            let newCartList = [];
            action.payload.product?.products.forEach(item => {
                state.userCart.map(cart => {
                    if (item.id === cart.product.id) {
                        newCartList.push({
                            ...cart,
                            product: item
                        })
                    }
                })
            });
            return {
                ...state,
                userCart: newCartList
            };

        case CART_CONSTS.ADD_TO_CART:
            const {product, quantity} = action.payload;
            // console.log(product, quantity)
            let newProduct = {
                quantity: 1,
                product: product,
                totalPrice: (product?.price) || 0
            };
            return {
                ...state,
                userCart: [
                    newProduct, ...state.userCart
                ],
            };

        case CART_CONSTS.REMOVE_FROM_CART:
            return {
                ...state,
                userCart: state.userCart.filter(item => item?.product?.id !== action.payload)
            };

        case CART_CONSTS.CHANGE_PRODUCT_COUNT_IN_CART:
            return {
                ...state,
                userCart: state.userCart.map(item => item?.product?.id === action.payload.id ? {
                    ...item,
                    quantity: action.payload.quantity,
                    totalPrice: (Number(item?.product?.price) * action.payload.quantity) || 0
                } : item)
            };

        case CART_CONSTS.CLEAR_USER_CART:
            return {
                ...state,
                userCart: [],
            };

        case CART_CONSTS.ADD_ADDRESS:
            return {
                ...state,
                address: {
                    mainAddress: action.payload.mainAddress,
                    lat: action.payload.lat,
                    lng: action.payload.lng,
                    secondaryAddress: action.payload.secondaryAddress
                },
            };
        case AUTH_CONSTS.LOG_OUT:
            return {
                ...state,
                // ...initialState
            };
        default:
            return state;
    }
}
