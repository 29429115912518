// import packages
import React, {memo} from "react";
import {connect} from "react-redux";

//Import assets
import "./orderCard.scss";

//Import Components

// Import utils
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import moment from "moment";


const OrderCard = memo((props) => {
    const {data, className, staticTexts} = props;

    return <div className={`order-card-wrapper ${className ? className : ""}`}>
        <div className="left-info">
            <div className="info-item">{staticTexts?.orders_order_number_title} <span>{data?.orderNumber}</span></div>
            <div className="info-item">{staticTexts?.orders_order_date_title} <span>{moment(data.createdAt).format("DD MMM YYYY")}</span></div>
        </div>
        <div style={{background: data?.status?.color + "24", color: data?.status?.color}} className="right-status">
            <span>{data?.status?.title}</span>
        </div>
    </div>
});

const mapStateToProps = (state) => getPropsFromState(state, []);

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrderCard);


