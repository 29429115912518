// import packages
import React, {useRef, useState} from "react";
import {connect} from "react-redux";
import {Link, NavLink, useLocation} from "react-router-dom";

//import assets
import './headerBox.scss'
import {
    Logo,
    CartIcon,
    UserIcon,
    NotificationIcon,
    ProfileFavIcon
} from "../../../assets/images";

// Import Components
import SearchSuggestions from "../searchSuggestions/SearchSuggestions";
import MobileMenu from "../mobileMenu/MobileMenu";


// Import utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {generateImageMediaUrl} from "../../../utils/generateMediaUrl";
import {resizeImage} from "../../../utils/resizeImage";

function HeaderBox(props) {
    const {isLoggedIn, loggedInUser, mobileMenuIsOpen, categories, staticTexts} = props;
    const [dropdownMenuIsOpen, setDropdownMenuIsOpen] = useState(false);
    const [firstParent, setFirstParent] = useState(undefined);
    // const [secondParent, setSecondParent] = useState(undefined);

    return <div className='header-box'>
        <div className="content-wrapper">
            <div className="header-left-part">
                <Link to={'/'} className="header-logo">
                    <Logo onClick={() => setDropdownMenuIsOpen(false)}/>
                </Link>
                <div className="header-center-wrapper">
                    <div className={`nav-bar-menu`}
                         onMouseEnter={() => setDropdownMenuIsOpen(true)}
                         onMouseLeave={() => setDropdownMenuIsOpen(false)}
                    >
                        <div className={`menu-btn ${dropdownMenuIsOpen ? 'active' : ''}`}>
                            <span className="line line-1"/>
                            <span className="line line-2"/>
                            <span className="line line-3"/>
                        </div>
                    </div>
                    <div className={`dropdown-menu-overlay ${!dropdownMenuIsOpen ? "expend-on" : ""}`}
                         onClick={() => setDropdownMenuIsOpen(!dropdownMenuIsOpen)}/>
                    <div
                        className={`categories-list-dropdown ${!dropdownMenuIsOpen ? "expend-on" : ""}`}
                        onMouseEnter={() => setDropdownMenuIsOpen(true)}
                        onMouseLeave={() => setDropdownMenuIsOpen(false)}>
                        <div className="dropdown-wrapper">
                            <div className="dropdown-content">
                                <div className="categories-list">
                                    <div className="categories-top-empty-wrapper"/>
                                    {
                                        categories && !!categories?.length && categories?.map((item, index) => {
                                            return <Link to={`/category/${item?.id}`}
                                                         key={item?.id}
                                                         className={`category-item ${firstParent === index ? 'active' : ''}`}
                                                         onClick={() => setDropdownMenuIsOpen(false)}
                                                         onMouseEnter={() => setFirstParent(index)}>
                                                <div>
                                                    {item?.title}
                                                </div>
                                            </Link>
                                        })
                                    }
                                </div>
                                {
                                    !!categories?.[firstParent]?.children?.length &&
                                    <div className="categories-first-sub">
                                        {
                                            categories?.[firstParent]?.children?.map((item, index) => {
                                                return <Link
                                                    to={`/category/${categories?.[firstParent].id}/${item.id}`}
                                                    key={item.id}
                                                    className="category-item"
                                                    onClick={() => setDropdownMenuIsOpen(false)}
                                                    // onMouseEnter={() => setSecondParent(index)}
                                                >
                                                    {item?.title}
                                                    {/*{!!item?.subCategories?.length && <div className="arrow-icon"/>}*/}
                                                </Link>
                                            })
                                        }
                                    </div>
                                }
                                {/*{categories[firstParent]?.children*/}
                                {/*&& !!categories[firstParent]?.children[secondParent]?.children?.length &&*/}
                                {/*<div className="categories-second-sub">*/}
                                {/*    {*/}
                                {/*        categories[firstParent]?.children[secondParent]?.children?.map(item => {*/}
                                {/*            return <Link to={{*/}
                                {/*                pathname: `/category/${item.id}`,*/}
                                {/*                state: {*/}
                                {/*                    firstParent: firstParent,*/}
                                {/*                    secondParent: secondParent*/}
                                {/*                }*/}
                                {/*            }} className="category-item" key={item?.id}>*/}
                                {/*                {item?.title}*/}
                                {/*            </Link>*/}
                                {/*        })*/}
                                {/*    }*/}
                                {/*</div>*/}
                                {/*}*/}
                            </div>
                        </div>
                    </div>
                    <SearchSuggestions/>
                </div>
            </div>
            <ul className="menu-items-list">
                <li onClick={() => setDropdownMenuIsOpen(false)}>
                    {isLoggedIn ?
                        <NavLink to={'/profile/settings'} className="menu-item"
                                 activeClassName='active'>

                            <div className={'user-image'}>
                                <img
                                    src={resizeImage(generateImageMediaUrl(loggedInUser?.profilePicturePath?.path, 'user'))}
                                    alt={""}/>
                            </div>

                            <span>{staticTexts?.nav_item_profile}</span>
                        </NavLink> :
                        <NavLink to={'/sign-in'} className="menu-item"
                                 activeClassName='active'>
                            <UserIcon title=""/>
                            <span>{staticTexts?.nav_item_sign_in}</span>
                        </NavLink>}
                </li>
                {isLoggedIn && <li onClick={() => setDropdownMenuIsOpen(false)}>
                    <NavLink to={`/profile/notifications`} className="menu-item notifications-item"
                             activeClassName='active'>
                        {!!props?.nonOpenedNotificationCount &&
                        <div className={`badge ${props?.nonOpenedNotificationCount < 10 ? '' : 'plus'}`}>
                            {props?.nonOpenedNotificationCount > 9 ? "9+" : (props?.nonOpenedNotificationCount > 0 ? props?.nonOpenedNotificationCount : '')}
                        </div>}
                        <NotificationIcon title=""/>
                        <span>{staticTexts?.nav_item_notification}</span>
                    </NavLink>
                </li>
                }
                {isLoggedIn && <li onClick={() => setDropdownMenuIsOpen(false)}>
                    <NavLink to={`/profile/favorites`} className="menu-item"
                             activeClassName='active'>
                        <ProfileFavIcon title=""/>
                        <span>{staticTexts?.nav_item_favorite}</span>
                    </NavLink>
                </li>
                }
                <li onClick={() => setDropdownMenuIsOpen(false)}>
                    <NavLink to={`/cart`} className="menu-item"
                             activeClassName='active'>
                        <div className={"cart-icon-wrapper"}>
                            {!!props.userCart?.length &&
                            <div className={`badge ${props.userCart?.length < 10 ? '' : 'plus'}`}>
                                {props.userCart?.length > 9 ? "9+" : (props.userCart?.length > 0 ? props.userCart?.length : '')}
                            </div>}
                            <CartIcon title=""/>
                        </div>
                        <span>{staticTexts?.nav_item_cart}</span>
                    </NavLink>
                </li>
            </ul>
        </div>

        {/*Mobile Menu*/}

        <div className="mobile-nav-bar">
            <div className="mobile-nav-top-side">
                <Link to={'/'} className="header-logo">
                    <Logo/>
                </Link>
                <div className="action-part">
                    {isLoggedIn ?
                        <Link to={'/profile/settings'} className="menu-item"
                              activeClassName='active'>
                            <div className={'user-image'}>
                                <img
                                    src={resizeImage(generateImageMediaUrl(loggedInUser?.profilePicturePath?.path, 'user'))}
                                    alt={""}/>
                            </div>
                        </Link> :
                        <Link to={'/sign-in'} className="menu-item"
                              activeClassName='active'>
                            <UserIcon title=""/>
                        </Link>}
                    {isLoggedIn &&
                    <NavLink to={`/profile/notifications`} className="menu-item notifications-item"
                             activeClassName='active'>
                        {!!props?.nonOpenedNotificationCount &&
                        <div className={`badge ${props?.nonOpenedNotificationCount < 10 ? '' : 'plus'}`}>
                            {props?.nonOpenedNotificationCount > 9 ? "9+" : (props?.nonOpenedNotificationCount > 0 ? props?.nonOpenedNotificationCount : '')}
                        </div>}
                        <NotificationIcon title=""/>
                    </NavLink>
                    }
                    {isLoggedIn &&
                    <NavLink to={`/profile/favorites`} className="menu-item"
                             activeClassName='active'>
                        <ProfileFavIcon title=""/>
                    </NavLink>
                    }
                    <NavLink to={`/cart`} className="menu-item"
                             activeClassName='active'>
                        {!!props.userCart?.length &&
                        <div className={`badge ${props.userCart?.length < 10 ? '' : 'plus'}`}>
                            {props.userCart?.length > 9 ? "9+" : (props.userCart?.length > 0 ? props.userCart?.length : '')}
                        </div>}
                        <CartIcon title=""/>
                    </NavLink>
                </div>
            </div>
            <div className="mobile-nav-bottom-side">
                <div className={`nav-bar-menu`}
                     onClick={props.toggleMobileMenu}>
                    <div className={`menu-btn ${mobileMenuIsOpen ? 'active' : ''}`}>
                        <span className="line line-1"/>
                        <span className="line line-2"/>
                        <span className="line line-3"/>
                    </div>
                </div>
                <SearchSuggestions/>
            </div>
            <MobileMenu categories={categories}
                        selectedAddress={props?.address?.mainAddress}
                        mobileMenuIsOpen={mobileMenuIsOpen}
                        toggleMobileMenu={props.toggleMobileMenu}
            />
        </div>
    </div>
}

const mapStateToProps = (state) =>
    getPropsFromState(state, [
        'categories',
        'staticTexts',
        "userCart",
        "nonOpenedNotificationCount",
        "address"
    ]);

const mapDispatchToProps = {
    // LogOut
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderBox)
