// import packages
import React, {useEffect} from "react";
import {connect} from "react-redux";

//Import assets
import './apps.scss'
import {
    AndroidImg, AppsBg,
    AppStore, GooglePlay,
    IosImg
} from "../../assets/images";

//Import components
import {PrimaryButton} from "../../components/uiElements/buttons/Buttons";

//Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {SendEmail, GetInformation} from "../../redux/actions";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {CustomHeader} from "../../components/uiElements/customHeader/CustomHeader";
import {androidUrl, iosUrl} from "../../configs/appUrls";


function Apps(props) {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        props.GetInformation()
    }, []);

    const {staticTexts} = props;

    return <div className="apps-page-wrapper">
        <CustomHeader
            image={AppsBg}
            title={staticTexts?.apps_title}
            description={staticTexts?.apps_description}
        />

        <div className="apps-info-section">
            <div className="image-wrapper">
                <img src={IosImg} alt=""/>
            </div>
            <div className="apps-info-content">
                <div className="apps-info-title">{staticTexts?.apps_section1_title}</div>
                <div className="apps-info-description">{staticTexts?.apps_section1_description}</div>
                <a
                    // href={iosUrl}
                   target={'_blank'} rel="noopener noreferrer"><AppStore/></a>
            </div>
        </div>

        <div className="apps-info-section second">
            <div className="apps-info-content">
                <div className="apps-info-title">{staticTexts?.apps_section2_title}</div>
                <div className="apps-info-description">{staticTexts?.apps_section2_description}</div>
                <a
                    // href={androidUrl}
                    target={'_blank'} rel="noopener noreferrer"><GooglePlay/></a>
            </div>
            <div className="image-wrapper">
                <img src={AndroidImg} alt=""/>
            </div>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'requestLoading',
        'selectedLanguage',
        'information',
    ])
};

const mapDispatchToProps = {
    SendEmail,
    GetInformation
}

export default connect(mapStateToProps, mapDispatchToProps)(Apps);
