import {AUTH_CONSTS, USER_CONSTS} from "../constants";

export const initialState = {
    loggedInUser: {},
    isLoggedIn: false,
    accessToken: null,
    refreshToken: null,
    userId: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case AUTH_CONSTS.LOG_IN:
            return {
                ...state,
                isLoggedIn: true,
                refreshToken: action.payload.refreshToken,
                accessToken: action.payload.accessToken,
                loggedInUser: action.payload.user,
                userId: action.payload.userId
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        case AUTH_CONSTS.REFRESH_TOKEN:
            return {
                ...state,
                refreshToken: action.payload.refreshToken,
                accessToken: action.payload.accessToken,
            };
        case USER_CONSTS.GET_CURRENT_USER:
            return {
                ...state,
                loggedInUser: action.payload,
            };
        case USER_CONSTS.UPDATE_CURRENT_USER:
            return {
                ...state,
                loggedInUser: {
                    ...action.payload,
                    profilePicturePath: {
                        ...action.payload?.profilePicturePath,
                        path: action.payload?.profilePicturePath ? `${action.payload.profilePicturePath?.path}?date=${Date.now()}` : undefined
                    }
                }
            };
        default:
            return state;
    }
}
