// import packages
import React, {Component} from "react";
import Slider from "react-slick";

//import assets
import "./banners.scss";

// Import Components
import {PrimaryButton, SliderButton} from "../../../components/uiElements/buttons/Buttons";

import {generateImageMediaUrl} from "../../../utils/generateMediaUrl";
import {SLIDER_URL_TYPES} from "../../../constants/constTypes";
import {history} from "../../../configs/history";
import BannerCard from "../../../components/uiElements/cards/bannerCard/BannerCard";
import moment from "moment";

// Import utils

class Banners extends Component {
    constructor() {
        super();
        this.state = {
            disabledPrev: true,
            disabledNext: false,
            timer: 0,
        };
    }

    componentDidMount() {
        let itemCount = this.getShowSlideCount();
        this.setState({
            ...this.state,
            disabledNext: this.props?.banners?.length - 1 < itemCount,
        });
    }

    getShowSlideCount = () => {
        let width = window.innerWidth;
        if (width > 1260) return 1;
        else if (width > 1000) return 4;
        else if (width > 700) return 3;
        else if (width > 460) return 2;
        else return 1;
    };

    afterSlideChange = (next) => {
        let itemCount = this.getShowSlideCount();
        this.setState({
            disabledPrev: next === 0,
            disabledNext: next + itemCount === this.props.banners?.length,
        });
    };

    openUrl = (banner) => {
        const path = banner?.product?.id &&
            `/product/${banner?.product?.productNumber}`;
        history.push({
            pathname: path
        })

    };

    setTime = (time) => {
        this.setState({
            timer: time
        })
    };

    render() {
        const {banners, staticTexts} = this.props;
        const {disabledPrev, disabledNext} = this.state;
        const settingsSlider = {
            dots: banners?.length < 7,
            arrows: false,
            infinite: false,
            speed: 400,
            autoplay: false,
            pauseOnHover: false,
            lazyLoad: 'progressive',
            beforeChange: (current, next) => this.afterSlideChange(next),
            ref: slider => (this.slider = slider),
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1260,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 1000,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 460,
                    settings: {
                        slidesToShow: 1,
                    }
                },
            ]
        };

        return !!banners?.length && <div className="banners-wrapper">
            <h2 className="banners-title">{staticTexts?.homepage_banners_title}</h2>
            <div className="image-wrapper">
                <Slider {...settingsSlider}>
                    {banners?.map(item => {
                        let activeBanner = moment().format('YYYY MM DD HH:mm:ss') < moment(item?.date).format('YYYY MM DD HH:mm:ss')
                        return activeBanner && <div onClick={() => this.openUrl(item)} key={item?.id}>
                            <BannerCard item={item} setTime={this.setTime}/>
                        </div>
                    })}
                </Slider>
                {
                    banners?.length > this.getShowSlideCount() &&
                    <SliderButton
                        disabledPrev={disabledPrev}
                        disabledNext={disabledNext}
                        cbPrev={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.slider.slickPrev()
                        }}
                        cbNext={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.slider.slickNext()
                        }}
                    />
                }
            </div>
        </div>
    }
}

export default Banners;
