// Import packages
import React, {PureComponent} from 'react';

// Import components
import {Helmet} from "react-helmet";

import {defaultTexts} from "../constants/defaultTexts";
import {FB_APP_ID} from "../constants/constants";
const logo = 'http://173.249.20.192:8703/images/logo.svg';

// Import utils

class MetaTags extends PureComponent {

    render() {
        const {title, description, image, url} = this.props;
        const _title = title ? (`${title} | ${defaultTexts.title}`) : defaultTexts.title;
        const _description = description || defaultTexts.description;
        let _image = logo;
        if (image) {
            _image = image;
        }
        const _url = url || window.location.href;
        const {hostname, port, protocol, origin} = window.location;
        const BASE_URL = origin;

        return (<Helmet>
                <title>{_title}</title>
                <meta name="description" content={_description}/>
                <meta property="og:image" content={_image}/>

                <meta property="og:title" content={_title}/>
                <meta property="og:site_name" content='smartlife.am'/>
                <meta property="og:description" content={_description}/>

                <meta property="og:url" content={_url}/>
                <meta property="og:image:width" content={600}/>
                <meta property="og:image:height" content={600}/>
                <meta property="og:type" content={'website'}/>
                <meta property="fb:app_id" content={FB_APP_ID}/>

                <link rel="manifest" href={`${BASE_URL}/manifest.json`}/>
                <link rel="shortcut icon" href={`${BASE_URL}/static/favicon.ico`}/>
                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:title" content={_title}/>
                <meta name="twitter:description" content={_description}/>
                <meta name="twitter:image" content={_image}/>
                <meta name="twitter:image:src" content={_image}/>
                <meta name="twitter:site" content="smartlife.am"/>
                <meta name="twitter:url" content={_url}/>
                <meta name="twitter:domain" content="smartlife.am"/>

            </Helmet>
        )
    }
}

export default MetaTags;
