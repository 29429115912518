// Import packages
import React, {useState} from "react"

// Import components
import {InputGroup} from "../../../components/uiElements/inputGroup/inputGroup";
import {PrimaryButton} from "../../../components/uiElements/buttons/Buttons";
import {EyeOutlined, EyeInvisibleOutlined} from '@ant-design/icons';

//import utils
import {NOTIFICATION_ROW_MODES} from "../../../constants/constTypes";


export function PasswordChangeBox(props) {
    const {staticTexts, loggedInUser} = props;

    const [password, setPassword] = useState({
        oldPassword: '',
        newPassword: '',
        repeatPassword: '',
    });
    const [passwordVisible, _setPasswordVisible] = useState({
        oldPassword: false,
        newPassword: false,
        repeatPassword: false,
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const [changePassword, setChangePassword] = useState(false);

    const {oldPassword, newPassword, repeatPassword} = password;

    function setPasswordVisible(type) {
        _setPasswordVisible({
            ...passwordVisible,
            [type]: !passwordVisible[type]
        })
    }

    function getPasswordInputValues(e) {
        const {name, value} = e.target;
        setPassword({
            ...password,
            [name]: value.trimStart(),
        })
    }

    function validateData() {
        const requiredFields = ['oldPassword', 'newPassword', 'repeatPassword'];
        let validationResult = true;
        const errorState = {};
        requiredFields.forEach(key => {
            if (!password[key]) {
                validationResult = false;
                errorState[key] = true;
            }
        });
        if (newPassword !== repeatPassword) {
            errorState.repeatPassword = true;
            validationResult = false;
        }
        if (newPassword?.length < 8) {
            errorState.newPassword = true;
            validationResult = false;
        }
        setErrors(errorState);
        return validationResult;
    }

    function updatePassword() {
        if (changePassword) {
            if (validateData()) {
                setLoading(true);
                const reqData = {
                    oldPassword,
                    newPassword,
                };
                props.UpdateCurrentUserPassword(reqData).then(() => {
                    setLoading(false);
                    props.toggleNotificationRow({
                        visible: true,
                        text: staticTexts?.password_update_success,
                        disappear: true,
                        mode: NOTIFICATION_ROW_MODES.SUCCESS
                    });
                    setPassword({
                        oldPassword: '',
                        newPassword: '',
                        repeatPassword: '',
                    });
                    setChangePassword(false)
                }).catch((error) => {
                    let errText = staticTexts?.notification_default_error;
                    if (error.response.status === 400) {
                        errText = staticTexts?.password_update_error_not_valid;
                    }
                    // if(error.response.message === "Specified password is the same as before.") {
                    //     errText = "Specified password is the same as before."
                    // }
                    props.toggleNotificationRow({
                        visible: true,
                        text: errText,
                        disappear: true,
                        mode: NOTIFICATION_ROW_MODES.ERROR
                    });
                    setLoading(false);
                });
            }
        } else {
            setChangePassword(true);
        }
    }

    return <div className="password-wrapper">
        {
            changePassword && <>
                <div className="hidden-email-wrapper">
                    <InputGroup
                        inputType={'input'}
                        type={"text"}>
                    </InputGroup>
                </div>
                <InputGroup
                    inputType={'input'}
                    type={`${passwordVisible.oldPassword ? "text" : "password"}`}
                    value={password.oldPassword}
                    className={'input-group'}
                    autocomplete={"new-password"}
                    placeholder={staticTexts?.change_password_label_old_password}
                    name={'oldPassword'}
                    error={errors.oldPassword}
                    onChange={getPasswordInputValues}>
                                <span className={'visibility-icon'}
                                      onClick={setPasswordVisible.bind(this, 'oldPassword')}>
                              {passwordVisible.oldPassword ? <EyeInvisibleOutlined/> : <EyeOutlined/>}
                       </span>
                </InputGroup>
                <InputGroup
                    inputType={'input'}
                    type={`${passwordVisible.newPassword ? "text" : "password"}`}
                    value={password.newPassword}
                    className={'input-group'}
                    placeholder={staticTexts?.change_password_label_new_password}
                    name={'newPassword'}
                    error={errors.newPassword}
                    onChange={getPasswordInputValues}>
                                  <span className={'visibility-icon'}
                                        onClick={setPasswordVisible.bind(this, 'newPassword')}>
                               {passwordVisible.newPassword ? <EyeInvisibleOutlined/> : <EyeOutlined/>}
                       </span>
                </InputGroup>
                <InputGroup
                    inputType={'input'}
                    type={`${passwordVisible.repeatPassword ? "text" : "password"}`}
                    value={password.repeatPassword}
                    className={'input-group'}
                    placeholder={staticTexts?.change_password_label_repeat_password}
                    name={'repeatPassword'}
                    error={errors.repeatPassword}
                    onChange={getPasswordInputValues}>
                                    <span className={'visibility-icon'}
                                          onClick={setPasswordVisible.bind(this, 'repeatPassword')}>
                               {passwordVisible.repeatPassword ? <EyeInvisibleOutlined/> : <EyeOutlined/>}
                       </span>
                </InputGroup>
            </>
        }
        {!props.loggedInUser.isSocial && <PrimaryButton
            loading={loading}
            loadingText={staticTexts?.btn_loading_text}
            title={staticTexts?.change_password_btn_change}
            disabled={loggedInUser?.isBlocked}
            className={'changePassword'}
            cb={updatePassword}
        />}
    </div>

}

export default PasswordChangeBox;
