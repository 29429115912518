import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {connect} from 'react-redux';

//import assets
import {SearchIcon, ClearIcon} from "../../../assets/images";
import './searchSuggestions.scss'

// Import utils
import {history} from "../../../configs/history";
import {GetProductSuggestions} from "../../../redux/actions";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {PRODUCTS_SEARCH_PAGES} from "../../../constants/constTypes";

function SearchSuggestions(props) {
    const location = useLocation();
    const [text, setText] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const {staticTexts} = props;

    useEffect(() => {
        if (!PRODUCTS_SEARCH_PAGES.some(page => location?.pathname?.startsWith(page.path))) {
            setText('');
            setSuggestions([]);
        } else {
            setText(location?.state?.text);
        }
    }, [location])

    function onEnter(e) {
        if (e.key === 'Enter') {
            text && search()
        }
    }

    function onChange(e) {
        const value = e.target.value;
        setText(value);
        if (value && value.length > 1) {
            GetProductSuggestions(value).then((suggestions) => {
                setSuggestions(suggestions)
            });
        } else {
            setSuggestions([])
        }
        !value &&
        PRODUCTS_SEARCH_PAGES.some(page => location?.pathname?.startsWith(page.path)) &&
        search('');
    }

    function search(value) {
        // inMobileMenu && toggleMobileMenu();
        const searchText = typeof value === 'string' ? value : text;
        if (searchText?.length !== 1) {
            const path = {
                state: {
                    text: searchText
                }
            }
            if (!PRODUCTS_SEARCH_PAGES.some(page => location?.pathname?.startsWith(page.path))) {
                path.pathname = '/search'
            }
            history.push(path)
        }
    }

    function selectSuggestion(text) {
        setSuggestions([]);
        setText(text);
        search(text);
    }

    return <div className="search_box">
        <SearchIcon className={'search-left-icon'}/>
        {!!text && <ClearIcon className={'clear-icon'}
                              onClick={() => {setText('')}}/>
        }
        <input value={text}
               onChange={onChange}
               onKeyDown={onEnter}
               maxLength={50}
               type="text"
               className="search"
               placeholder={staticTexts?.header_search_placeholder}
        />
        <ul className={`suggestions-wrapper ${suggestions?.length ? 'active' : ''}`}>
            {suggestions.map((item, index) => {
                return <li key={index}
                           className={'item'}
                           onClick={selectSuggestion.bind(null, item.text)}>
                    {item.text}
                </li>
            })}
        </ul>
        <div className="input-group-append">
            <button type="button" className="search_btn desktop" onClick={search}>
                {staticTexts?.header_search_btn}
            </button>
            <button type="button" className="search_btn mobile" onClick={search}>
                <SearchIcon/>
            </button>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'products',
        'staticTexts',
    ])
};

const mapDispatchToProps = {
    GetProductSuggestions
};


export default connect(mapStateToProps, mapDispatchToProps)(SearchSuggestions);

