import React, {Component} from "react";
import {connect} from "react-redux";
import {Checkbox} from "antd";
import {Link} from "react-router-dom";
import {history} from "../../configs/history";

//import assets
import './search.scss'
import {ListIcon, GridIcon, ArrowIcon, CategoryBannerImg, SortIcon, ClearIcon} from "../../assets/images";


//Import Components
import {ProductList} from "../productList/ProductList";
import {getCategoryParents} from "../../utils/helperFunctions";
import {PrimaryButton} from "../../components/uiElements/buttons/Buttons";
import Filters from "../../components/uiElements/filters/Filters";
import CategorySubItems from "../../components/uiElements/categorySubItems/CategorySubItems";

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {
    GetSingleCategory,
    GetProducts,
    GetFilters,
    ClearProducts,
    ClearProductFilters,
    toggleProductView,
} from "../../redux/actions";
import {PRODUCTS_SEARCH_PAGES, SLIDER_URL_TYPES} from "../../constants/constTypes";
import {MAX_PRICE, MIN_PRICE} from "../../constants/acceptedConsts";
import {InputGroup} from "../../components/uiElements/inputGroup/inputGroup";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";


class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryId: null,
            subCategoryId: null,
            filterValues: {},
            sort: "-createdAt",
            minPrice: MIN_PRICE,
            maxPrice: MAX_PRICE,
            pageType: PRODUCTS_SEARCH_PAGES.find(page => props.location.pathname.startsWith(page.path)).key,
            categoryBanner: {}
        };
        this.filters = React.createRef();
        this.loadMoreItems = this.loadMoreItems.bind(this);
        this.changeSortType = this.changeSortType.bind(this);
        if (props.history.action === 'POP' && props.products.state) {
            this.state = props.products.state
        }
    }

    async componentDidMount() {
        // if (this.props.history?.action !== 'POP') {
            window.scrollTo({
                top: 0,
                // behavior: "smooth"
            })
            this.parseLocationData();
            this.getFiltersList(false);
        // }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const currLocation = this.props?.location?.pathname;
        const prevLocation = prevProps?.location?.pathname;
        const currText = this.props?.location?.state?.text;
        const prevText = prevProps?.location?.state?.text;
        // console.log('updated', prevLocation, '->', currLocation)

        if (currLocation !== prevLocation) {
            // console.log('updated - location')
            this.parseLocationData();
            this.getFiltersList();
            return
        }
        if (currText !== prevText) {
            // console.log('updated - text')
            this.getProducts();
        }
        if (this.props?.selectedLanguage?.code !== prevProps?.selectedLanguage?.code) {
            this.getProducts()
            this.getFiltersList(false);
        }
    }

    componentWillUnmount() {
        // this.props.ClearProducts();
    }

    parseLocationData() {
        const categoryId = this.props?.match?.params?.category;
        const subCategoryId = this.props?.match?.params?.subCategory;
        // const text = this.props?.location?.state?.text;
        // console.log('categoryId', categoryId)
        // console.log('subCategoryId', subCategoryId)
        const selectedCategory = this.props.categories?.find(c => c.id === categoryId)
        this.setState({
            categoryId,
            subCategoryId,
            categoryBanner: selectedCategory?.banner?.id ? selectedCategory?.banner : {}
        }, () => this.getProducts());
    }

    getProducts = (changedData = {}) => {
        const {categoryId, subCategoryId, filterValues, sort, minPrice, maxPrice} = this.state;
        const text = this.props?.location?.state?.text;
        const reqData = {
            filterValueIds: [],
            sort: sort,
            text: text,
            category: '',
            minPrice: minPrice,
            maxPrice: maxPrice,
            ...changedData,
            state: JSON.parse(JSON.stringify(this.state))
        };
        if (categoryId) {
            reqData.category = categoryId
            subCategoryId && (reqData.category = subCategoryId)
        }
        Object.keys(filterValues).forEach(filterId => {
            const values = filterValues[filterId] || []
            reqData.filterValueIds.push(...values)
        })
        return this.props.GetProducts(reqData);
    };

    getCategory(categoryIds) {
        const {categories} = this.props;
        let category = '';
        categories.forEach((item) => {
            if (item.id === categoryIds) {
                category = item
            } else {
                item.children && item.children.length && item.children.forEach((subItem) => {
                    if (subItem.id === categoryIds) {
                        category = subItem
                    } else {
                        subItem.children && subItem.children.length && subItem.children.forEach((subItem2) => {
                            if (subItem2.id === categoryIds) {
                                category = subItem2
                            }
                        })
                    }
                })
            }
        });
        return category
    }

    clearSelectedFilters = () => {
        this.setState({
            filterValues: {},
            minPrice: MIN_PRICE,
            maxPrice: MAX_PRICE
        }, this.getProducts)
    }

    async loadMoreItems() {
        const {products} = this.props;
        if (!this.fetching) {
            this.fetching = true;
            await this.getProducts({
                offset: products?.list?.length,
                reset: false,
            });
            this.fetching = false;
        }
    };

    getFiltersList = (reset = true) => {
        const categoryId = this.props?.match?.params?.category;
        this.props.GetFilters(categoryId);
        Object.keys(this.state.filterValues).length && reset &&
        this.setState({
            filterValues: {},
        })
    }

    changeSortType = (name, value) => {
        this.setState({
            [name]: value
        }, this.getProducts)
    };

    getFilterValues = (id, values) => {
        this.setState({
            filterValues: {
                ...this.state.filterValues,
                [id]: values
            }
        }, this.getProducts)
    }

    getPriceValues = ({minPrice, maxPrice}) => {
        // console.log('get price', minPrice, maxPrice )
        if (this.state.minPrice !== minPrice || this.state.maxPrice !== maxPrice) {
            // console.log('price updated in search page')
            this.setState({
                minPrice,
                maxPrice
            }, this.getProducts)
        }
    }

    clearBanner = (e) => {
        e && e.preventDefault();
        e && e.stopPropagation();
        this.setState({
            categoryBanner: {}
        })
    }

    openUrl = () => {
        if (!!this.state.categoryBanner?.url) {
            window.open(this.state.categoryBanner?.url);
        }
    };

    render() {
        const {products, categories, staticTexts, requestLoading, productCardView} = this.props;
        const {sort, filterValues, minPrice, maxPrice, categoryId, subCategoryId, categoryBanner} = this.state;

        const market = {
            title: "Discounts and promotions",
            description: ""
        }
        const selectedCategory = categories?.find(c => c.id === categoryId)

        const sortTypesOptions = [
            {
                id: '-createdAt',
                name: <div>ըստ ամսաթվի &nbsp;<SortIcon className="rotate"/></div>
            },
            {
                id: 'createdAt',
                name: <div>ըստ ամսաթվի &nbsp;<SortIcon/></div>
            },
            {
                id: '-price',
                name: <div>ըստ գնի &nbsp;<SortIcon className="rotate"/></div>
            },
            {
                id: 'price',
                name: <div>ըստ գնի &nbsp;<SortIcon/></div>
            },
        ];

        const t = categoryBanner?.title || ""
        const start = t.slice(0, t.indexOf("["))
        const mid = t.slice(t.indexOf("[") + 1, t.lastIndexOf("]")) || ""
        const end = t.slice(t.lastIndexOf("]") + 1)
        const titleJSX = t.indexOf("[") !== t.lastIndexOf("]")
            ? <>{start}<span>{mid}</span>{end}</> : t

        return <div className="products-wrapper">
            <div className={'up-part'}>
                {categoryBanner?.id && <div className={`banner-wrapper ${this.state.categoryBanner?.url ? "link-banner": ""}`} onClick={this.openUrl}>
                    <ClearIcon className="clear-btn" onClick={this.clearBanner}/>
                    <div className="banner-content">
                        <div className='banner-item'>
                            <div className="image-wrapper">
                                <picture>
                                    <source media="(max-width:701px)"
                                            srcSet={generateImageMediaUrl(categoryBanner?.mediaMainMobile?.path)}/>
                                    <img src={generateImageMediaUrl(categoryBanner?.mediaMain?.path)}
                                         alt={categoryBanner?.mediaMain?.altAttribute ? categoryBanner.mediaMain?.altAttribute : ''}/>
                                </picture>
                                <div className="inner-side">
                                    <div>
                                        <div className="title">
                                            {titleJSX}
                                        </div>
                                        <div className="description">
                                            {categoryBanner?.description}
                                        </div>
                                    </div>
                                    {!!this.state.categoryBanner?.url && <div className="banner-btn">See more <ArrowIcon/></div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
                <div className={'products-header-part'}>
                    <div className={'products-header'}>
                        {/*<div className="rout">*/}
                        {/*    <span className="rout-item">*/}
                        {/*        <Link to={'/'}>{staticTexts?.rout_item_home}</Link>*/}
                        {/*    </span>*/}
                        {/*    {fromCategories && <span className="rout-item">*/}
                        {/*        <Link to={'/categories'}> / {staticTexts?.rout_item_products_list}</Link>*/}
                        {/*    </span>}*/}
                        {/*    {!selectedSubCategory && categoryFamily && categoryFamily.map(category => {*/}
                        {/*            return <span className="rout-item" key={category.id}>*/}
                        {/*        <Link to={`/category/${category.id}`}> / {category.title}*/}
                        {/*        </Link>*/}
                        {/*    </span>*/}
                        {/*        }*/}
                        {/*    )}*/}
                        {/*    {selectedSubCategory && getCategoryParents(categories, selectedSubCategory).map((category, index) => {*/}
                        {/*        return index === 0 ? <span className="rout-item" onClick={() => {}}>*/}
                        {/*        <Link to={`/category/${category.id}`}> / {category.title}*/}
                        {/*        </Link>*/}
                        {/*    </span> : <span className="rout-item">*/}
                        {/*        <Link> / {category.title}</Link>*/}
                        {/*    </span>*/}
                        {/*    })}*/}
                        {/*</div>*/}

                        <div className="category-sub-title-wrapper">
                            <h2 className="category-sub-title">{selectedCategory?.title || staticTexts?.category_details_header}</h2>
                            <div className="products-count">{products?.count || 0} products</div>
                        </div>
                        {selectedCategory
                        && <CategorySubItems data={selectedCategory?.children}
                                             staticTexts={staticTexts}
                                             categoryId={categoryId}
                                             subCategoryId={subCategoryId}
                        />}
                    </div>
                </div>
                {!!selectedCategory?.children?.length && <div className="separator"/>}
            </div>
            <div className={'down-part'}>
                <div className={'filters-part'}>
                    <div className={'filters-inner'}>
                        <div className={'scrolled-part'}>
                            <Filters filterValues={filterValues}
                                     minPrice={minPrice}
                                     maxPrice={maxPrice}
                                     getPriceValues={this.getPriceValues}
                                     getFilterValues={this.getFilterValues}/>
                            <div className={'clear-filters'}>
                                <PrimaryButton title={staticTexts?.filters_btn_clear}
                                               cb={this.clearSelectedFilters}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'products-part'}>
                    <div className={"products-top-filters"}>
                        <div className={'sort-part'}>
                            <div className="sort-default-text">Սորտավորել</div>
                            <InputGroup value={sort || undefined}
                                        inputType={'select'}
                                        name="sort"
                                        onChange={this.changeSortType}
                                        showSearch={false}
                                        options={sortTypesOptions}>
                            </InputGroup>
                        </div>
                        <div className={'toggle-track-view'}>
                            <PrimaryButton
                                className={`list-view-button without-hover ${productCardView ? 'selected' : ''}`}
                                title={<ListIcon title={''}/>}
                                cb={() => toggleProductView(true)}/>
                            <PrimaryButton
                                className={`list-view-button without-hover ${!productCardView ? 'selected' : ''}`}
                                title={<GridIcon title={''}/>}
                                cb={() => toggleProductView(false)}/>
                        </div>
                    </div>
                    <div className={'products-list'}>
                        <ProductList
                            loading={requestLoading}
                            loadMoreItems={this.loadMoreItems}
                            noResult={staticTexts?.products_empty_view}
                            gridView={!productCardView}
                            products={products}/>
                    </div>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'singleCategory',
        'filters',
        'categories',
        'mainSlider',
        'products',
        'selectedLanguage',
        'requestLoading',
        'productCardView',
    ])
};

const mapDispatchToProps = {
    GetSingleCategory,
    GetProducts,
    ClearProducts,
    GetFilters,
    ClearProductFilters,
    toggleProductView
};


export default connect(mapStateToProps, mapDispatchToProps)(Search);
