// Import packages
import React from "react";

// Import utils
import {history} from "../../../configs/history";

// Import styles
import "./buttons.scss";
import {LoadingOutlined} from '@ant-design/icons';
import {ArrowIcon} from "../../../assets/images";

// import {ArrowIcon} from "../../../assets/images";


export function PrimaryButton(props) {
    const {title, className, link, cb, loading, disabled, id} = props;
    return <button className={`PrimaryButton ${className ? className : ''}`}
                   id={id}
                   disabled={disabled || false}
                   onClick={(e) => {
                       if (link) {
                           history.push(link)
                       } else {
                           if (!loading && cb) {
                               e.preventDefault();
                               e.stopPropagation()
                               cb();
                           }
                       }
                   }}>
        {loading ?
            <div className="flex-row">Please wait &nbsp;
                <LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>
            </div> :
            <span className="title">{title}</span>
        }
    </button>
}

export function AuthorisationButton(props) {
    const {title, className, onClick, loading, disabled, imagePath, svg} = props;
    return <button className={`link-button ${className ? className : ''}`}
                   disabled={disabled || false}
                   onClick={onClick}>
        {loading ?
            <div className="flex-row">Please wait
                <LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>
            </div> :
            <>
                {imagePath && <img src={imagePath} className="authorisation-button-image" alt={'authorisation'}/>}
                {svg && svg}
                <span className="title">{title}</span>
            </>

        }
    </button>
}

export function SliderButton(props) {
    const {className, cbPrev, cbNext, disabledPrev, disabledNext} = props;
    return <div className={`arrows ${className ? className : ""}`}>
        <button className={`slider-btn prev-btn ${disabledPrev ? 'disabled' : ''}`}
                onClick={cbPrev}
                disabled={disabledPrev}
        >
            <ArrowIcon className={'prev-icon'}/>
        </button>
        <button className={`slider-btn next-btn ${disabledNext ? 'disabled' : ''}`}
                disabled={disabledNext}
                onClick={cbNext}>
            <ArrowIcon className={'next-icon'}/>
        </button>
    </div>
}

export function ProductBadge(props) {
    const {className, style, title, id} = props;
    return <div className={`product-badge ${className ? className : ""}`}
                style={style}
                key={id}>
        {title}
    </div>
}

