import {AUTH_CONSTS} from "../constants";
import {BONUS_CARDS_CONSTS} from "../constants/bonusCardsConsts";

export const initialState = {
    bonusCard: null,
    history: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case BONUS_CARDS_CONSTS.GET_BONUS_CARD:
            return {
                ...state,
                bonusCard: action.payload
            };
        case BONUS_CARDS_CONSTS.GET_HISTORY:
            return {
                ...state,
                history: action.payload
            };

        case AUTH_CONSTS.LOG_OUT:
            return {
                ...state,
                ...initialState
            };
        default:
            return state;
    }
}
