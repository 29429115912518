// Import packages
import React, { useEffect, useState } from "react";
import { withRouter, useLocation } from 'react-router-dom';
import { connect } from "react-redux";

// Import core Firebase SDK and configs
// import "firebase/auth";

// Import assets
import '../assets/styles/layout.scss'

// Import components
import Header from "../components/header/Header";
// import Footer from "../components/footer/Footer";
// import MetaTags from "../components/metaTags/MetaTags";

// Import utils
import { getPropsFromState } from "../redux/mapStateToProps";
import {
    GetSlider,
    GetStaticTexts,
    GetCategories,
    GetMarkets,
    GetCurrentUser,
    GetLanguages,
    GetBanners,
    GetContacts, GetInformation, GetProjects, GetOrderStatuses
}
    from "../redux/actions";
import NavigationRow from "../components/header/navigationRow/NavigationRow";
import Footer from "../components/footer/Footer";
import MetaTags from "../components/MetaTags";
import { socketNotificationConnection } from "../socket/api/socketNotificationConnection";
import { TokenValidation } from "../redux/actions";

function Layout (props) {
    const location = useLocation();
    const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);

    useEffect(async() => {
        // console.log('isLoggedIn', props.isLoggedIn)
        if (props.isLoggedIn) {
            const tokenIsValid = await props.TokenValidation();
            // console.log('tokenIsValid', tokenIsValid);
            if (tokenIsValid) {
                if (!socketNotificationConnection._instance && props.isLoggedIn) {
                    socketNotificationConnection.connectToSocket();
                }
                props.GetCurrentUser();
            }
        } else {
            // console.log('disconnect', socketNotificationConnection?.instance?.connected)
            if (socketNotificationConnection.instance) {
                socketNotificationConnection.instance.disconnect();
                socketNotificationConnection.instance = null;
            }
        }
    }, [props.isLoggedIn]);

    useEffect(() => {
        props?.GetLanguages();
    }, []);

    useEffect(() => {
        props.GetCategories();
        props.GetStaticTexts();
        props?.GetContacts();
        props?.GetSlider();
        props?.GetMarkets();
        props?.GetBanners();
        props?.GetInformation();
        props?.GetProjects();
        props.GetOrderStatuses();

    }, [props?.selectedLanguage?.code]);

    const toggleMobileMenu = () => {
        setMobileMenuIsOpen(!mobileMenuIsOpen)
    };

    const { children } = props;
    let showFooter = !location.pathname.includes('profile') && !location.pathname.startsWith('/sign')
        && !location.pathname.startsWith('/users')
        && !location.pathname.startsWith('/user');
    let bgDark = location.pathname.startsWith('/sign') || location.pathname.startsWith('/user');
    let hideHeader = location.pathname.startsWith('/sign') || location.pathname.startsWith('/user')
    let withContent = !location.pathname.startsWith('/about')
    return <div
        className={`LayoutWrapper ${mobileMenuIsOpen ? "mobileMenuOpen" : ""} ${bgDark ? 'bg-dark' : ''} ${hideHeader ? 'hidden-header' : ''}`}>
        <MetaTags/>
        <Header mobileMenuIsOpen={mobileMenuIsOpen}
                hideHeader={hideHeader}
                toggleMobileMenu={toggleMobileMenu}/>
        {!hideHeader && <NavigationRow staticTexts={props.staticTexts}/>}
        <div className={withContent ? `MainContent`: ''}>
            {children}
        </div>
        {showFooter && <Footer/>}
    </div>
}

const mapStateToProps = (state) => {
        return getPropsFromState(state, [
            'isLoggedIn',
            'selectedLanguage',
            'staticTexts',
        ])
    }
;

const mapDispatchToProps =
    {
        GetSlider,
        TokenValidation,
        GetCurrentUser,
        GetLanguages,
        GetStaticTexts,
        GetCategories,
        GetMarkets,
        GetBanners,
        GetContacts,
        GetInformation,
        GetProjects,
        GetOrderStatuses
    }
;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
