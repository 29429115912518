export const UTIL_CONSTS = {
    START_LOADING: "START_LOADING",
    END_LOADING: "END_LOADING",
    TOGGLE_NOTIFICATION_ROW: "TOGGLE_NOTIFICATION_ROW",
    TOGGLE_LINEAR_LOADING: "TOGGLE_LINEAR_LOADING",
    TOGGLE_PRODUCT_CARD_VIEW: "TOGGLE_PRODUCT_CARD_VIEW",

    SET_META_DATA: "SET_META_DATA",
    CLEAR_META_DATA: "CLEAR_META_DATA",
};
