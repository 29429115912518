import React from "react";
import "./loading.scss";

export function LoadingLinear(props) {
    return <div className="linear-loading">
        <div className="line"></div>
        <div className="subline inc"></div>
        <div className="subline dec"></div>
    </div>
}
