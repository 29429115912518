// import packages
import React, {Component} from "react";
import Slider from "react-slick";

//import assets
import "./notificationDetails.scss";

import {generateImageMediaUrl} from "../../../../utils/generateMediaUrl";
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {GetNotifications} from "../../../../redux/actions";
import {connect} from "react-redux";
import {SliderButton} from "../../../../components/uiElements/buttons/Buttons";
import moment from "moment";

class NotificationDetails extends Component {
    constructor() {
        super();
        this.state = {
            disabledPrev: true,
            disabledNext: false,
            timer: 0,
        };
    }

    nextSlide = (index) => {
        this.setState({
            slideIndex: this.state.slideIndex - 1 === index ? 0 : index + 1,
        });
        if (this.props.mainSlider?.length - 1 === this.state.slideIndex) {
            this.setState({
                slideIndex: 0,
            });
        }
        this.slider.slickNext();
    };

    prevSlide = (index) => {
        this.setState({
            slideIndex: index === 0 ? this.props.mainSlider?.length - 1 : index - 1,
        });
        this.slider.slickPrev();
    };

    render() {
        const {notificationsList, data} = this.props;
        const settingsSlider = {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 400,
            autoplay: false,
            pauseOnHover: false,
            swipeToSlide: false,
            lazyLoad: 'progressive',
            swipe: true,
            afterChange: this.afterChangeSlideIndex,
            beforeChange: this.beforeChangeSlideIndex,
            ref: slider => (this.slider = slider),
            slidesToShow: 1,
            slidesToScroll: 1,
        };
        return <div className="notification-details">
            {!!data?.medias?.length && <div className="notification-slider-wrapper">
                <div className="image-wrapper">
                    <div className={'main-slider-items'}>
                        <div className={'images-slider'}>
                            <Slider {...settingsSlider}>
                                {data?.medias?.map(item => {
                                    return <div key={item.id}
                                                className={`slider-item`}>
                                        <img src={generateImageMediaUrl(item.path)} alt={""}/>
                                    </div>
                                })}
                            </Slider>
                            {data?.medias?.length > 1 && <SliderButton
                                cbPrev={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.slider.slickPrev()
                                }}
                                cbNext={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.slider.slickNext()
                                }}
                            />}
                        </div>
                    </div>
                </div>
            </div>}
            <div className="notification-title">
                {data?.title}
            </div>
            <div className="date">{moment(notificationsList?.[0]?.createdAt).format('MMM DD, YYYY HH:mm')}</div>
            <div className="notification-description">
                {data?.description}
            </div>
        </div>
    }
}

const mapStateToProps = (state) => getPropsFromState(state, [
'staticTexts',
'selectedLanguage',
'notificationsList',
'notificationHasMore',
'loggedInUser',
'requestLoading',
'orderStatuses',
]);

const mapDispatchToProps =
{
    GetNotifications,
    /*ToggleNotification*/
}
;

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDetails);