// import packages
import React, {useRef, useState} from "react";
import {connect} from "react-redux";
import {Link, NavLink} from "react-router-dom";

//import assets
import './navigationRow.scss'
import {ArrowDown, LocationIcon} from "../../../assets/images";

// import components
import {Dropdown, Menu} from "antd";
import AddressMapModal from "../../uiElements/addressMapModal/AddressMapModal";

// Import utils
import {history} from "../../../configs/history";
import {mapStateToProps} from "../../../redux/mapStateToProps";

function NavigationRow(props) {
    const [openNavDropdown, setOpenNavDropdown] = useState(false);
    const [openModal, toggleOpenModal] = useState(false);
    const {staticTexts} = props;
    const navMenu = (
        <Menu className={'nav-menu-wrapper'}>
            <Menu.Item>
                <Link to={"/about"}>{staticTexts?.nav_item_about_us}</Link>
            </Menu.Item>
            <Menu.Item>
                <Link to={"/blogs"}>{staticTexts?.nav_item_blog}</Link>
            </Menu.Item>
            <Menu.Item>
                <Link to={"/help"}>{staticTexts?.nav_item_help}</Link>
            </Menu.Item>
        </Menu>
    );

    window.addEventListener("scroll", () => setOpenNavDropdown(false));

    const getAddress = () => {
        toggleOpenModal(false);
    };

    return <div className='navigation-wrapper'>
        <div className="left-side">
            <div className='location-btn' onClick={() => toggleOpenModal(true)}>
                <LocationIcon title={""}/>
                <span>{props?.address?.mainAddress ? props?.address?.mainAddress : staticTexts?.header_default_address}</span>
            </div>
        </div>
        <div className="right-side">
            <ul className="navigation-row">
                <li>
                    <NavLink to={'/products'} className="menu-item"
                             activeClassName='active'>
                        <span>{staticTexts?.nav_item_products}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={'/project-plans'} className="menu-item"
                             activeClassName='active'>
                        <span>{staticTexts?.nav_item_project_plan}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={'/shipping'} className="menu-item"
                             activeClassName='active'>
                        <span>{staticTexts?.nav_item_shipping}</span>
                    </NavLink>
                </li>
               {/* <li>
                    <NavLink to={'/apps'} className="menu-item"
                             activeClassName='active'>
                        <span>{staticTexts?.nav_item_apps}</span>
                    </NavLink>
                </li>*/}
                <li>
                    <Dropdown
                        trigger={['click']}
                        placement="bottomRight"
                        className="nav-dropdown"
                        onVisibleChange={() => setOpenNavDropdown(!openNavDropdown)}
                        visible={openNavDropdown}
                              overlay={navMenu}>
                        <a className={`menu-item ${openNavDropdown ? 'is-open' : ''}`} onClick={e => e.preventDefault()}>
                            {staticTexts?.header_dropdown_title} <ArrowDown title={''}/>
                        </a>
                    </Dropdown>
                </li>
            </ul>
        </div>
        <AddressMapModal addresFromHeader
                         isOpen={openModal}
                         closeModal={() => toggleOpenModal(false)}
                         getAddress={getAddress}/>
    </div>
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationRow)