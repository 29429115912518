import {initialState as auth} from "../reducers/auth";
import {initialState as general} from "../reducers/general";
import {initialState as cart} from "../reducers/cart";
import {initialState as utils} from "../reducers/utils";
import {initialState as product} from "../reducers/product";
import {initialState as order} from "../reducers/order";
import {initialState as bonusCard} from "../reducers/bonusCards";
import {initialState as notifications} from "../reducers/notifications"
import {initialState as blog} from "../reducers/blog"

const reducers = {
    auth,
    general,
    cart,
    utils,
    product,
    order,
    bonusCard,
    notifications,
    blog,
};

export default state => {
    const sData = {};
    Object.keys(reducers).forEach(selectorKey => {
        Object.keys(reducers[selectorKey]).forEach(key => {
            sData[key] = state[selectorKey][key];
        });
    });
    return sData;
};





