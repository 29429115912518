import {_urlBonusCards, request} from "../api";
import {BONUS_CARDS_CONSTS} from "../constants/bonusCardsConsts";
import {UTIL_CONSTS} from "../constants";

export const GetBonusCards = () => {
    const requestData = {
        url: `${_urlBonusCards}`,
        method: "GET",
        token: true
    };

    return dispatch => {
        dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then((res) => {
                const data = res && res?.data;
                data && dispatch({
                    type: BONUS_CARDS_CONSTS.GET_BONUS_CARD,
                    payload: data
                });
                dispatch({type: UTIL_CONSTS.END_LOADING});
            })
    };
};


export const BonusCardAttach = (data) => {
    const requestData = {
        url: `${_urlBonusCards}/attach`,
        method: "POST",
        token: true,
        data
    };

    return (dispatch) => {
        return request(requestData).then(async () => {
            await dispatch({
                type: BONUS_CARDS_CONSTS.CHECK_BONUS_CARD,
                payload: data
            });
        })
    };
};
