import {_urlProducts, request} from "../api";
import {PRODUCTS_CONSTS, UTIL_CONSTS} from "../constants";
import {MAX_PRICE, MIN_PRICE} from "../../constants/acceptedConsts";

export const GetProducts = ({
                                offset = 0,
                                limit = 6,
                                reset = true,

                                text = '',
                                category = null,
                                filterValueIds = null,
                                minPrice = null,
                                maxPrice = null,
                                sort = null,
                                state = null
                            } = {}) => {

    const requestData = {
        url: `${_urlProducts}?offset=${offset}&limit=${limit}`,
        method: "GET",
        languageFlag: true,
        token: true
    };
    text && (requestData.url += `&text=${text}`);
    minPrice && minPrice !== MIN_PRICE && (requestData.url += `&minPrice=${minPrice}`);
    maxPrice && maxPrice !== MAX_PRICE && (requestData.url += `&maxPrice=${maxPrice}`);
    sort && (requestData.url += `&sort=${sort}`);
    category && (requestData.url += `&categoryIds=${category}`);
    filterValueIds?.length && (requestData.url += `&filterValueIds=${filterValueIds.join(',')}`);

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: PRODUCTS_CONSTS.GET_PRODUCTS,
                    payload: {
                        data: data.products,
                        hasMore: data?.products?.length === limit,
                        count: data.productsCount,
                        reset,
                        text,
                        state
                    }
                })
            }).catch(err => {

            })
    };
};

export const GetProductsByIds = ({
                                     productIds = null,
                                     limit = 100
                                 } = {}) => {
    const requestData = {
        url: `${_urlProducts}`,
        method: "GET",
        languageFlag: true,
        token: true
    };
    productIds && (requestData.url += `?productIds=${productIds.join(',')}`);

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                return {
                    data,
                }
            })
    };
};

export const GetSpecialProducts = () => {
    const requestData = {
        url: `${_urlProducts}?offset=${0}&limit=${20}&specialOffers=true`,
        method: "GET",
        languageFlag: true,
        token: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                dispatch({
                    type: PRODUCTS_CONSTS.GET_SPECIAL_PRODUCTS,
                    payload: data.products,
                })
            })
    };
};

export const GetSingleProduct = (id) => {
    const requestData = {
        url: `${_urlProducts}/${id}`,
        method: "GET",
        languageFlag: true,
        token: true
    };

    return dispatch => {
        dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: PRODUCTS_CONSTS.GET_SINGLE_PRODUCT,
                    payload: data
                });
                dispatch({type: UTIL_CONSTS.END_LOADING});
            })
    };
};

export const ClearSingleProduct = () => {
    return async dispatch => {
        await dispatch({
            type: PRODUCTS_CONSTS.CLEAR_SINGLE_PRODUCT
        })
    };
};

export const RateOrderProduct = (productId, order, rate) => {
    const requestData = {
        url: `${_urlProducts}/${productId}/rate`,
        method: "POST",
        token: true,
        languageFlag: true,
        data: {order, rate}
    };
    return dispatch => {
        return request(requestData)
    };
};

export const GetIdenticalProducts = (id) => {
    const requestData = {
        url: `${_urlProducts}/${id}/identical`,
        method: "GET",
        languageFlag: true,
        token: true
    };

    return dispatch => {
        dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: PRODUCTS_CONSTS.GET_IDENTICAL_PRODUCTS,
                    payload: data
                });
                dispatch({type: UTIL_CONSTS.END_LOADING});
            })
    };
};

export const LikeProduct = (id) => {
    const requestData = {
        url: `${_urlProducts}/${id}/add-to-favorites`,
        method: "POST",
        token: true,
    };

    return (dispatch) => {
        return request(requestData).then(async () => {
            const likeState = true;
            await dispatch({
                type: PRODUCTS_CONSTS.TOGGLE_PRODUCT_LIKE,
                payload: {id, likeState}
            });
        })
    };
};

export const UnLikeProduct = (id) => {
    const requestData = {
        url: `${_urlProducts}/${id}/remove-from-favorites`,
        method: "DELETE",
        token: true,
    };
    return (dispatch,) => {
        return request(requestData).then(async () => {
            const likeState = false;
            await dispatch({
                type: PRODUCTS_CONSTS.TOGGLE_PRODUCT_LIKE,
                payload: {id, likeState}
            });
        })
    };
};

export const GetFavoriteProducts = ({offset = 0, limit = 10, reset = true} = {}) => {
    const requestData = {
        url: `${_urlProducts}/favorites?offset=${offset}&limit=${limit}`,
        method: "GET",
        token: true,
        languageFlag: true,
    };
    return dispatch => {
        dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then((res) => {
                const data = res && res.data;

                // console.log("data", data)
                data && dispatch({
                    type: PRODUCTS_CONSTS.GET_FAVORITE_PRODUCTS,
                    payload: {
                        data: data,
                        reset,
                        hasMore: data?.length === limit
                    }
                })
                dispatch({type: UTIL_CONSTS.END_LOADING});
            })
    };
};

export const ClearProducts = () => {
    return async dispatch => {
        await dispatch({
            type: PRODUCTS_CONSTS.CLEAR_PRODUCTS
        })
    };
};

export const ClearProductFilters = () => {
    return async dispatch => {
        await dispatch({
            type: PRODUCTS_CONSTS.CLEAR_PRODUCT_FILTERS
        })
    };
};


export const GetProductSuggestions = (text) => {
    const requestData = {
        url: `${_urlProducts}/search-suggestions?text=${text}`,
        method: "GET",
        token: true,
        languageFlag: true,
    };
    return request(requestData).then((res) => {
        return res?.data;
    })
};


//===============================================UNUSED ACTIONS=========================================================
export const GetProductReview = (id) => {
    const requestData = {
        url: `${_urlProducts}/${id}/reviews`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: PRODUCTS_CONSTS.GET_PRODUCTS_REVIEWS,
                    payload: data
                })
            })
    };
};

export const ClearProductReview = () => {
    return async dispatch => {
        await dispatch({
            type: PRODUCTS_CONSTS.CLEAR_PRODUCTS_REVIEWS
        })
    };
};


export const AddProductReview = (id, data) => {
    const requestData = {
        url: `${_urlProducts}/${id}/reviews`,
        method: "POST",
        token: true,
        languageFlag: true,
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: PRODUCTS_CONSTS.ADD_PRODUCT_REVIEW,
                    payload: data
                })
            })
    };
};

export const UpdateProductReview = (id, reviewId, data) => {
    const requestData = {
        url: `${_urlProducts}/${id}/reviews/${reviewId}`,
        method: "PATCH",
        token: true,
        languageFlag: true,
        data,
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: PRODUCTS_CONSTS.UPDATE_PRODUCT_REVIEW,
                    payload: {data, id}
                })
            })
    };
};

export const DeleteProductReview = (id, reviewId) => {
    const requestData = {
        url: `${_urlProducts}/${id}/reviews/${reviewId}`,
        method: "DELETE",
        token: true,
    };

    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: PRODUCTS_CONSTS.DELETE_PRODUCTS_REVIEWS,
                    payload: reviewId
                })
            })
    };
};

export const GetProductReviewReplies = (id, reviewId) => {
    const requestData = {
        url: `${_urlProducts}/${id}/reviews/${reviewId}/replies`,
        method: "GET",
        languageFlag: true
    };
    return dispatch => {
        // dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                // console.log(data, 'RES');
                data && dispatch({
                    type: PRODUCTS_CONSTS.GET_PRODUCTS_REVIEW_REPLIES,
                    payload: {
                        data,
                        id: reviewId
                    }
                });
                // dispatch({type: UTIL_CONSTS.END_LOADING});
            })
    };
};

export const ClearProductReviewReplies = () => {
    return async dispatch => {
        await dispatch({
            type: PRODUCTS_CONSTS.CLEAR_PRODUCTS_REVIEW_REPLIES
        })
    };
};

export const AddProductReviewReplies = (id, reviewId, data) => {
    const requestData = {
        url: `${_urlProducts}/${id}/reviews/${reviewId}/replies`,
        method: "POST",
        token: true,
        languageFlag: true,
        data,
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                dispatch({
                    type: PRODUCTS_CONSTS.ADD_PRODUCTS_REVIEW_REPLIES,
                    payload: reviewId
                })
            })
    };
};

export const DeleteProductReviewReplies = (id, reviewId, repliesId) => {
    const requestData = {
        url: `${_urlProducts}/${id}/reviews/${repliesId}`,
        method: "DELETE",
        token: true,
    };

    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: PRODUCTS_CONSTS.DELETE_PRODUCTS_REVIEW_REPLIES,
                    payload: {reviewId, repliesId}
                })
            })
    };
};

export const UpdateProductReviewReplies = (id, reviewId, repliesId, data) => {
    const requestData = {
        url: `${_urlProducts}/${id}/reviews/${reviewId}/replies/${repliesId}`,
        method: "PATCH",
        token: true,
        languageFlag: true,
        data,
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: PRODUCTS_CONSTS.UPDATE_PRODUCT_REVIEW_REPLIES,
                    payload: {data, id}
                })
            })
    };
};
