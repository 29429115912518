// import packages
import React from "react";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {connect} from "react-redux";

// import assets
import './rated.scss'

// import components
import {PrimaryButton} from "../../components/uiElements/buttons/Buttons";

function Rated(props) {

    const {staticTexts} = props

    return <div className="rated-view">
        <p>{staticTexts?.already_rated}</p>
        <PrimaryButton link={"/"} title={staticTexts?.rout_item_home}/>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
    ])
};
export default connect(mapStateToProps)(Rated);
