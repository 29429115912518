import {_urlBlogTypes, _urlBlogs, request} from "../api";
import {BLOGS_CONSTS} from "../constants";

export const GetBlogs = ({
                             reset = true,
                             offset = 0,
                             badge,
                             limit = 20,
                         } = {}) => {
    let url = `${_urlBlogs}?offset=${offset}&limit=${limit}`;
    badge && (url += `&badge=${badge}`);
    const requestData = {
        url,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: BLOGS_CONSTS.GET_BLOGS,
                    payload: {
                        data,
                        badge,
                        reset,
                        hasMore: data.length === limit,
                    }
                })
            })
    };
};

export const GetBlogTypes = () => {
    const requestData = {
        url: `${_urlBlogTypes}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: BLOGS_CONSTS.GET_BLOG_TYPES,
                    payload: data
                })
            })
    };
};

export const GetSingleBlog = (slug) => {
    const requestData = {
        url: `${_urlBlogs}/${slug}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
    };
};