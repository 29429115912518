export function checkYoutubeUrl (url = '') {
    let regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    let match = url.match(regExp);
    return match && match[2] && match[2].length === 11
}
export function getYoutubeId (url = '') {
    let regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    let match = url.match(regExp);
    if (match && match[2] && match[2].length === 11) {
        return match[2];
    } else {
        return '';
    }
}
