// import packages
import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import GoogleMapReact from "google-map-react";
import isEmail from "validator/es/lib/isEmail";

//Import assets
import './about.scss'
import {
    TickIcon, MapIconContact, MailIconContact, PhoneIconContact, ClockIconContact, LocationIcon
} from "../../assets/images";
import {LoadingOutlined} from '@ant-design/icons';

//Import components
import {InputGroup} from "../../components/uiElements/inputGroup/inputGroup";
import {PrimaryButton} from "../../components/uiElements/buttons/Buttons";

//Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {SendEmail} from "../../redux/actions";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {GOOGLE_MAP_KEY} from "../../configs/variables";
import {MAP_DEFAULT_CENTER, MAP_DEFAULT_ZOOM} from "../../constants/constants";
import {CustomHeader} from "../../components/uiElements/customHeader/CustomHeader";
import {useLocation} from "react-router-dom";


function About(props) {
    let location = useLocation();
    const [contact, setContact] = useState({
        name: '',
        // lastName: '',
        email: '',
        phone: '',
        message: '',
    });
    const [errors, setErrors] = useState({
        name: '',
        // lastName: '',
        email: '',
        phone: '',
        message: '',
    });
    const [tick, setTick] = useState(false);

    const contactsRef = useRef(null);


    useEffect(() => {
        window.scrollTo({
            top: location?.state?.goToContactPart ? contactsRef?.current?.offsetTop - '100' : 0,
            behavior: "smooth"
        });
    }, []);


    function getInputValues(e) {
        const {name, value} = e.target;
        if (name === 'phone' && value && !/^[0-9]*$/.test(value)) {
            return;
        }
        setContact({
            ...contact,
            [name]: value.trimStart()

        })
        setErrors({
            ...errors,
            [name]: false

        })
    }

    function validate() {
        const errors = {};
        let result = true;
        Object.keys(contact).forEach(key => {
            if (!contact[key]
                || (key === 'email' && !isEmail(contact?.email))
                || (key === 'phone' && contact.phone?.length < 8)
            ) {
                errors[key] = true;
                result = false;
            }

        })
        setErrors(errors);

        return result;
    }

    function sendMessage() {
        const {name, email, phone, message} = contact;
        if (validate()) {
            let reqData = {
                name,
                email,
                phone,
                message
            };
            props.SendEmail(reqData).then(() => {
                setContact({
                    name: '',
                    email: '',
                    phone: '',
                    message: '',
                });
                setTick(true)
                showTick()
            })
        }
    }

    function showTick() {
        setTimeout(
            () => setTick(false),
            2000
        )
    }

    const {requestLoading, contacts, staticTexts, information} = props;

    let defaultAddress = contacts?.location?.latitude && contacts?.location?.longitude ? {
        lat: contacts?.location?.latitude,
        lng: contacts?.location?.longitude
    } : MAP_DEFAULT_CENTER

    const headerInfo = information?.headers?.find(item => item?.slug === 'about');
    const block_1 = information?.blocks?.find(item => item?.slug === "about-block-1");
    const block_2 = information?.blocks?.find(item => item?.slug === "about-block-2");

    return <div className="about-page-wrapper">
        <CustomHeader image={headerInfo?.backgroundImage}
                      title={headerInfo?.title}
                      description={headerInfo?.description}/>

        <div className="contacts-info-section">
            <div className="image-wrapper">
                <div className="image-proportion">
                    <img src={generateImageMediaUrl(block_1?.mediaMain?.path)} alt=""/>
                </div>
            </div>
            <div className="contacts-info-content">
                <div className="contacts-info-title">{block_1?.title}</div>
                <div className="contacts-info-description" dangerouslySetInnerHTML={{__html: block_1?.description}}/>
                <PrimaryButton title={staticTexts?.about_contact_btn}
                               cb={() => {
                                   window.scrollTo({
                                       top: contactsRef?.current?.offsetTop,
                                       behavior: "smooth"
                                   });
                               }}/>
            </div>
        </div>

        <div className="full-section">
            <div className="section-title">{staticTexts?.about_green_content_title}</div>
            <div className="section-items">
                <div className="about-item">
                    <div className="item-title">{staticTexts?.about_green_content_item_technology_title}</div>
                    <div className="item-description">{staticTexts?.about_green_content_item_technology_description}
                    </div>
                </div>
                <div className="line"/>
                <div className="about-item">
                    <div className="item-title">{staticTexts?.about_green_content_item_impact_title}</div>
                    <div className="item-description">{staticTexts?.about_green_content_item_impact_description}</div>
                </div>
                <div className="line"/>
                <div className="about-item">
                    <div className="item-title">{staticTexts?.about_green_content_item_recognition_title}</div>
                    <div className="item-description">{staticTexts?.about_green_content_item_recognition_description}
                    </div>
                </div>
            </div>
        </div>

        <div className="contacts-info-section">
            <div className="contacts-info-content">
                <div className="contacts-info-title">{block_2?.title}</div>
                <div className="contacts-info-description" dangerouslySetInnerHTML={{__html: block_2?.description}}/>
            </div>
            <div className="image-wrapper">
                <div className="image-proportion">
                    <img src={generateImageMediaUrl(block_2?.mediaMain?.path)} alt=""/>
                </div>
            </div>
        </div>

        <div className="full-section" ref={contactsRef}>
            <div className="section-title">{staticTexts?.about_contact_part_title}</div>
            <div className="section-description">{staticTexts?.about_contact_part_description}
            </div>
        </div>

        <div className="contact-section">
            <div className="map-wrapper">
                <div className={'map'}>
                    <GoogleMapReact
                        bootstrapURLKeys={{
                            key: GOOGLE_MAP_KEY,
                            libraries: 'places'
                        }}
                        defaultCenter={defaultAddress}
                        center={defaultAddress}
                        distanceToMouse={() => {
                            // console.log('distanceToMouse');
                        }}
                        defaultZoom={MAP_DEFAULT_ZOOM}

                        yesIWantToUseGoogleMapApiInternals={true}
                    >
                        <Marker lat={contacts?.location?.latitude}
                                lng={contacts?.location?.longitude}/>
                    </GoogleMapReact>
                </div>
            </div>

            <div className="form-wrapper">
                <h1 className="form-title">{staticTexts?.about_send_message_title}</h1>
                <h1 className="form-description">{staticTexts?.about_send_message_description}</h1>

                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    name={'name'}
                    placeholder={staticTexts?.about_send_message_name_placeholder}
                    value={contact.name}
                    error={errors.name}
                    maxLength={100}
                    onKeyDown={(e) => {
                        e.key === 'Enter' && sendMessage()
                    }}
                    onChange={getInputValues}
                />

                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    name={'phone'}
                    value={contact.phone}
                    error={errors.phone}
                    placeholder={staticTexts?.about_send_message_phone_placeholder}
                    maxLength={8}
                    className={'phone-input'}
                    onKeyDown={(e) => {
                        e.key === 'Enter' && sendMessage()
                    }}
                    onChange={getInputValues}><span className="phone-code">+374</span></InputGroup>

                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    name={'email'}
                    value={contact.email}
                    error={errors.email}
                    placeholder={staticTexts?.about_send_message_email_placeholder}
                    maxLength={50}
                    onKeyDown={(e) => {
                        e.key === 'Enter' && sendMessage()
                    }}
                    onChange={getInputValues}
                />
                <div className={'message'}>
                    <InputGroup
                        inputType={'textarea'}
                        type={"text"}
                        name={'message'}
                        value={contact.message}
                        error={errors.message}
                        placeholder={staticTexts?.about_send_message_text_placeholder}
                        onKeyDown={(e) => {
                            e.key === 'Enter' && sendMessage()
                        }}
                        onChange={getInputValues}
                    />
                </div>
                <button className='PrimaryButton'
                        onClick={() => {
                            if (!requestLoading && sendMessage) {
                                sendMessage();
                            }
                        }}>

                    {staticTexts?.about_send_message_btn}
                    {
                        requestLoading ? <LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>
                            : <TickIcon title={''}
                                        className={`tick-icon ${tick ? 'show-tick' : 'hide-tick'}`}/>
                    }
                </button>
            </div>
        </div>

        <div className="contact-card-list">
            <a className="contact-item" href={`mailto:${contacts?.email}`}>
                <a href={`mailto:${contacts?.email}`} className="icon-wrapper">
                    <MailIconContact title={''}/>
                </a>
                <div className='item-content'>
                    <div className="title">E mail</div>
                    <a className="value" href={`mailto:${contacts?.email}`}>{contacts?.email}</ a>
                </div>
            </a>
            <a className="contact-item"
                // onClick={()=> history.push('/location')}
            >
                <div className="icon-wrapper contact-item-map">
                    <MapIconContact title={''}/>
                </div>
                <div className='item-content'>
                    <div className="title">{staticTexts?.about_info_section_address_title}</div>
                    <div className="value">{contacts?.address}</div>
                </div>
            </a>
            <a className="contact-item middle-item"
                // onClick={()=> history.push('/location')}
            >
                <div className="icon-wrapper">
                    <ClockIconContact title={''}/>
                </div>
                <div className='item-content'>
                    <div className="title">{staticTexts?.about_info_section_working_hours_title}</div>
                    <div className="value">{contacts?.openingTime} - {contacts?.closingTime}</div>
                </div>
            </a>
            <a className="contact-item contact-item-phone" href={`tel:${contacts?.phoneNumber}`}>
                <a href={`tel:${contacts?.phoneNumber}`} className="icon-wrapper">
                    <PhoneIconContact title={''}/>
                </a>
                <div className='item-content'>
                    <div className="title">{staticTexts?.about_info_section_phone_title}</div>
                    <a className="value" href={`tel:${contacts?.phoneNumber}`}>{contacts?.phoneNumber}</a>
                </div>
            </a>
        </div>
    </div>
}

const Marker = () => <div className={`marker`}>
    <div className="marker-icon"/>
</div>;

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'requestLoading',
        'contacts',
        'selectedLanguage',
        'information',
    ])
};

const mapDispatchToProps = {
    SendEmail,
}

export default connect(mapStateToProps, mapDispatchToProps)(About);
